
//import logo from "../../data/loclon/logo.png";
//import logoLb from "../../data/loclon/logo-lb.png";

//import FieldFindAPlace from "./forms/fields/FieldFindAPlace";
//import SearchButton from "./fields/SearchButton";
import useFormSimple from "../../hooks/useFormSimple";
import { Link, useLocation, useHistory } from "react-router-dom";
import queryString from "query-string";

const Navbar = () => {

    //const pretty = props.pretty;
    //const showOffcanvas = props.showOffcanvas;
    //const values = props.values;
    //const handleChange = props.handleChange;
    //const handleChangeHelpslug = props.handleChangeHelpslug;
    //const handleSubmit = props.handleSubmit;
    //const handleReset = props.handleReset;

    const [ values, handleChange, , ] = useFormSimple({});

    let history = useHistory();

    const location = useLocation();

    const handleNavbarSubmit = (event) => {

        event.preventDefault();

        //console.log('handleNavbarSubmit');

        //console.log(values['text']);

        // Make navbar searches phrase searches if they aren't already
        if (!values['text'].includes('"') && !values['text'].includes('*') && !values['text'].includes('+') && !values['text'].includes('|') && !values['text'].includes('~')) {
            values['text'] = '"' + values['text'] + '"';
        }

        const stringified = queryString.stringify(values, { arrayFormat: "bracket" });

        history.push({ pathname: '/place', search: stringified, hash: 'results' });

        showOffcanvasGis(true);

        //props.addQueryLayer( 'place', values );

        //history.push({ pathname: '/place' });

    };

    const showOffcanvasGis = ( show ) => {

        const oe = document.querySelector("#offcanvasGis");
        const classname = oe.className;    

        const showing = classname.includes('show');

        if ((show && !showing) || (!show && showing)) {

            const nt = document.querySelector(".offcanvas-gis-button");

            if (nt) {
                nt.click(); // user click to hide, .hide() reloads the page in Safari!
            }

        }

    }

    const showOffcanvasWelcome = ( show ) => {

        //console.log('Navbar showOffcanvasWelcome ran');

        const oe = document.querySelector("#offcanvasWelcome");

        if (oe) {

            const classname = oe.className;    

            const showing = classname.includes('show');

            if ((show && !showing) || (!show && showing)) {

                const nt = document.querySelector(".offcanvas-welcome-button");

                if (nt) {
                    nt.click(); // user click to hide, .hide() reloads the page in Safari!
                }

            }

        }

    }

    const handleCollapse = () => {

        //console.log('handleCollapse');

        const dts = document.querySelectorAll(".dropdown-toggle");
        //console.log(dts);

        dts.forEach( (dt, i) => {
 
            //console.log(dt);

            if (dt && dt.getAttribute("aria-expanded") === "true") {
                // user click to hide, .hide() reloads the page in Safari!
                dt.click();
            } else {
                //console.log('no dropdown-toggle or aria-expanded is not true');
            }

        });

        const nt = document.querySelector(".navbar-toggler");
        //console.log(nt);

        if (nt && nt.getAttribute("aria-expanded") === "true") {
            // user click to hide, .hide() reloads the page in Safari!
            nt.click();
        } else {
            //console.log('no navbar-toggler or aria-expanded is not true');
        }

        /*
        if (
            dropdownToggle &&
            dropdownToggle.getAttribute("aria-expanded") === "true"
        ) {
            // user click to hide, .hide() reloads the page in Safari!
            dropdownToggle.click();
        } else {
            console.log('no dropdownToggle or aria-expanded is not true');
        }
        */

        // hide the menu if it is expanded (i.e in small screen responsive view)
        //if (!toggle) {
        //    toggleFunc();
        //}

    };

    /*
    const handleHome = () => {

        showOffcanvasWelcome(true);

    };
    */

    let topnav = '';

    if (location.pathname.startsWith('/search/')) { topnav = 'search'; }

    if (location.pathname === "/about/project") { topnav = 'about'; }
    if (location.pathname === "/about/publishinghistory") { topnav = 'about'; }
    if (location.pathname === "/about/value") { topnav = 'about'; }
    if (location.pathname === "/about/advertising") { topnav = 'about'; }
    if (location.pathname === "/about/linked-records") { topnav = 'about'; }
    if (location.pathname === "/about/ordinarys-accounts") { topnav = 'about'; }
    if (location.pathname === "/about/locating-information-about-old-bailey-trials-after-1913") { topnav = 'about'; }
    
    if (location.pathname === "/about/history") { topnav = 'history'; }
    if (location.pathname === "/about/crime") { topnav = 'history'; }
    if (location.pathname === "/about/london-life") { topnav = 'history'; }
    if (location.pathname === "/about/communities") { topnav = 'history'; }
    if (location.pathname === "/about/gender") { topnav = 'history'; }
    if (location.pathname === "/about/the-old-bailey") { topnav = 'history'; }
    if (location.pathname === "/about/glossary") { topnav = 'history'; }
    if (location.pathname === "/about/bibliography") { topnav = 'history'; }

    if (location.pathname === "/about/guides") { topnav = 'guides'; }
    if (location.pathname === "/about/gettingstarted") { topnav = 'guides'; }
    if (location.pathname === "/about/searchhelp") { topnav = 'guides'; }
    if (location.pathname === "/about/howtoreadtrial") { topnav = 'guides'; }
    if (location.pathname === "/about/mediatedtexts") { topnav = 'guides'; }
    if (location.pathname === "/about/doingstatistics") { topnav = 'guides'; }
    if (location.pathname === "/about/zotero") { topnav = 'guides'; }
    if (location.pathname === "/about/obapidemonstrator") { topnav = 'guides'; }
    if (location.pathname === "/about/universityteaching") { topnav = 'guides'; }
    if (location.pathname === "/about/projects") { topnav = 'guides'; }

    if (location.pathname === "/about/data") { topnav = 'downloads'; }

    if (location.pathname === "/about/about") { topnav = 'project'; }
    if (location.pathname === "/about/a-narrative-history-of-the-project") { topnav = 'project'; }
    if (location.pathname === "/about/legal-info") { topnav = 'project'; }
    if (location.pathname === "/about/whats-new") { topnav = 'project'; }
    if (location.pathname === "/about/whats-new-archive") { topnav = 'project'; }
    if (location.pathname === "/about/projects") { topnav = 'project'; }
    if (location.pathname === "/about/contact") { topnav = 'project'; }

    //console.log(location.pathname);
    //console.log(topnav);

    // If we are not browsing the '/about' pages, we open all links in a new tab.
    const target = window.location.href.includes('/about') ? '_self' : '_blank'; // We are not allowed to use React hooks here.

    return (

        <nav className="navbar navbar-expand-lg fixed-top">

            <div className="container-fluid">
                <Link className="navbar-brand" to={ "/" } onClick={ function () { showOffcanvasWelcome(true); } }>
                    <img src={ process.env.PUBLIC_URL + '/loclon_horiz.svg' } className="d-none d-lg-inline loclon-logo-horiz" alt="Locating London's Past" />
                    <img src={ process.env.PUBLIC_URL + '/loclon_vert.svg' } className="d-inline d-lg-none loclon-logo-vert" alt="Locating London's Past" />
                </Link>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav ms-auto me-3 mb-2 mb-lg-0">

                        <li className="nav-item me-3">
                            <Link className={ "nav-link" + ( location.pathname === '/' ? ' active' : '' ) } to={ "/" } onClick={ function () { showOffcanvasWelcome(true) } }>Home</Link>
                        </li>

                        <li className="nav-item dropdown me-3">
                            <a className={ "nav-link dropdown-toggle" + ( topnav === 'about' ? ' active' : '' ) } href={ ' #' } role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                About the Project
                            </a>
                            <ul className="dropdown-menu">
                                <li><Link target={ target } className={ "dropdown-item" + ( location.pathname === '/about/project' ? ' active' : '' ) } to={ "/about/project" } onClick={ handleCollapse }>Overview</Link></li>
                                <li><Link target={ target } className={ "dropdown-item" + ( location.pathname === '/about/getting-started' ? ' active' : '' ) } to={ "/about/getting-started" } onClick={ handleCollapse }>Getting Started</Link></li>
                                <li><Link target={ target } className={ "dropdown-item" + ( location.pathname === '/about/about-this-project' ? ' active' : '' ) } to={ "/about/about-this-project" } onClick={ handleCollapse }>Project Information</Link></li>
                                <li><Link target={ target } className={ "dropdown-item" + ( location.pathname === '/about/mapping-methodology' ? ' active' : '' ) } to={ "/about/mapping-methodology" } onClick={ handleCollapse }>Mapping Methodology</Link></li>
                                <li><Link target={ target } className={ "dropdown-item" + ( location.pathname === '/about/geocoding' ? ' active' : '' ) } to={ "/about/geocoding" } onClick={ handleCollapse }>Geocoding</Link></li>
                                <li><Link target={ target } className={ "dropdown-item" + ( location.pathname === '/about/data-downloads' ? ' active' : '' ) } to={ "/about/data-downloads" } onClick={ handleCollapse }>Data Downloads</Link></li>
                                <li><Link target={ target } className={ "dropdown-item" + ( location.pathname === '/about/copyright' ? ' active' : '' ) } to={ "/about/copyright" } onClick={ handleCollapse }>Copyright Information and Citation Guide</Link></li>
                            </ul>
                        </li>

                        <li className="nav-item dropdown me-3">
                            <a className={ "nav-link dropdown-toggle" + ( topnav === 'about' ? ' active' : '' ) } href={ ' #' } role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                Historical Background
                            </a>
                            <ul className="dropdown-menu">

                                <li><Link target={ target } className={ "dropdown-item" + ( location.pathname === '/about/historical' ? ' active' : '' ) } to={ "/about/historical" } onClick={ handleCollapse }>Overview</Link></li>

                                <li className="dropdown-item"><b>Datasets</b></li>
                                <li><Link target={ target } className={ "ps-5 dropdown-item" + ( location.pathname === '/about/old-bailey' ? ' active' : '' ) } to={ "/about/old-bailey" } onClick={ handleCollapse }>Old Bailey Proceedings</Link></li>
                                <li><Link target={ target } className={ "ps-5 dropdown-item" + ( location.pathname === '/about/london-lives' ? ' active' : '' ) } to={ "/about/london-lives" } onClick={ handleCollapse }>London Lives, 1690-1800: Crime, Poverty and Social Policy in the Metropolis</Link></li>
                                <li><Link target={ target } className={ "ps-5 dropdown-item" + ( location.pathname === '/about/cmh' ? ' active' : '' ) } to={ "/about/cmh" } onClick={ handleCollapse }>Mortality, Plague, and the Hearth Tax, 1665-1666</Link></li>
                                <li><Link target={ target } className={ "ps-5 dropdown-item" + ( location.pathname === '/about/mola-archaeology' ? ' active' : '' ) } to={ "/about/mola-archaeology" } onClick={ handleCollapse }>Archaeological Evidence</Link></li>
                                <li><Link target={ target } className={ "ps-5 dropdown-item" + ( location.pathname === '/about/population' ? ' active' : '' ) } to={ "/about/population" } onClick={ handleCollapse }>Estimating London's Population</Link></li>

                                <li className="dropdown-item"><b>Maps</b></li>
                                <li><Link target={ target } className={ "ps-5 dropdown-item" + ( location.pathname === '/about/rocque' ? ' active' : '' ) } to={ "/about/rocque" } onClick={ handleCollapse }>John Rocque's Survey of London, Westminster &amp; Southwark, 1746</Link></li>
                                <li><Link target={ target } className={ "ps-5 dropdown-item" + ( location.pathname === '/about/os' ? ' active' : '' ) } to={ "/about/os" } onClick={ handleCollapse }>Ordnance Survey, 1st Edition Map of London 1863-80</Link></li>


                            </ul>
                        </li>


                        <li className="nav-item me-3">
                            <Link target={ target } className={ "nav-link" + ( location.pathname === '/about/contact' ? ' active' : '' ) } to={ "/about/contact" } onClick={ /*showOffcanvasWelcome(true)*/ handleCollapse() }>Contact</Link>
                        </li>
                      </ul>

                      <form className="d-flex" onSubmit={ handleNavbarSubmit }>
                          <div className="input-group">
                              <div className="input-group">
                                  <input type="search" className='form-control' name="text" id="field_text" placeholder={ 'Find a place ...' } value={ values['text'] || '' } onChange={ handleChange } />
                                  {/*
                                  <button type="button" className="btn btn-outline-input-group btn-light bg-white border-start-0 text-muted" data-bs-toggle="modal" data-bs-target="#helpModal" data-bs-helpslug="help-text" onClick={ handleChangeHelpslug }>
                                      <i className="bi bi-question-circle" />
                                  </button>
                                  */}
                                  <button type="submit" className="btn btn-outline-input-group btn-light bg-primary text-white search-feature-inline-submit">
                                      <i className="bi bi-search" />
                                  </button>
                              </div>
                          </div>
                      </form>

                </div>
            </div>
        </nav>

    );

};

export default Navbar;
