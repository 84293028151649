
//import { useHistory, useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";

const useSearchmode = () => {

    //const location = useLocation();
    let history = useHistory(); 

    const handleSearchmodeClick = (event) => {

        event.preventDefault();
        //history.push({ pathname: '/search/' + event.currentTarget.dataset.searchmode, search: location.search });
        history.push({ pathname: '/' + event.currentTarget.dataset.searchmode });

    };

    return [ handleSearchmodeClick ];
    
};

export default useSearchmode;
