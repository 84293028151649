const useHitTerm = () => {

    const hitTerm = (dataset) => {

        if (dataset == 'fire') { return 'address'; }
        if (dataset == 'ahdsfsp') { return 'address'; }
        if (dataset == 'ahdsdir') { return 'address'; }
        if (dataset == 'pcc') { return 'will'; }
        if (dataset == 'lsdspar') { return 'event'; }
        if (dataset == 'westrate') { return 'address'; }
        if (dataset == 'westpoll') { return 'voter'; }
        if (dataset == 'cmhht') { return 'household'; }

        return 'place';

    }

    const hitsTerm = (dataset) => {

        if (dataset == 'fire') { return 'addresses'; }
        if (dataset == 'ahdsfsp') { return 'addresses'; }
        if (dataset == 'ahdsdir') { return 'addresses'; }
        if (dataset == 'pcc') { return 'wills'; }
        if (dataset == 'lsdspar') { return 'events'; }
        if (dataset == 'westrate') { return 'addresses'; }
        if (dataset == 'westpoll') { return 'voters'; }
        if (dataset == 'cmhht') { return 'households'; }

        return 'places';

    }

    const keyTerm = (dataset) => {

        if (dataset == 'fire') { return 'addresses'; }
        if (dataset == 'ahdsfsp') { return 'addresses'; }
        if (dataset == 'ahdsdir') { return 'addresses'; }
        if (dataset == 'pcc') { return 'wills'; }
        if (dataset == 'lsdspar') { return 'events'; }
        if (dataset == 'westrate') { return 'addresses'; }
        if (dataset == 'westpoll') { return 'voters'; }
        if (dataset == 'cmhht') { return 'households'; }

        return 'occurrences';

    }

    const layerControlTerm = (dataset, display) => {

        if (dataset === 'molacp') { return 'Finds by location'; }
        if (dataset === 'molag') { return 'Finds by location'; }

        if (display === 'place-insval-avg') { return 'Average insured value by place'; }
        if (display === 'parish-insval-avg') { return 'Average insured value by parish'; }
        if (display === 'ward-insval-avg') { return 'Average insured value by ward'; }
        if (display === 'heat-blocks-3dp-insval-avg') { return 'Average insured value by 110m2 tiles.'; }

        if (display === 'place-rent-tax-avg') { return 'Average rent tax by place'; }
        if (display === 'parish-rent-tax-avg') { return 'Average rent tax by parish'; }
        if (display === 'ward-rent-tax-avg') { return 'Average rent tax by ward'; }
        if (display === 'heat-blocks-3dp-rent-tax-avg') { return 'Average rent tax by 110m2 tiles.'; }
        if (display === 'place-per-tax-avg') { return 'Average personal tax by place'; }
        if (display === 'parish-per-tax-avg') { return 'Average personal tax by parish'; }
        if (display === 'ward-per-tax-avg') { return 'Average personal tax by ward'; }
        if (display === 'heat-blocks-3dp-per-tax-avg') { return 'Average personal tax by 110m2 tiles.'; }

        if (display === 'place-tax-avg') { return 'Average rental values by place'; }
        if (display === 'parish-tax-avg') { return 'Average rental values by parish'; }
        if (display === 'ward-tax-avg') { return 'Average rental values by ward'; }
        if (display === 'heat-blocks-3dp-tax-avg') { return 'Average rental values by 110m2 tiles.'; }

        if (display === 'place-hearths-total-avg') { return 'Average hearths by place'; }
        if (display === 'parish-hearths-total-avg') { return 'Average hearths by parish'; }
        if (display === 'ward-hearths-total-avg') { return 'Average hearths by ward'; }
        if (display === 'heat-blocks-3dp-hearths-total-avg') { return 'Average hearths by 110m2 tiles.'; }

        if (display === 'place-deaths-sum') { return 'Sum of deaths by place'; }
        if (display === 'parish-deaths-sum') { return 'Sum of deaths by parish'; }
        
        if (display === 'parish-deaths-sum-area') { return 'Sum of deaths, by parish, moderated by area'; }
        if (display === 'parish-deaths-sum-pop1690s') { return 'Sum of deaths, by parish, moderated by population (1690s)'; }
        if (display === 'parish-deaths-sum-pop1695') { return 'Sum of deaths, by parish, moderated by population (1695)'; }
        if (display === 'parish-deaths-sum-pop1740s') { return 'Sum of deaths, by parish, moderated by population (1740s)'; }
        if (display === 'parish-deaths-sum-pop1801') { return 'Sum of deaths, by parish, moderated by population (1801)'; }
        
        if (display === 'ward-deaths-sum') { return 'sum of deaths by ward'; }
        if (display === 'heat-blocks-3dp-deaths-sum') { return 'sum of deaths by 110m2 tiles.'; }

        if (display === 'place') { return 'Mappable ' + hitsTerm(dataset); }
        if (display === 'parish') { return 'Mappable ' + hitsTerm(dataset) + ', by parish'; }
        if (display === 'parish-area') { return 'Mappable ' + hitsTerm(dataset) + ', by parish, moderated by area'; }
        if (display === 'parish-pop1690s') { return 'Mappable ' + hitsTerm(dataset) + ', by parish, moderated by population (1690s)'; }
        if (display === 'parish-pop1695') { return 'Mappable ' + hitsTerm(dataset) + ', by parish, moderated by population (1695)'; }
        if (display === 'parish-pop1740s') { return 'Mappable ' + hitsTerm(dataset) + ', by parish, moderated by population (1740s)'; }
        if (display === 'parish-pop1801') { return 'Mappable ' + hitsTerm(dataset) + ', by parish, moderated by population (1801)'; }
        if (display === 'ward') { return 'Mappable ' + hitsTerm(dataset) + ', by ward'; }

        if (display === 'heat-blocks-3dp') { return 'Mappable ' + hitsTerm(dataset) + ', by 110m2 tiles'; }

        return 'Mappable ' + hitsTerm(dataset);

    }

    return [ hitTerm, hitsTerm, keyTerm, layerControlTerm ];

};

export default useHitTerm;
