
import React from 'react'; // Needed to use the full Fragment syntax

import { Link } from "react-router-dom";

import { domToReact } from 'html-react-parser';

const options = {

    replace: (domNode) => {

        if (domNode.name === 'a' && domNode.attribs && domNode.attribs.href) {

            if (domNode.attribs.href.startsWith('https://www.dhi.ac.uk/blogs/locating-london/')) {

                //return <a href={ `${process.env.REACT_APP_BASENAME}/about/` + domNode.attribs.href.substring(39, domNode.attribs.href.length) }>{ domToReact(domNode.children, options) }</a>
                return <Link target="_blank" to={ '/about/' + domNode.attribs.href.substring(44, domNode.attribs.href.length) }>{ domToReact(domNode.children, options) }</Link>


            } /*else if (domNode.attribs.href.startsWith('http://dev.local/obwp22/')) {

                //return <a href={ `${process.env.REACT_APP_BASENAME}/about/` + domNode.attribs.href.substring(24, domNode.attribs.href.length) }>{ domToReact(domNode.children, options) }</a>
                return <Link to={ '/about/' + domNode.attribs.href.substring(24, domNode.attribs.href.length) }>{ domToReact(domNode.children, options) }</Link>

            } else if (domNode.attribs.href.startsWith('https://www.dhi.ac.uk/obp-new/')) {

                //return <a href={ `${process.env.REACT_APP_BASENAME}/` + domNode.attribs.href.substring(30, domNode.attribs.href.length) }>{ domToReact(domNode.children, options) }</a>
                return <Link to={ '/' + domNode.attribs.href.substring(30, domNode.attribs.href.length) }>{ domToReact(domNode.children, options) }</Link>

            } else if (domNode.attribs.href.startsWith('https://new.oldbaileyonline.org/')) {

                //return <a href={ `${process.env.REACT_APP_BASENAME}/` + domNode.attribs.href.substring(30, domNode.attribs.href.length) }>{ domToReact(domNode.children, options) }</a>
                return <Link to={ '/' + domNode.attribs.href.substring(32, domNode.attribs.href.length) }>{ domToReact(domNode.children, options) }</Link>

            }*/

        }

        if (domNode.name === 'img') {

            return <img className="img-fluid" srcSet={ domNode.attribs.srcset } src={ domNode.attribs.src } alt={ domNode.attribs.alt }></img>;

        }

        
        if (domNode.name === 'div' && domNode.attribs && domNode.attribs.style) {

            delete(domNode.attribs.style);

            return <div>{ domToReact([domNode], options) }</div>;
            //return <div className="bad">{ domToReact(domNode.children, options) }</div>;

        }
        

        /*
        if (name === 'div' && attribs && attribs.class && (attribs.class === 'right image' || attribs.class === 'left image')) {

            let float_class = 'float-sm-start me-sm-4 ';

            if (attribs.class === 'right image') { float_class = 'float-sm-end ms-sm-4 '; }

            const only_img = children.map((c, idx) => {          
 
                if (c.type === 'tag' && c.name === 'img') {

                    return <React.Fragment key={ idx }>{ domToReact([c], options) }</React.Fragment>

                }
                
                return null;

            });

            const only_not_img = children.map((c, idx) => {
           
                if (c.type !== 'tag' || c.name !== 'img') {

                    return <React.Fragment key={ idx }>{ domToReact([c], options) }</React.Fragment>

                }
                 
                return null;

            });

            return (

                <div className={ float_class + 'text-center mw-sm-300px mt-2' }>

                    <figure className='figure text-center'>

                        { only_img }

                        <figcaption className='figure-caption'>

                            { only_not_img }

                        </figcaption>

                    </figure>

                </div>

            );
            
        }

        if (name === 'ul' && attribs && attribs.class && attribs.class === 'pagenav') {

            return <p>{ domToReact(children, options) }</p>

        }
        */

        /*
        if (name === 'h2') {

            if (attribs.class) {

                attribs.class = attribs.class + ' mt-5 mb-3';

            } else {

                attribs.class = 'mt-5 mb-3';

            }
           
            return domToReact(children, options)

        }
        */
       
    }
};

export default options;
