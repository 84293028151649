
import { useState } from "react";
//import { useParams } from "react-router-dom";


//import useForm from "../../hooks/useForm";
import useFormSimple from "../../hooks/useFormSimple";

//import usePretty from "../../hooks/usePretty";
//import usePaging from "../../hooks/usePaging";
//import useFilters from "../../hooks/useFilters";
//import useViz from "../../hooks/useViz";

//import locLonLookups from '../../data/loclon/lookups';

//import FieldKeyword from "./fields/FieldKeyword";
//import FieldFolder from "./fields/FieldFolder";
//import FieldStatsCat1 from "./fields/FieldStatsCat1";

//import SearchButton from "./fields/SearchButton";

import useSearchmode from "../../hooks/loclon/useSearchmode";
import SearchmodeButton from "./forms/SearchmodeButton";
//import SearchFields from "./forms/fields/SearchFields";

import FormPlace from "./forms/FormPlace";
import FormOldbailey from "./forms/FormOldbailey";
import FormPpop from "./forms/FormPpop";
import FormLlcoroner from "./forms/FormLlcoroner";
import FormLlcriminal from "./forms/FormLlcriminal";
import FormLlguild from "./forms/FormLlguild";
import FormLlpoor from "./forms/FormLlpoor";
import FormFire from "./forms/FormFire";
import FormAhdsfsp from "./forms/FormAhdsfsp";
import FormAhdsdir from "./forms/FormAhdsdir";
import FormPcc from "./forms/FormPcc";
import FormLsdspar from "./forms/FormLsdspar";
import FormWestrate from "./forms/FormWestrate";
import FormWestpoll from "./forms/FormWestpoll";
import FormCmhht from "./forms/FormCmhht";
import FormCmhpd from "./forms/FormCmhpd";
import FormMolag from "./forms/FormMolag";
import FormMolacp from "./forms/FormMolacp";

import LayerOptions from "./LayerOptions";
import LayersControl from "./LayersControl";

const OffcanvasGis = (props) => {
 
    const [ handleSearchmodeClick ] =
    useSearchmode();

    const pretty = props.pretty;

    const [ layerOptionsIdx, setLayerOptionsIdx ] = useState();
    const [ layerOptionsValues, layerOptionsHandleChange, , layerOptionsSetVal ] = useFormSimple({});

    const setLayerOptions = (idx) => {

        // Make the dropdown have the correct current options for that layer selected.
        layerOptionsSetVal("layerdisplay", props.layerData[idx].display);
        layerOptionsSetVal("colorscheme", props.layerData[idx].colorscheme);
        layerOptionsSetVal("stroke", props.layerData[idx].stroke);
        layerOptionsSetVal("polymarker", props.layerData[idx].polymarker);

        setLayerOptionsIdx(idx);

    }

    return (

        <>

            <div className="modal fade" id="layerOptionsModal" tabIndex="-1" aria-labelledby="layerOptionsModalLabel" aria-hidden="true">
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-body layer-options-modal">
                    <button type="button" className="btn float-end" data-bs-dismiss="modal" aria-label="Close">
                        <i className="bi bi-x-lg"></i>
                    </button>

                    { props.layerData[layerOptionsIdx] && (

                        <LayerOptions
                        idx={ layerOptionsIdx }
                        layerData={ props.layerData[layerOptionsIdx] }
                        layerOptionsValues={ layerOptionsValues }
                        layerOptionsHandleChange={ layerOptionsHandleChange }
                        handleChangeHelpslug={ props.handleChangeHelpslug }
                        setLayerOpts={ props.setLayerOpts }
                        pretty={ pretty }
                        />

                    ) } 

                  </div>
                </div>
              </div>
            </div>

            <button onClick={ props.dismissDemoLayer } className="btn btn-light offcanvas-gis-button" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasGis" aria-controls="offcanvasGis">
                <img src={ process.env.PUBLIC_URL + '/add_data.svg' } className="offcanvas-gis-button-svg" alt="Locating London's Past" />
            </button>

            <div className="offcanvas offcanvas-end" data-bs-scroll="true" data-bs-backdrop="false" tabIndex="-1" id="offcanvasGis" aria-labelledby="offcanvasGisLabel">

                <div className="offcanvas-body">

                    <h1 className="offcanvas-loclon-title">
                        {/*<i className="bi bi-plus"/>*/}
                        Add Data
                        <button data-bs-helpslug="help-adddata-top" type="button" className="btn border-0 text-white ps-2 pe-0 pt-0 pb-0 align-top" data-bs-toggle="modal" data-bs-target="#helpModal" onClick={ props.handleChangeHelpslug }><i className="bi bi-question-circle" /></button>
                        <button type="button" className="offcanvas-close btn" data-bs-dismiss="offcanvas" aria-label="Close"><i className="bi bi-chevron-double-right"/></button>
                        {/*<button type="button" className="btn btn-secondary pt-0 pb-0 ps-1 pe-1 ms-2" onClick={ props.dumpLayers } >Dump Layers</button>*/}
                        <button type="button" className="reset-layers btn" aria-label="Reset map layers" title="Reset map layers" onClick={ props.resetLayers }><i className="bi bi-arrow-clockwise"/></button>
                    </h1>

                    <div className="layers-control">

                        <LayersControl
                            layerData={ props.layerData }
                            setLayerVisibility={ props.setLayerVisibility }
                            setLayerOptions={ setLayerOptions }
                            deleteLayer={ props.deleteLayer }
                            setActiveLayer={ props.setActiveLayer }
                            activeLayer={ props.activeLayer }
                            setMapPosition={ props.setMapPosition }
                        />

                    </div>

                    { props.layerData[props.activeLayer] && (

                        <>

                        <div className="row gx-0">
                            <div className="col-8">
                                <input type="range" className="form-range" min="0" max="1" step="0.05" id="opacity" value={ props.layerData[props.activeLayer].opacity } onChange={ props.handleOpacityChange }/>
                            </div>
                            <div className="col-3 small opacity-label text-end">
                                Opacity {  Math.round(props.layerData[props.activeLayer].opacity / 1 * 100) }%
                            </div>
                            <div className="col-1 small opacity-label">
                                <button data-bs-helpslug="help-adddata-opacity" type="button" className="btn border-0 text-white ps-2 pe-0 pt-0 pb-0 align-top" data-bs-toggle="modal" data-bs-target="#helpModal" onClick={ props.handleChangeHelpslug }><i className="bi bi-question-circle" /></button>
                            </div>
                        </div>

                        { props.layerData[props.activeLayer].key && (

                            <>

                                <h4 className="layer-key-heading">
                                    Key to Layer
                                    <span className="layer-key-heading-info">{ pretty(props.layerData[props.activeLayer].searchmode, ['lookup_ldata_searchmode']) }</span>
                                </h4>

                                <div className="layer-key">

                                    <>
                                    { props.layerData[props.activeLayer].key }
                                    </>

                                </div>

                            </>

                        ) }

                        </>

                    ) } 

                    <div className="search-form mb-2">

                        { props.searchmode === '' && (
                            <div className="setinfo">This website allows you to place data layers over the maps. More information is available about each dataset once it is selected. Please select a dataset from the menu below to get started.</div>
                        ) }

                        <SearchmodeButton searchmode={ props.searchmode } pretty={ pretty } handleSearchmodeClick={ handleSearchmodeClick } />
                        <div className="text-end">
                            <button data-bs-helpslug="help-adddata-choosedataset" type="button" className="btn border-0 p-0 m-0 align-top help-link" data-bs-toggle="modal" data-bs-target="#helpModal" onClick={ props.handleChangeHelpslug }>What's this?</button>
                        </div>

                        { props.searchmode === 'place' && ( <FormPlace pretty={ pretty } addHitLayer={ props.addHitLayer } addQueryLayer={ props.addQueryLayer } handleChangeHelpslug={ props.handleChangeHelpslug } /> ) }
                        { props.searchmode === 'oldbailey' && ( <FormOldbailey pretty={ pretty } addHitLayer={ props.addHitLayer } addQueryLayer={ props.addQueryLayer } handleChangeHelpslug={ props.handleChangeHelpslug } /> ) }
                        { props.searchmode === 'ppop' && ( <FormPpop pretty={ pretty } addHitLayer={ props.addHitLayer } addQueryLayer={ props.addQueryLayer } handleChangeHelpslug={ props.handleChangeHelpslug } /> ) }
                        { props.searchmode === 'llcoroner' && ( <FormLlcoroner pretty={ pretty } addHitLayer={ props.addHitLayer } addQueryLayer={ props.addQueryLayer } handleChangeHelpslug={ props.handleChangeHelpslug } /> ) }
                        { props.searchmode === 'llcriminal' && ( <FormLlcriminal pretty={ pretty } addHitLayer={ props.addHitLayer } addQueryLayer={ props.addQueryLayer } handleChangeHelpslug={ props.handleChangeHelpslug } /> ) }
                        { props.searchmode === 'llguild' && ( <FormLlguild pretty={ pretty } addHitLayer={ props.addHitLayer } addQueryLayer={ props.addQueryLayer } handleChangeHelpslug={ props.handleChangeHelpslug } /> ) }
                        { props.searchmode === 'llpoor' && ( <FormLlpoor pretty={ pretty } addHitLayer={ props.addHitLayer } addQueryLayer={ props.addQueryLayer } handleChangeHelpslug={ props.handleChangeHelpslug } /> ) }
                        { props.searchmode === 'fire' && ( <FormFire pretty={ pretty } addHitLayer={ props.addHitLayer } addQueryLayer={ props.addQueryLayer } handleChangeHelpslug={ props.handleChangeHelpslug } /> ) }
                        { props.searchmode === 'ahdsfsp' && ( <FormAhdsfsp pretty={ pretty } addHitLayer={ props.addHitLayer } addQueryLayer={ props.addQueryLayer } handleChangeHelpslug={ props.handleChangeHelpslug } /> ) }
                        { props.searchmode === 'ahdsdir' && ( <FormAhdsdir pretty={ pretty } addHitLayer={ props.addHitLayer } addQueryLayer={ props.addQueryLayer } handleChangeHelpslug={ props.handleChangeHelpslug } /> ) }
                        { props.searchmode === 'pcc' && ( <FormPcc pretty={ pretty } addHitLayer={ props.addHitLayer } addQueryLayer={ props.addQueryLayer } handleChangeHelpslug={ props.handleChangeHelpslug } /> ) }
                        { props.searchmode === 'lsdspar' && ( <FormLsdspar pretty={ pretty } addHitLayer={ props.addHitLayer } addQueryLayer={ props.addQueryLayer } handleChangeHelpslug={ props.handleChangeHelpslug } /> ) }
                        { props.searchmode === 'westrate' && ( <FormWestrate pretty={ pretty } addHitLayer={ props.addHitLayer } addQueryLayer={ props.addQueryLayer } handleChangeHelpslug={ props.handleChangeHelpslug } /> ) }
                        { props.searchmode === 'westpoll' && ( <FormWestpoll pretty={ pretty } addHitLayer={ props.addHitLayer } addQueryLayer={ props.addQueryLayer } handleChangeHelpslug={ props.handleChangeHelpslug } /> ) }
                        { props.searchmode === 'cmhht' && ( <FormCmhht pretty={ pretty } addHitLayer={ props.addHitLayer } addQueryLayer={ props.addQueryLayer } handleChangeHelpslug={ props.handleChangeHelpslug } /> ) }
                        { props.searchmode === 'cmhpd' && ( <FormCmhpd pretty={ pretty } addHitLayer={ props.addHitLayer } addQueryLayer={ props.addQueryLayer } handleChangeHelpslug={ props.handleChangeHelpslug } /> ) }
                        { props.searchmode === 'molag' && ( <FormMolag pretty={ pretty } addHitLayer={ props.addHitLayer } addQueryLayer={ props.addQueryLayer } handleChangeHelpslug={ props.handleChangeHelpslug } /> ) }
                        { props.searchmode === 'molacp' && ( <FormMolacp pretty={ pretty } addHitLayer={ props.addHitLayer } addQueryLayer={ props.addQueryLayer } handleChangeHelpslug={ props.handleChangeHelpslug } /> ) }
      
                    </div>

                </div>

            </div>

        </>

    );

};

export default OffcanvasGis;
