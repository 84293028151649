const usePretty = (lookups) => {

  const pretty = (arg, directives) => {

    if (Array.isArray(arg)) {

        if (directives && directives.includes('array-br')) {

            return arg.map( (sarg) => { return ( <>{ pretty(sarg, directives) } <br/></> ); } );

        } else if (directives && directives.includes('array-ul')) {

            return ( <ul> { arg.map( (sarg) => { return ( <><li>{ pretty(sarg, directives) }</li></> ); } ) } </ul> );

        } else if (directives && directives.includes('array-ulnobr')) {

            return ( <ul> { arg.map( (sarg) => { return ( <><li><nobr>{ pretty(sarg, directives) }</nobr></li></> ); } ) } </ul> );

        }

        return arg.map( (sarg) => { return ( <>{ pretty(sarg, directives) } </> ); } );

    }

    if (directives && directives.includes('string')) {

        if (typeof arg === "number") {

            arg = String(arg);

        }

        arg = applyLookups(arg, directives)

        return prettyString(arg, directives);

    } else if (directives && directives.includes('number')) {

        return prettyNumber(arg, directives);

    } else if (directives && directives.includes('date')) {

        return prettyDate(arg, directives);

    } else if (directives && directives.includes('address')) {

        return prettyAddress(arg);

    } else if (directives && directives.includes('query')) {

        return prettyQuery(arg);

    } else if (directives && directives.includes('query-brief')) {

        return prettyQueryBrief(arg);

    } else if (directives && directives.includes('query-stats')) {

        return prettyQueryStats(arg);

    } else if (directives && directives.includes('query-countby')) {

        return prettyQueryCountBy(arg);

    }

    if (typeof arg === "string") {

        arg = applyLookups(arg, directives)

        return prettyString(arg, directives);

    } else if (typeof arg === "number") {

        return prettyNumber(arg, directives);

    } else if (arg && 'getYear' in arg) {

        return prettyDate(arg, directives);

    } else if (isNaN(arg)) {

        return '';

    } else if (typeof arg === "object") {

        return JSON.stringify(arg);

    }

    return arg;

  };

  const applyLookups = (arg, directives) => {

      if (lookups) {

          let key = arg;

          if (lookups.hasOwnProperty(key)) {

              return lookups[key];

          }

          if (directives && Array.isArray(directives)) {

              const lookup_directive = directives.find(el => el.startsWith('lookup_'));

              key = lookup_directive + '|' + key

              if (lookups.hasOwnProperty(key)) {

                return lookups[key];

              }

          }
          
      }

      return arg;

  }

  const prettyString = (str, directives) => {

      if (/_/.test(str)) {
        // Detect snake case

        str = str.replace(/_/g, " ");

      } else if (/^([a-z]+)(([A-Z]([a-z]+))+)$/.test(str)) {
        // Detect camel case

        str = str.replace(/([A-Z])/g, " $1").toLowerCase();

      }

      if (str !== undefined) {

          if (directives && directives.includes('first-letter-lower')) {

              str = str.charAt(0).toLowerCase() + str.slice(1); // Lowercase first letter

          } else if (!(directives && directives.includes('do-not-capitalise'))) {

              str = str.charAt(0).toUpperCase() + str.slice(1); // Capitalise first letter

          }

      }

      return str;

  };

  var months = [ "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December" ];

  const prettyNumber = (num, directives) => {

    if (isNaN(num)) { return 0; }

    if (directives && directives.includes('percent')) {

        //return Number(num * 100).toFixed(2);

        return Math.round((Number(num) + Number.EPSILON) * 10000) / 100;

    }

    if (directives && directives.includes('ordinal')) {

        return ordinal(num);

    }

    if (directives && directives.includes('month')) {

        return months[num - 1];

    }

    return Number(num).toLocaleString();

  };

  const ordinal = (d) => {
    if (d > 3 && d < 21) return "th";

    switch (d % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  };

  const prettyDate = (date, directives) => {
    return (
      date.getDate() +
      ( directives && directives.includes('no-ordinal') ? '' : ordinal(date.getDate()) ) +
      " " +
      ( directives && directives.includes('short-month') ? date.toLocaleString("default", { month: "short" }) : date.toLocaleString("default", { month: "long" }) ) +
      " " +
      date.getFullYear()
    );
  };

  const prettyAddress = (address) => {
    const parts = [];
    if (address.street_Address !== "") {
      parts.push(address.street_Address);
    }
    if (address.address_locality !== "") {
      parts.push(address.address_locality);
    }
    if (address.address_region !== "") {
      parts.push(address.address_region);
    }
    if (address.address_country !== "") {
      parts.push(address.address_country);
    }
    return parts.join(", ");
  };

  const prettyQuery = (query) => {

    // Create a new object with only the properties we are interested in
    let relprops = {}
    for (var k in query) {
        if (
            k !== 'filters' &&
            k !== 'series' &&
            k !== 'columns' &&
            k !== 'rows' &&
            k !== 'viz' &&
            k !== 'initviz' &&
            k !== 'from' &&
            k !== 'layerdisplay' &&
            k !== 'countby' &&
            k !== 'geocode_success'
        ) {
            relprops[k] = query[k];
        }
    }

    if (Object.keys(relprops).length === 0) {

      return ( <></> );

    }

    const ret = Object.keys(relprops).map((item, i) => {

        let seg = '';

        if (item === 'text') {
            seg = ( <span>the text matched the query '<span className="pretty-query-value">{ pretty(query[item], 'first-letter-lower') }</span>'</span> );
        } else if (item === 'year_gte') {
            seg = ( <span>the earliest year is <span className="pretty-query-value">{ query[item] }</span></span> );
        } else if (item === 'year_lte') {
            seg = ( <span>the latest year is <span className="pretty-query-value">{ query[item] }</span></span> );
        } else if (item === 'month_gte') {
            seg = ( <span>the earliest month is <span className="pretty-query-value">{ months[query[item] - 1] }</span></span> );
        } else if (item === 'month_lte') {
            seg = ( <span>the latest month is <span className="pretty-query-value">{ months[query[item] - 1] }</span></span> );
        } else {
            seg = ( <span>{ pretty(item, 'first-letter-lower') } was <span className="pretty-query-value">{ pretty(query[item], [ 'do-not-capitalise', 'lookup_' + item ]) }</span></span> );
        }

        if (i < Object.keys(relprops).length - 2) { seg = (<>{seg}, </> ); }
        if (Object.keys(relprops).length > 1 && i === Object.keys(relprops).length - 1) { seg = (<> and {seg}</> ); }

        return (<span key={ item }>{ seg }</span>);

    })

    return ( <>All records where { ret }.</> );

  }

  const prettyQueryBrief = (query) => {

    // Create a new object with only the properties we are interested in
    let relprops = {}
    for (var k in query) {
        if (
            k !== 'filters' &&
            k !== 'series' &&
            k !== 'columns' &&
            k !== 'rows' &&
            k !== 'viz' &&
            k !== 'initviz' &&
            k !== 'from' &&
            k !== 'countby'
        ) {
            relprops[k] = query[k];
        }
    }

    if (Object.keys(relprops).length === 0) {

      return ( <>all records</> );

    }

    const ret = Object.keys(relprops).map((item, i) => {

        let seg = '';

        seg = ( <span><b>{ pretty(item) }</b> <span className="pretty-query-value">{ pretty(query[item], [ 'lookup_' + item ]) }</span></span> );

        //if (i < Object.keys(relprops).length - 2) { seg = (<>{seg}, </> ); }
        //if (Object.keys(relprops).length > 1 && i === Object.keys(relprops).length - 1) { seg = (<> and {seg}</> ); }

        return (<span key={ item }>{ seg } </span>);

    })

    return ( <>{ ret }</> );

  }

  const prettyQueryStats = (query) => {

    if (query['columns']) {
        return ( <><span className="pretty-query-value-stats">{ pretty(query['series'], ['first-letter-lower']) }</span> against <span className="pretty-query-value-stats">{ pretty(query['rows'], 'first-letter-lower') }</span> against <span className="pretty-query-value-stats">{ pretty(query['columns'], ['first-letter-lower']) }</span></> ); 
    }

    if (query['rows']) {
        return ( <><span className="pretty-query-value-stats">{ pretty(query['series'], ['first-letter-lower']) }</span> (rows) against <span className="pretty-query-value-stats">{ pretty(query['rows'], ['first-letter-lower']) }</span> (columns)</> ); 
    }

    return ( <><span className="pretty-query-value-stats">{ pretty(query['series'], ['first-letter-lower']) }</span></> );

  }

  const prettyQueryCountBy = (view) => {
    
    let viewsuffix = view.split('oldbailey_', 2);

    if (viewsuffix.length > 1) { viewsuffix = viewsuffix[1]; }

    if (viewsuffix === 'record') { viewsuffix = 'trial'; }

    return ( <><span className="pretty-query-value-stats">{ viewsuffix }</span></> );

  }
  return [ pretty ];
};

export default usePretty;
