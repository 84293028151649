
import { useCallback } from "react";
import { useLocation, Link } from "react-router-dom";

import useForm from "../../../hooks/useForm";

//import usePretty from "../../../hooks/usePretty";
import usePaging from "../../../hooks/usePaging";

import SearchButton from "./SearchButton";

import useResults from "../../../hooks/useResultsNew";

import Loading from "../../Loading";
import Error from "../../Error";
import Hit from "../Hit";

import queryString from "query-string";

import useHitTerm from "../../../hooks/useHitTerm";

import Post from "../../Post";
import options_syn from '../../../data/loclon/options_syn';

const FormPlace = (props) => {

    document.title = "Place index 1746 | Locating London's Past";

    const location = useLocation();
    let qs = queryString.parse(location.search, { arrayFormat: "bracket" });

    const [ values, handleChange, handleSubmit, handleReset ] =
    useForm(false, false);

    //const [ pretty ] = usePretty();
    const pretty = props.pretty;

    const [ hitTerm, hitsTerm, keyTerm, layerControlTerm ] = useHitTerm();

    const preprocess = useCallback( () => {

        let qs = queryString.parse(location.search, { arrayFormat: "bracket" });

        const path = `${process.env.REACT_APP_API_URL}/view/loclon_polygon`;

        //qs['series'] = 'geocode_success';

        const stringified = queryString.stringify(qs, { arrayFormat: "bracket" });

        {/* This is done here so that place searches initiated from the navbar will map all hits immediately */}
        props.addQueryLayer( 'place', qs );

        return `${ path }?${ stringified }`;

    }, [ location.search ]);

    {/* Not required because queries are mapped in the preprocess callback above
    const handleSubmitSearch = (event) => {

        //event.preventDefault();
        handleSubmit(event);
        props.addQueryLayer( 'place', values );

    }
    */}

    const [ results, isLoading, error ] =
    useResults( null, false, preprocess );

    let hits;

    const pagesize = 10; // Paging requires that we know the page size 
    const [ page, handleChangePageField, handleSubmitPageForm, handleNextPageButton, handlePreviousPageButton, handleLastPageButton, handleFirstPageButton ] =
    usePaging(pagesize);

    if (results.hits && results.hits.hits) {

        hits = results.hits.hits.map((hit, idx) => (

            <Hit
            key={ hit._id }
            hit={ hit }
            idx={ ((parseInt(values['from']) || 0) + idx + 1) }
            last={ ((idx + 1) === Math.min((parseInt(values['from']) || 0) + pagesize, results.hits.total.value)) }
            addHitLayer={ props.addHitLayer }
            pretty={ pretty }
            /> 

        ));

    }

    return (

        <>

            <div>

                <div className="setinfo">
                    <Post url={ `${process.env.REACT_APP_WORDPRESS_URL}/posts?slug=synopsis-place` } options={ options_syn } />
                    {/* <span>An index of eighteenth century London place names, created by Patrick Mannix and augmented with parish and ward names. </span> */}
                    <Link target="_blank" to="/about/mapping-methodology">More information ...</Link>
                </div>

                {/* Queries are mapped from the preprocess callback so that those initiated from the navbar will map immediately */}
                <form onSubmit={ handleSubmit } onReset={ handleReset }>

                    <div className="row mb-1"><div className="col-12"><div className="input-group"><input type="search" className='form-control' name="text" id="field_text" placeholder={ 'Search place names ...' } value={ values['text'] || '' } onChange={ handleChange } /><button type="button" className="btn btn-outline-input-group btn-light bg-white border-start-0 text-muted" data-bs-toggle="modal" data-bs-target="#helpModal" data-bs-helpslug="help-placeindex-placename" onClick={ props.handleChangeHelpslug }><i className="bi bi-question-circle" /></button><SearchButton/></div></div></div>

                    <div className="row mb-1"><div className="col-12"><div className="input-group"><select className="form-select" name="geocode_folder" id="field_geocode_folder" value={ values['geocode_folder'] || '' } onChange={ handleChange }>
                        <option value="">&lt; All places &gt;</option>
                        <option value="LL_PL">Location (street / building / court etc.)</option>
                        <option value="LL_PA">Parish</option>
                        <option value="LL_WA">Ward</option>
                    </select><button type="button" className="btn btn-outline-input-group btn-light bg-white border-start-0 text-muted" data-bs-toggle="modal" data-bs-target="#helpModal" data-bs-helpslug="help-placeindex-type" onClick={ props.handleChangeHelpslug }><i className="bi bi-question-circle"></i></button></div></div></div>

                </form>
 
                { results.hits && results.hits.total.value > 0 && (

                    <div className="mb-2">

                        <span><strong className="fs-6 me-1">{ pretty(results.hits.total.value) }</strong> record{ results.hits.total.value !== 1 && ( <>s</> ) } matched this query. </span>

                        {/* Disabled because all place searches now map every hit immediately by default */}
                        {/*<span><a className="fake-link" onClick={ function() { props.addQueryLayer( 'place', qs ); }  }>Map these results</a>. </span>*/}

                        {/*
                        { results.aggregations && results.aggregations.geocode_success && results.aggregations.geocode_success.buckets.length === 2 && ( 

                            <span>
                                <b>{ pretty(results.aggregations.geocode_success.buckets[0].doc_count) }</b>
                                <span> ({ Math.round((results.aggregations.geocode_success.buckets[0].doc_count / results.hits.total.value) * 10000) / 100 }%) can be mapped. </span>
                                <span><a className="fake-link" onClick={ function() { props.addQueryLayer( 'crime', qs ); }  }>Map these results.</a></span>
                            </span>

                        ) }
                        */}

                    </div>

                ) }

                { results && results.hits && results.hits.total.value > 0 && (

                    <>

                        <div className="mb-1">
                            Showing records <strong>{ (parseInt(values['from']) || 0) + 1 }</strong> to <strong>{ Math.min((parseInt(values['from']) || 0) + pagesize, results.hits.total.value) }</strong> of <strong>{ pretty(results.hits.total.value) }</strong>
                        </div>
                        
                        <div className="paging-wrapper border-bottom pb-1 mb-2">

                            <div className="paging row g-0">

                                <div className="col-3">
                                    <button type="button" disabled={ page < 2 } className="paging-first btn btn-link text-start" onClick={ event => handleFirstPageButton(event, results.hits.total.value) }><i className="bi bi-chevron-double-left"></i></button>
                                    <button type="button" disabled={ page < 2 } className="paging-previous btn btn-link text-start" onClick={ event => handlePreviousPageButton(event, results.hits.total.value) }><i className="bi bi-chevron-left"></i></button>
                                </div>

                                <div className="col-6 text-center">
                                    <form id="paging-form" onSubmit={ event => handleSubmitPageForm(event, results.hits.total.value) }>
                                        <span className="paging-text">Page:</span>
                                        <input type="text" className="form-control ms-2 me-2 paging-input" name="page" id="field_page" value={ page } onChange={ handleChangePageField } />
                                        <span className="paging-text me-1">of </span><span className="paging-text">{ pretty(Math.ceil(results.hits.total.value / pagesize)) }</span>
                                    </form>
                                </div>

                                <div className="col-3 text-end">
                                    <button type="button" disabled={ page >= Math.ceil(results.hits.total.value / pagesize) } className="paging-next btn btn-link text-start" onClick={ event => handleNextPageButton(event, results.hits.total.value) }><i className="bi bi-chevron-right"></i></button>
                                    <button type="button" disabled={ page >= Math.ceil(results.hits.total.value / pagesize) } className="paging-last btn btn-link text-start" onClick={ event => handleLastPageButton(event, results.hits.total.value) }><i className="bi bi-chevron-double-right"></i></button>
                                </div>

                            </div>

                        </div>

                    </>

                ) }                            
                 
                <div className="hits">

                    { isLoading && ( <div className="mt-5 mb-5 pt-5 pb-5"><Loading /></div> ) }

                    { error && ( <div className="mt-5 mb-5 pt-5 pb-5"><Error error={ error } /></div> ) }

                    { (!isLoading && !error) && (

                        <>
                            { hits }
                        </>

                    ) }

                    { results && results.hits && results.hits.total.value === 0 && (

                        <div>

                            <strong className="fs-6 ms-1 me-1">No records</strong> matched this query.

                        </div>

                    ) }
           
                </div>

            </div>

        </>

    );

};

export default FormPlace;
