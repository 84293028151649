
const initLayerData = [

        { "type": "separator", "title": "Map Layers", 'id': 0 },
        //{ "description": "Rocque (1746)", "visible": true, "type": "tile", "opacity": 0.7, "url": "https://www.dhi.ac.uk/san/llptiles/mr/{z}/{x}/{y}.jpg", "attribution": '<a href="https://www.locatinglondon.org/static/MappingMethodology.html">Mapping Methodology</a>', "id": 1 },
        { "description": "Rocque (1746)", "visible": true, "type": "tile", "opacity": 0.7, "url": "https://www.dhi.ac.uk/san/llptiles/molarocque/{z}/{x}/{y}.png", "attribution": '<a href="https://www.locatinglondon.org/static/MappingMethodology.html">Mapping Methodology</a>', "id": 1 },
        //{ "description": "Ordnance Survey (1869-80)", "visible": false, "type": "tile", "opacity": 0.7, "url": "https://www.dhi.ac.uk/san/llptiles/mo/{z}/{x}/{y}.jpg", "attribution": '<a href="https://www.locatinglondon.org/static/MappingMethodology.html">Mapping Methodology</a>', 'id': 2 },
        { "description": "Ordnance Survey (1871-80)", "visible": false, "type": "tile", "opacity": 0.7, "url": "https://www.dhi.ac.uk/san/llptiles/molaos/{z}/{x}/{y}.png", "attribution": '<a href="https://www.locatinglondon.org/static/MappingMethodology.html">Mapping Methodology</a>', 'id': 2 },
        { "description": "Open Street Map (modern)", "visible": false, "type": "tile", "opacity": 0.7, "url": "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", "attribution": '&amp;copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors', 'id': 3 },
        { "description": "Satellite (ESRI ArcGIS)", "visible": false, "type": "tile", "opacity": 0.7, "url": "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}", "attribution": 'Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community', 'id': 4 },
        { "type": "separator", "title": "Data Layers", 'id': 5 },

        {
            "type": "query",
            "visible": true,
            "opacity": 0.85,
            "display": "heat-blocks-3dp",
            "stroke": "stroke",
            "colorscheme": "redopacity",
            "searchmode": "oldbailey",
            "demolayer": true,
            "specialDataUrl": '/loclon_oldbailey.json',
            "specialEditUrl": '/oldbailey?layerdisplay=heat-blocks-3dp&offence=theft&type=crimeLocation#results',
            "legend": '<i>Old Bailey Proceedings</i>: Mappable Thefts 1674-1819, by 110m<sup>2</sup> tiles',
            "qs": {
                "layerdisplay": "heat-blocks-3dp",
                "offence": "theft",
                "type": "crimeLocation",
                "from": 0
            },
            "polymarker": "polygons",
            "id": 6,
        },

];

export default initLayerData;
