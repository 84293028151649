import React from 'react';

//import GA4React from 'ga-4-react';

import ReactDOM from 'react-dom';

//import reportWebVitals from './reportWebVitals';

import App from './App';

ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById('root')
);

//const ga4react = new GA4React(`${process.env.REACT_APP_GTAG}`);

/*
(async (_) => {
  await ga4react
    .initialize()
    .then
    // (res) => console.log("Analytics Success.")
    ()
    .catch
    // (err) => console.log("Analytics Failure.")
    ()
    .finally(() => {

      ReactDOM.render(
          <React.StrictMode>
              <App />
          </React.StrictMode>,
          document.getElementById('root')
      );

      //const container = document.getElementById("root");
      //const root = createRoot(container);
      //root.render(
      //  <React.StrictMode>
      //    <BrowserRouter basename={process.env.REACT_APP_UI_BASEURL}>
      //      <App />
      //    </BrowserRouter>
      //  </React.StrictMode>
      //);
    });
})();
*/


/*
(async () => {

    try {

        if (`${process.env.REACT_APP_GTAG}` !== '') {

            await ga4react.initialize();
            //console.log('GA initialised');

        }

    } catch {

        //console.log('GA unable to load');

    } finally {

        ReactDOM.render(
            <React.StrictMode>
                <Router basename={process.env.REACT_APP_BASENAME}>
                <App />
                </Router>
            </React.StrictMode>,
            document.getElementById('root')
        );

    }

})();
*/
