
//import { useState } from "react";
//import { useParams } from "react-router-dom";


//import useForm from "../../hooks/useForm";
//import useFormSimple from "../../hooks/useFormSimple";

//import usePretty from "../../hooks/usePretty";
//import usePaging from "../../hooks/usePaging";
//import useFilters from "../../hooks/useFilters";
//import useViz from "../../hooks/useViz";

//import locLonLookups from '../../data/loclon/lookups';

//import FieldKeyword from "./fields/FieldKeyword";
//import FieldFolder from "./fields/FieldFolder";
//import FieldStatsCat1 from "./fields/FieldStatsCat1";

//import SearchButton from "./fields/SearchButton";

//import useSearchmode from "../../hooks/loclon/useSearchmode";
//import SearchSelectButton from "./forms/fields/SearchSelectButton";
//import SearchFields from "./forms/fields/SearchFields";

//import FormOldBailey from "./forms/FormOldBailey";
//import FormPlace from "./forms/FormPlace";

//import LayerOptions from "./LayerOptions";

import options from '../../data/loclon/options';

import Post from "../Post";

import FooterSmall from "./FooterSmall";

const OffcanvasWelcome = (props) => {

    /*
    const showOffcanvasGis = ( show ) => {

        const oe = document.querySelector("#offcanvasGis");
        const classname = oe.className;    

        const showing = classname.includes('show');

        if ((show && !showing) || (!show && showing)) {

            const nt = document.querySelector(".offcanvas-gis-button");

            if (nt) {
                nt.click(); // user click to hide, .hide() reloads the page in Safari!
            }

        }

    }
    */

    return (

        <>
            
            <button className="btn btn-light offcanvas-welcome-button" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasWelcome" aria-controls="offcanvasWelcome">
                Welcome
            </button>
            
            <div className="offcanvas offcanvas-end" data-bs-scroll="true" data-bs-backdrop="false" tabIndex="-1" id="offcanvasWelcome" aria-labelledby="offcanvasWelcomeLabel">

                <div className="offcanvas-body offcanvas-body-welcome">

                    <div className="over-the-thames">

                        {/*<button type="button" className="offcanvas-welcome-close btn-close" data-bs-dismiss="offcanvas" aria-label="Close">*/}
                        <button type="button" className="offcanvas-welcome-close btn" onClick={ function () { props.editDemoLayer(); props.showOffcanvasWelcome(false); } } aria-label="Close">
                            <i className="bi bi-x"/>
                        </button>

                        <h1>Welcome to Locating London’s Past</h1>
                        <div className="offcanvas-welcome-tagline">Mapping the Eighteenth Century</div>

                        <img src={ process.env.PUBLIC_URL + '/rocque.png' } className="offcanvas-welcome-rocque" alt="A figure with a measuring wheel" />

                    </div>

                    <div className="under-the-thames">

                        <img src={ process.env.PUBLIC_URL + '/rocque_reflection.png' } className="offcanvas-welcome-rocque-reflection" alt="A figure with a measuring wheel" />

                        <button onClick={ props.dismissDemoLayer } className="btn btn-light offcanvas-gis-welcome-button" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasGis" aria-controls="offcanvasGis">
                            <img src={ process.env.PUBLIC_URL + '/add_data.svg' } className="offcanvas-gis-button-svg" alt="Locating London's Past" />
                        </button>

                        <div className="mt-4">

                        {/*<button type="button" className="offcanvas-welcome-close btn-close" data-bs-toggle="offcanvas" data-bs-target="#offcanvasGis" aria-controls="offcanvasGis"></button>*/}

                            <Post url={ `${process.env.REACT_APP_WORDPRESS_URL}/posts?slug=home-page` } options={ { ...options, notitle: true  } } />                  

                        </div>

                        {/*
                        <button className="btn btn-light offcanvas-gis-welcome-button" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasGis" aria-controls="offcanvasGis">
                            <img src={ process.env.PUBLIC_URL + '/add_data.svg' } className="offcanvas-gis-button-svg" alt="Locating London's Past" />
                        </button>
                        */}

                    </div>

                    <FooterSmall/>

                </div>

            </div>

        </>

    );

};

export default OffcanvasWelcome;
