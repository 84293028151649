
//import queryString from "query-string";
import { useState } from "react";
//import { useHistory, useLocation } from "react-router-dom";

const useFormSimple= (initvals) => {

    //const location = useLocation();
    //let history = useHistory();

    //let real_initvals = {};
    //if (initvalstring) {
    //    let initvals = queryString.parse(initvalstring, { arrayFormat: "bracket" });
    //    real_initvals = {...initvals};
    //}

    const [values, setValues] = useState(initvals);

    const handleChange = (event) => {

        let vals = values;
        vals[event.target.name] = event.target.value;

        if (event.target.type === 'checkbox') {
            vals[event.target.name] = event.target.checked;
        }    

        setValues({...vals});   // {...foo} is the js object spread syntax. It is needed here because react needs to see a new object in order to update the state

    };

    const setVal = (key, val) => {

        let vals = values;
        vals[key] = val;

        setValues({...vals});   // {...foo} is the js object spread syntax. It is needed here because react needs to see a new object in order to update the state

    };

    const handleReset = (event) => {

        // This function is strictly to be called only by the form reset button
        
        event.preventDefault();

        let vals = values;
        for(var k in vals) { vals[k] = ''; }

        setValues({...vals}); // {...foo} is the js object spread syntax. It is needed here because react needs to see a new object in order to update the state

    };

    return [ values, handleChange, handleReset, setVal ];
    
};

export default useFormSimple;
