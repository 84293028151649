
const SearchButton = () => {
    
    return (

        <>

            <button type="submit" className="btn btn-outline-input-group btn-light bg-primary text-white search-feature-inline-submit">
                <i className="bi bi-search" />
            </button>

        </>

    );

};

export default SearchButton;
