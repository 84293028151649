
const SearchmodeButton = (props) => {

    const searchmode = props.searchmode;
    const handleSearchmodeClick = props.handleSearchmodeClick;
   
    return (

        <>

        {/*
        <div className="search-select-tooltip-container">
            <div className="search-select-tooltip">
                You can choose different ways to search using this button.
            </div>
        </div>
        */}

        <div className="dropdown d-grid gap-2 mb-1"> 
            <button className="btn btn-light bg-primary text-white dropdown-toggle search-feature-mode-select text-start" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                { searchmode === '' && ( <><span className="ms-2 me-3">Choose a dataset ...</span></> ) }
                { searchmode === 'place' && ( <><i className="bi bi-signpost"></i><span className="ms-2 me-3">Place index 1746</span></> ) }
                { searchmode === 'ppop' && ( <><i className="bi bi-bar-chart-line"></i><span className="ms-2">Population and Area Data 1690-1801</span></> ) }
                { searchmode === 'oldbailey' && ( <><i className="bi bi-bank"></i><span className="ms-2 me-3">Old Bailey Proceedings 1674-1819</span></> ) }
                { searchmode === 'llcoroner' && ( <><i className="bi bi-list-columns-reverse"></i><span className="ms-2">Coroners' Records 1747-99</span></> ) }
                { searchmode === 'llcriminal' && ( <><i className="bi bi-bank2"></i><span className="ms-2">Criminal Justice 1676-1800</span></> ) }
                { searchmode === 'llguild' && ( <><i className="bi bi-hospital"></i><span className="ms-2">Hospital and Guild 1721-87</span></> ) }
                { searchmode === 'llpoor' && ( <><i className="bi bi-prescription2"></i><span className="ms-2">Poor Relief 1688-1799</span></> ) }
                { searchmode === 'fire' && ( <><i className="bi bi-fire"></i><span className="ms-2">Fire Insurance 1777-86</span></> ) }
                { searchmode === 'ahdsfsp' && ( <><i className="bi bi-currency-pound"></i><span className="ms-2">Four Shillings in the Pound Tax 1689-97</span></> ) }
                { searchmode === 'ahdsdir' && ( <><i className="bi bi-card-list"></i><span className="ms-2">London and Westminster Directory 1774</span></> ) }
                { searchmode === 'pcc' && ( <><i className="bi bi-list-columns"></i><span className="ms-2">PCC Wills 1680-1819</span></> ) }
                { searchmode === 'lsdspar' && ( <><i className="bi bi-buildings"></i><span className="ms-2">St. Botolph Aldgate Parish Registers 1681-1709</span></> ) }
                { searchmode === 'westrate' && ( <><i className="bi bi-card-text"></i><span className="ms-2">Westminster Historical Database (ratebooks) 1749-1820</span></> ) }
                { searchmode === 'westpoll' && ( <><i className="bi bi-card-checklist"></i><span className="ms-2">Westminster Historical Database (votes) 1749-1820</span></> ) }                 
                { searchmode === 'cmhht' && ( <><i className="bi bi-house"></i><span className="ms-2">Hearth Tax 1662-66</span></> ) }
                { searchmode === 'cmhpd' && ( <><i className="bi bi-list-columns-reverse"></i><span className="ms-2">Plague Deaths 1665</span></> ) }
                { searchmode === 'molag' && ( <><i className="bi bi-cup-straw"></i><span className="ms-2">Glass 1660-1800</span></> ) }
                { searchmode === 'molacp' && ( <><i className="bi bi-archive"></i><span className="ms-2">Clay Pipes 1660-1800</span></> ) }
            </button>
            <ul className="dropdown-menu">
                <li><button type="button" className={ 'dropdown-item mode-select-dropdown-item' + (searchmode === 'place' ? ' active' : '') } data-searchmode="place" onClick={ handleSearchmodeClick } ><i className="bi bi-signpost"></i><span className="ms-2">Place index 1746</span></button></li>
                <li><button type="button" className={ 'dropdown-item mode-select-dropdown-item' + (searchmode === 'ppop' ? ' active' : '') } data-searchmode="ppop" onClick={ handleSearchmodeClick } ><i className="bi bi-bar-chart-line"></i><span className="ms-2">Population and Area Data 1690-1801</span></button></li>
                <li><button type="button" className={ 'dropdown-item mode-select-dropdown-item' + (searchmode === 'oldbailey' ? ' active' : '') } data-searchmode="oldbailey" onClick={ handleSearchmodeClick } ><i className="bi bi-bank"></i><span className="ms-2">Old Bailey Proceedings 1674-1819</span></button></li>

                <li className="searchmode-heading">London Lives</li>
                <li><button type="button" className={ 'dropdown-item mode-select-dropdown-item' + (searchmode === 'llcoroner' ? ' active' : '') } data-searchmode="llcoroner" onClick={ handleSearchmodeClick } ><i className="bi bi-list-columns-reverse"></i><span className="ms-2">Coroners' Records 1747-99</span></button></li>
                <li><button type="button" className={ 'dropdown-item mode-select-dropdown-item' + (searchmode === 'llcriminal' ? ' active' : '') } data-searchmode="llcriminal" onClick={ handleSearchmodeClick } ><i className="bi bi-bank2"></i><span className="ms-2">Criminal Justice 1676-1800</span></button></li>
                <li><button type="button" className={ 'dropdown-item mode-select-dropdown-item' + (searchmode === 'llguild' ? ' active' : '') } data-searchmode="llguild" onClick={ handleSearchmodeClick } ><i className="bi bi-hospital"></i><span className="ms-2">Hospital and Guild 1721-87</span></button></li>
                <li><button type="button" className={ 'dropdown-item mode-select-dropdown-item' + (searchmode === 'llpoor' ? ' active' : '') } data-searchmode="llpoor" onClick={ handleSearchmodeClick } ><i className="bi bi-prescription2"></i><span className="ms-2">Poor Relief 1688-1799</span></button></li>

                <li className="searchmode-heading">London Lives Datasets</li>
                <li><button type="button" className={ 'dropdown-item mode-select-dropdown-item' + (searchmode === 'fire' ? ' active' : '') } data-searchmode="fire" onClick={ handleSearchmodeClick } ><i className="bi bi-fire"></i><span className="ms-2">Fire Insurance 1777-86</span></button></li>
                <li><button type="button" className={ 'dropdown-item mode-select-dropdown-item' + (searchmode === 'ahdsfsp' ? ' active' : '') } data-searchmode="ahdsfsp" onClick={ handleSearchmodeClick } ><i className="bi bi-currency-pound"></i><span className="ms-2">Four Shillings in the Pound Tax 1689-97</span></button></li>
                <li><button type="button" className={ 'dropdown-item mode-select-dropdown-item' + (searchmode === 'ahdsdir' ? ' active' : '') } data-searchmode="ahdsdir" onClick={ handleSearchmodeClick } ><i className="bi bi-card-list"></i><span className="ms-2">London and Westminster Directory 1774</span></button></li>
                <li><button type="button" className={ 'dropdown-item mode-select-dropdown-item' + (searchmode === 'pcc' ? ' active' : '') } data-searchmode="pcc" onClick={ handleSearchmodeClick } ><i className="bi bi-list-columns"></i><span className="ms-2">PCC Wills 1680-1819</span></button></li>
                <li><button type="button" className={ 'dropdown-item mode-select-dropdown-item' + (searchmode === 'lsdspar' ? ' active' : '') } data-searchmode="lsdspar" onClick={ handleSearchmodeClick } ><i className="bi bi-buildings"></i><span className="ms-2">St. Botolph Aldgate Parish Registers 1681-1709</span></button></li>
                <li><button type="button" className={ 'dropdown-item mode-select-dropdown-item' + (searchmode === 'westrate' ? ' active' : '') } data-searchmode="westrate" onClick={ handleSearchmodeClick } ><i className="bi bi-card-text"></i><span className="ms-2">Westminster Historical Database (ratebooks) 1749-1820</span></button></li>
                <li><button type="button" className={ 'dropdown-item mode-select-dropdown-item' + (searchmode === 'westpoll' ? ' active' : '') } data-searchmode="westpoll" onClick={ handleSearchmodeClick } ><i className="bi bi-card-checklist"></i><span className="ms-2">Westminster Historical Database (votes) 1749-1820</span></button></li>
                  
                <li className="searchmode-heading">Mortality, Plague, and the Hearth Tax, 1665-66</li>
                <li><button type="button" className={ 'dropdown-item mode-select-dropdown-item' + (searchmode === 'cmhht' ? ' active' : '') } data-searchmode="cmhht" onClick={ handleSearchmodeClick } ><i className="bi bi-house"></i><span className="ms-2">Hearth Tax 1662-66</span></button></li>
                <li><button type="button" className={ 'dropdown-item mode-select-dropdown-item' + (searchmode === 'cmhpd' ? ' active' : '') } data-searchmode="cmhpd" onClick={ handleSearchmodeClick } ><i className="bi bi-list-columns-reverse"></i><span className="ms-2">Plague Deaths 1665</span></button></li>

                <li className="searchmode-heading">Archaeological Evidence></li>
                <li><button type="button" className={ 'dropdown-item mode-select-dropdown-item' + (searchmode === 'molag' ? ' active' : '') } data-searchmode="molag" onClick={ handleSearchmodeClick } ><i className="bi bi-cup-straw"></i><span className="ms-2">Glass 1660-1800</span></button></li>
                <li><button type="button" className={ 'dropdown-item mode-select-dropdown-item' + (searchmode === 'molacp' ? ' active' : '') } data-searchmode="molacp" onClick={ handleSearchmodeClick } ><i className="bi bi-archive"></i><span className="ms-2">Clay Pipes 1660-1800</span></button></li>

            </ul> 
        </div>

        </>

    );

};

export default SearchmodeButton;
