
import useHitTerm from "../../../hooks/useHitTerm";

const DisplayOptions = (props) => {   

    const [ , hitsTerm, , ] = useHitTerm();

    return (
        
        <>

        { props.searchmode === 'fire' && (
            <>
            <option value="place-insval-avg">Average insured value, by place.</option>
            <option value="parish-insval-avg">Average insured value, by parish.</option>
            <option value="ward-insval-avg">Average insured value, by ward.</option>
            <option value="heat-blocks-3dp-insval-avg">Average insured value, by 110m&sup2; tiles..</option>
            </>
        ) }

        { props.searchmode === 'ahdsfsp' && (
            <>
            <option value="place-rent-tax-avg">Average rent tax, by place.</option>
            <option value="parish-rent-tax-avg">Average rent tax, by parish.</option>
            <option value="ward-rent-tax-avg">Average rent tax, by ward.</option>
            <option value="heat-blocks-3dp-rent-tax-avg">Average rent tax, by 110m&sup2; tiles..</option>
            <option value="place-per-tax-avg">Average personal tax, by place.</option>
            <option value="parish-per-tax-avg">Average personal tax, by parish.</option>
            <option value="ward-per-tax-avg">Average personal tax, by ward.</option>
            <option value="heat-blocks-3dp-per-tax-avg">Average personal tax, by 110m&sup2; tiles..</option>
            </>
        ) }

        { props.searchmode === 'westrate' && (
            <>
            <option value="place-tax-avg">Average rental values, by place.</option>
            <option value="parish-tax-avg">Average rental values, by parish.</option>
            <option value="ward-tax-avg">Average rental values, by ward.</option>
            <option value="heat-blocks-3dp-tax-avg">Average rental values, by 110m&sup2; tiles..</option>
            </>
        ) }

        { props.searchmode === 'cmhht' && (
            <>
            <option value="place-hearths-total-avg">Average hearths, by place.</option>
            <option value="parish-hearths-total-avg">Average hearths, by parish.</option>
            <option value="ward-hearths-total-avg">Average hearths, by ward.</option>
            <option value="heat-blocks-3dp-hearths-total-avg">Average hearths, by 110m&sup2; tiles..</option>
            </>
        ) }

        { props.searchmode === 'cmhpd' && (
            <>
            {/*
            <option value="place-deaths-sum">Sum of deaths, by place.</option>
            */}
            <option value="parish-deaths-sum-pop1690s">Sum of deaths, by parish, moderated by population (1690s).</option>
            <option value="parish-deaths-sum-pop1695">Sum of deaths, by parish, moderated by population (1695).</option>
            <option value="parish-deaths-sum">Sum of deaths, by parish.</option>
            <option value="parish-deaths-sum-area">Sum of deaths, by parish, moderated by area.</option>
            {/*
            <option value="parish-deaths-sum-pop1740s">Sum of deaths, by parish, moderated by population (1740s).</option>
            <option value="parish-deaths-sum-pop1801">Sum of deaths, by parish, moderated by population (1801).</option>
            <option value="ward-deaths-sum">Sum of deaths, by ward.</option>
            <option value="heat-blocks-3dp-deaths-sum">Sum of deaths, by 110m&sup2; tiles..</option>
            */}
            </>
        ) }

        { props.searchmode !== 'cmhpd' && (

            <>
            <option value="place">Mappable { hitsTerm(props.searchmode) }</option>
            <option value="parish">Mappable { hitsTerm(props.searchmode) }, by parish</option>
            <option value="parish-area">Mappable { hitsTerm(props.searchmode) }, by parish, moderated by area</option>
            <option value="parish-pop1690s">Mappable { hitsTerm(props.searchmode) }, by parish, moderated by population (1690s)</option>
            <option value="parish-pop1695">Mappable { hitsTerm(props.searchmode) }, by parish, moderated by population (1695)</option>
            <option value="parish-pop1740s">Mappable { hitsTerm(props.searchmode) }, by parish, moderated by population (1740s)</option>
            <option value="parish-pop1801">Mappable { hitsTerm(props.searchmode) }, by parish, moderated by population (1801)</option>
            <option value="ward">Mappable { hitsTerm(props.searchmode) }, by ward</option>
            {/*<option value="heat">Heatmap</option>*/}
            {/*<option value="heat-blocks">Heatmap Blocks</option>*/}
            <option value="heat-blocks-3dp">Mappable { hitsTerm(props.searchmode) }, by 110m&sup2; tiles</option>
            {/*<option value="heat-blocks-2dp">Heatmap (1.1km)</option>*/}
            </>

        ) }

        </>

    );

};

export default DisplayOptions;

