
import parse from 'html-react-parser';

import usePost from "../hooks/usePost";

import useAnchor from "../hooks/useAnchor";

import Loading from "./Loading";
import Error from "./Error";

const Post = (props) => {

    const DEBUG = false;

    const [ post, isLoading, error ] = usePost(props.url);
    useAnchor();

    return (

        <>

            {/*<div className="container" style={ { maxWidth: '900px' } }>*/}
                
                { (post[0] && DEBUG) && ( 
                    <div className="mt-3 mb-2 text-muted">
                        <p>You can edit this Wordpress post here: </p>
                        <a target="_blank" rel="noreferrer" href={ post[0]['link'] }>{ post[0]['link'] }</a>
                    </div>
                ) }

                { isLoading && ( <div className="mt-5 pt-5 mb-5 mb-5"><Loading /></div> ) }

                { error && ( <div className="mt-5 pt-5 mb-5 mb-5"><Error error={ error } /></div> ) }

                { !isLoading && !error && (

                    <>

                    { !post[0] && (

                        <>

                            { DEBUG && (

                                <div className="text-muted">

                                    <p>The website searched for a Wordpress post with the permalink <b>{ props.url.substring(64) }</b>, but it does not exist.</p>

                                    <p>There are two possibilities:</p>
                                    
                                    <ul>

                                        <li>This post that should go here exists, but it has a different permalink. You can search all of the posts
                                        on the Wordpress site here: <a target="_blank" rel="noreferrer" href="https://www.dhi.ac.uk/blogs/old-bailey/wp-admin/edit.php">https://www.dhi.ac.uk/blogs/old-bailey/wp-admin/edit.php</a>. If
                                        you can find the post that should go here, please email the site maintainer, quoting this permalink: <b>{ props.url.substring(64) }</b>, and
                                        the permalink of the post that should go here (viewable in the right hand bar of the Wordpress edit interface for that post, with the label 'URL'). If the
                                        right hand bar is not visible, click the cog icon in the top bar of the Wordpress editor.</li>

                                        <li>The post that should go here does not exist yet. You can add a new post to the wordpress site here: <a target="_blank" rel="noreferrer" href="https://www.dhi.ac.uk/blogs/old-bailey/wp-admin/post-new.php">https://www.dhi.ac.uk/blogs/old-bailey/wp-admin/post-new.php</a>. When
                                        you have finished creating the post, you must set its permalink to be: <b>{ props.url.substring(64) }</b>.
                                        </li>
                                    </ul>

                                </div>

                            ) }

                            <h5 className="mt-5 pt-5 mb-5 pb-5 fw-bold">
                                This post could not be found.
                            </h5>

                        </>

                    ) }

                    { post[0] && (

                        <>

                            { !props.options.notitle && ( <h1>{ parse(post[0]['title']['rendered'], props.options) }</h1> ) }

                            { parse(post[0]['content']['rendered'], props.options) }

                            {/*
                            <pre>
                                { JSON.stringify(post, null, 2) }
                            </pre>
                            */}

                        </>

                    ) }

                    </>

                ) }

            {/*</div>*/}

        </>

    );

};

export default Post;
