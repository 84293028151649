
import { Link } from "react-router-dom";

//import dhilogo from "../../data/oldbailey/dhilogo.svg";

const Footer = () => {

    return (

        <footer>

            <div id="footer-top-links">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12 text-center p-2">
                            <Link className="" to={ "/about/copyright" }>
                                Copyright & Citation
                            </Link>
                            <span className="vertical-divider">|</span>
                            <Link className="" to={ "/about/project" }>
                                About this Project
                            </Link>
                            <span className="vertical-divider">|</span>
                            <Link className="" to={ "/about/contact" }>
                                Contact Us
                            </Link>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container">
                <div className="row pt-4 pb-4">
                    <div className="col-12 col-md-8 col-lg-10 mb-3">
                        <h2>About this website</h2>
                        This website is published by the <a href="https://www.dhi.ac.uk/">Digital Humanities Institute</a> at the 
                        University of Sheffield. See <Link to={ '/about/about-this-project' }>About this Project</Link>.
                    </div>
                    <div className="col-12 col-md-4 col-lg-2">

                        <div className="d-block d-md-none">
                            <div className="row">
                                <div className="col-6 col-md-12 order-md-2 text-md-end">
                                    <a href="https://www.dhi.ac.uk/">
                                        <img src={ process.env.PUBLIC_URL + '/dhilogo.svg' } className="img-fluid dhilogo" alt="The Digital Humanities Institute Sheffield logo" />
                                    </a>
                                </div>
                                <div className="col-6 col-md-12 order-md-1 text-white footer-version text-end mb-3">
                                    <div>Version 2.0</div>
                                    <div>Summer 2024</div>
                                </div>
                            </div>
                        </div>

                        <div className="d-none d-md-block">
                            <div className="text-white ms-3 footer-version">
                                <div>Version 2.0</div>
                                <div>Summer 2024</div>
                            </div>
                            <a href="https://www.dhi.ac.uk/">
                                <img src={ process.env.PUBLIC_URL + '/dhilogo.svg' } className="img-fluid dhilogo" alt="The Digital Humanities Institute Sheffield logo" />
                            </a>
                        </div>

                    </div>

                </div>
            </div>

            <div className="container-fluid">
                <div className="row">
                    <div id="footer-bottom-links" className="col-12 text-center p-2">
                            {/*
                            Version 9.0
                        <span className="vertical-divider">|</span>
                            Autumn 2023
                        <span className="vertical-divider">|</span>
                            */}
                        {/*
                            ISBN 978-0-9557876-0-7
                        <span className="vertical-divider">|</span>
                        */}
                            &copy; 2011-2024 Locating London's Past
                    </div>
                </div>
            </div> 

        </footer>

    );

};

export default Footer;
