
const lookups = {

    "div_type": "the type of text",

    "kill": "killing",

    "keepingABrothel": "keeping a brothel",
    "concealingABirth": "concealing a birth",

    "transport": "transportation",

    "imprison": "imprisonment",

    "miscPunish": "miscellaneous",
    "miscVerdict": "miscellaneous",

    "noPunish": "no punishment",

    "gameLawOffence": "game law offences",

    "imprisonNoDetail": "imprisonment (no detail)",
    "transportNoDetail": "transportation",
    "deathNoDetail": "death (no detail)",
    "miscellaneousNoDetail": "miscellaneous (no detail)",
    "damageNoDetail": "damage (no detail)",
    "sexualNoDetail": "sexual (no detail)",
    "breakingPeaceNoDetail": "breaking peace (no detail)",
    "deceptionNoDetail": "deception (no detail)",
    "theftNoDetail": "theft (no detail)",
    "killNoDetail": "killing (no detail)",
    "royalOffencesNoDetail": "royal offences (no detail)",

    "guiltyNoDetail": "guilty (no detail)",
    "notGuiltyNoDetail": "not guilty (no detail)",
    "specialVerdictNoDetail": "special verdict",
    "miscVerdictNoDetail": "miscellaneous (no detail)",

    "miscPunishNoDetail": "miscellaneous (no detail)",
    "noPunishNoDetail": "no punishment (no detail)",
    "corporalNoDetail": "corporal (no detail)",

    "theftOther": "theft (other)",
    "miscellaneousOther": "miscellaneous (other)",
    "killOther": "killing (other)",
    "damageOther": "damage (other)",
    "sexualOther": "sexual (other)",
    "deceptionOther": "deception (other)",
    "royalOffencesOther": "royal offences (other)",
    "breakingPeaceOther": "breaking peace (other)",
    "violentTheftOther": "violent theft (other)",

    "theftunder1s": "theft under 1s",
    "theftunder5s": "theft under 1s",
    "theftunder10s": "theft under 10s",
    "theftunder40s": "theft under 40s",
    "theftunder100s": "theft under 100s",

    "victim_hisco_class": "victim occupational group",
    "victim_hisco_label": "victim occupational category",

    "victim_hisco_class_1": "victim occupational group",

    "lookup_victim_hisco_class_1|0.5" : "0.5 : Aristocrats",
    "lookup_victim_hisco_class_1|1" : "1  : Higher managers",
    "lookup_victim_hisco_class_1|2" : "2  : Higher professionals",
    "lookup_victim_hisco_class_1|3" : "3  : Lower managers",
    "lookup_victim_hisco_class_1|4" : "4  : Medium skilled professionals, clerical and sales personnel",
    "lookup_victim_hisco_class_1|5" : "5  : Lower skilled clerical and sales personnel",
    "lookup_victim_hisco_class_1|6" : "6  : Foremen",
    "lookup_victim_hisco_class_1|6.5" : "6.5 : Small scale manufacturers",
    "lookup_victim_hisco_class_1|7" : "7  : Medium-skilled workers",
    "lookup_victim_hisco_class_1|8" : "8  : Farmers and fishermen",
    "lookup_victim_hisco_class_1|9" : "9  : Lower-skilled workers",
    "lookup_victim_hisco_class_1|10" : "10 : Lower-skilled farm workers",
    "lookup_victim_hisco_class_1|11" : "11 : Unskilled workers",
    "lookup_victim_hisco_class_1|12" : "12 : Unskilled farm workers",

    "defendant_age_max_lte" : "maximum defendant age",
    "defendant_age_min_gte" : "minimum defendant age",

    "victim_age_max_lte" : "maximum victim age",
    "victim_age_min_gte" : "minimum victim age",


/*
    "lookup_ldata_display|place-insval-avg" : "average insured value by place",
    "lookup_ldata_display|parish-insval-avg" : "average insured value by parish",
    "lookup_ldata_display|ward-insval-avg" : "average insured value by ward",
    "lookup_ldata_display|heat-blocks-3dp-insval-avg" : "average insured value by 110m2 tiles.",

    "lookup_ldata_display|place-rent-tax-avg" : "average rent tax by place",
    "lookup_ldata_display|parish-rent-tax-avg" : "average rent tax by parish",
    "lookup_ldata_display|ward-rent-tax-avg" : "average rent tax by ward",
    "lookup_ldata_display|heat-blocks-3dp-rent-tax-avg" : "average rent tax by 110m2 tiles.",
    "lookup_ldata_display|place-per-tax-avg" : "average personal tax by place",
    "lookup_ldata_display|parish-per-tax-avg" : "average personal tax by parish",
    "lookup_ldata_display|ward-per-tax-avg" : "average personal tax by ward",
    "lookup_ldata_display|heat-blocks-3dp-per-tax-avg" : "average personal tax by 110m2 tiles.",

    "lookup_ldata_display|place-tax-avg" : "average rental values by place",
    "lookup_ldata_display|parish-tax-avg" : "average rental values by parish",
    "lookup_ldata_display|ward-tax-avg" : "average rental values by ward",
    "lookup_ldata_display|heat-blocks-3dp-tax-avg" : "average rental values by 110m2 tiles.",

    "lookup_ldata_display|place-hearths-total-avg" : "average total hearths by place",
    "lookup_ldata_display|parish-hearths-total-avg" : "average total hearths by parish",
    "lookup_ldata_display|ward-hearths-total-avg" : "average total hearths by ward",
    "lookup_ldata_display|heat-blocks-3dp-hearths-total-avg" : "average total hearths by 110m2 tiles.",

    "lookup_ldata_display|place-deaths-sum" : "sum of deaths by place",
    "lookup_ldata_display|parish-deaths-sum" : "sum of deaths by parish",
    "parish-deaths-sum-area" : "sum of deaths, by parish, moderated by area",
    "parish-deaths-sum-pop1690s" : "sum of deaths, by parish, moderated by population (1690s)",
    "parish-deaths-sum-pop1695" : "sum of deaths, by parish, moderated by population (1695)",
    "parish-deaths-sum-pop1740s" : "sum of deaths, by parish, moderated by population (1740s)",
    "parish-deaths-sum-pop1801" : "sum of deaths, by parish, moderated by population (1801)",
    "lookup_ldata_display|ward-deaths-sum" : "sum of deaths by ward",
    "lookup_ldata_display|heat-blocks-3dp-deaths-sum" : "sum of deaths by 110m2 tiles.",

    "lookup_ldata_display|place" : "Mappable places",
    "lookup_ldata_display|parish" : "Mappable places, by parish",
    "lookup_ldata_display|parish-area" : "Mappable places, by parish, moderated by area",
    "lookup_ldata_display|parish-pop1690s" : "Mappable places, by parish, moderated by population (1690s)",
    "lookup_ldata_display|parish-pop1695" : "Mappable places, by parish, moderated by population (1695)",
    "lookup_ldata_display|parish-pop1740s" : "Mappable places, by parish, moderated by population (1740s)",
    "lookup_ldata_display|parish-pop1801" : "Mappable places, by parish, moderated by population (1801)",
    "lookup_ldata_display|ward" : "Mappable places, by ward",

    "lookup_ldata_display|heat-blocks-3dp" : "Mappable places, by 110m2 tiles",
*/

    "lookup_ldata_searchmode|place" : "Place index",
    "lookup_ldata_searchmode|ppop" : "Population and Area Data",
    "lookup_ldata_searchmode|oldbailey" : "Old Bailey Proceedings", 
    "lookup_ldata_searchmode|llcoroner" : "Coroners' Records",
    "lookup_ldata_searchmode|llcriminal" : "Criminal Justice",
    "lookup_ldata_searchmode|llguild" : "Hospital and Guild",
    "lookup_ldata_searchmode|llpoor" : "Poor Relief", 
    "lookup_ldata_searchmode|fire" : "Fire Insurance", 
    "lookup_ldata_searchmode|ahdsfsp" : "Four Shillings in the Pound Tax",
    "lookup_ldata_searchmode|ahdsdir" : "London and Westminster Directory",
    "lookup_ldata_searchmode|pcc" : "PCC Wills",
    "lookup_ldata_searchmode|lsdspar" : "St. Botolph Aldgate Parish Registers",
    "lookup_ldata_searchmode|westrate" : "Westminster Historical Database (ratebooks)",
    "lookup_ldata_searchmode|westpoll" : "Westminster Historical Database (votes)", 
    "lookup_ldata_searchmode|cmhht" : "Hearth Tax",
    "lookup_ldata_searchmode|cmhpd" : "Plague Deaths",
    "lookup_ldata_searchmode|molag" : "Glass",
    "lookup_ldata_searchmode|molacp" : "Clay Pipes",

    "lookup_ldata_ppopdata|pop1690s" : "1690s Bills of Mortality",
    "lookup_ldata_ppopdata|den1690s" : "1690s Bills of Mortality (density)",
    "lookup_ldata_ppopdata|pop1695" : "1695 Marriage Duty",
    "lookup_ldata_ppopdata|den1695" : "1695 Marriage Duty (density)",
    "lookup_ldata_ppopdata|pop1740s" : "1740s Bills of Mortality",
    "lookup_ldata_ppopdata|den1740s" : "1740s Bills of Mortality (density)",
    "lookup_ldata_ppopdata|pop1801" : "1801 Census",
    "lookup_ldata_ppopdata|den1801" : "1801 Census (density)",
    "lookup_ldata_ppopdata|area" : "Area",


    "div1type" : "document type",

    "BA_ACpage" : "St Botolph Aldgate; Account Books",
    "BA_IApage" : "St Botolph Aldgate; Apprenticeship Records",
    "BA_LPpage" : "St Botolph Aldgate; Lists of Paupers",
    "BA_AOpage" : "St Botolph Aldgate; Miscellaneous Account Books",
    "BA_MOpage" : "St Botolph Aldgate; Misc. Parish Minute Books",
    "BA_EPpage" : "St Botolph Aldgate; Pauper Examinations",
    "BA_APpage" : "St Botolph Aldgate; Payments to Paupers",
    "BA_RApage" : "St Botolph Aldgate; Registers of Pauper Apprentices",
    "BA_RCpage" : "St Botolph Aldgate; Registers of Poor Children",
    "BA_MVpage" : "St Botolph Aldgate; Vestry Minutes",
    "BA_RWpage" : "St Botolph Aldgate; Workhouse Admissions Registers",

    "CD_page" : "St Clement Danes; all documents",
    "CD_BNpage" : "St Clement Danes; Clothing and Nursing Books",
    "CD_BCpage" : "St Clement Danes; Clothing Books",
    "CD_BEpage" : "St Clement Danes; Enfield Books",
    "CD_LPpage" : "St Clement Danes; Lists of weekly pensioners",
    "CD_LWpage" : "St Clement Danes; Lists of Persons in the Workhouse",
    "CD_AOpage" : "St Clement Danes; Miscellaneous Account Books",
    "CD_PMpage" : "St Clement Danes; Miscellaneous Papers",
    "CD_MOpage" : "St Clement Danes; Misc. Parish Minute Books",
    "CD_OOpage" : "St Clement Danes; Overseers Orders Book",
    "CD_PApage" : "St Clement Danes; Parish Apprenticeship Records",
    "CD_EPpage" : "St Clement Danes; Pauper Examinations",
    "CD_RVpage" : "St Clement Danes; Pauper Removal Orders",
    "CD_APpage" : "St Clement Danes; Payments to Paupers",
    "CD_RRpage" : "St Clement Danes; Registers of Bastard Children",
    "CD_RDpage" : "St Clement Danes; Registers of Exams",
    "CD_RCpage" : "Clement Danes; Registers of Poor Children",
    "CD_RPpage" : "St Clement Danes; Registers of the Poor",
    "CD_MVpage" : "St Clement Danes; Vestry Minutes",

    "DB_page" : "St Dionis Backchurch; all documents",
    "DB_ACpage" : "St Dionis Backchurch; Account Books",
    "DB_WBpage" : "St Dionis Backchurch; Bastardy Bonds",
    "DB_PPpage" : "St Dionis Backchurch; Churchwardens' Voucher/Receipts",
    "DB_BCpage" : "St Dionis Backchurch; Clothing Books",
    "DB_PRpage" : "St Dionis Backchurch; Letters Seeking Pauper Relief",
    "DB_PMpage" : "St Dionis Backchurch; Miscellaneous Papers",
    "DB_MOpage" : "St Dionis Backchurch; Miscellaneous Parish Minute Books",
    "DB_RIpage" : "St Dionis Backchurch; Register of Poor Infants",
    "DB_MVpage" : "St Dionis Backchurch; Vestry Minutes",
    "DB_IWpage" : "St Dionis Backchurch; Workhouse Inquest Minute Books",

    "BR_page" : "Bridewell Royal Hospital; all documents",
    "BR_IApage" : "Bridewell Royal Hospital; Apprentice Records",
    "BR_MBpage" : "Bridewell Royal Hospital; Prison Committee Minute Books",
    "BR_MGpage" : "Bridewell Royal Hospital; Minutes of the Court of Governors",
    "BR_PMpage" : "Bridewell Royal Hospital; Miscellaneous Papers",
    "BR_RApage" : "Bridewell Royal Hospital; Registers of Pauper Apprentices",
    "HO_CRpage" : "Home Office; Criminal Registers",
    "sessionsPaper" : "Old Bailey Proceedings; Accounts of Criminal Trials",
    "OB_PSpage" : "Old Bailey Sessions; Sessions Papers",
    "OApage" : "Ordinary's Accounts; Biographies of Executed Convicts",
    "SL_PSpage" : "City of London Sessions; Sessions Papers",

    "SM_page" : "Middlesex Sessions; all documents",
    "SM_GOpage" : "Middlesex Sessions; Orders of the Court",
    "SM_PSpage" : "Middlesex Sessions; Sessions Papers",
    "WJ_PSpage" : "Westminster Sessions; Sessions Papers",
    "CC_MCpage" : "Carpenters' Company; Court/Committee Minute Books",

    "CO_ICpage" : "Middlesex Coroners' Inquests",
    "CW_ICpage" : "City of Westminster Coroners' Inquests",
    "CL_ICpage" : "City of London Coroners' Inquests",

    "TH_page" : "St Thomas's Hospital; all documents",
    "TH_MCpage" : "St Thomas's Hospital; Court/Committee Minute Books",
    "TH_RHpage" : "St Thomas's Hospital; Hospital Admissions Registers",
    "TH_LTpage" : "St Thomas's Hospital; Lists of Governor Takers-In",
    "TH_MGpage" : "St Thomas's Hospital; Minutes of the Court of Governors",
    "TH_LBpage" : "St Thomas's Hospital; Out Letter Books",

    "ahdsfspFile" : "Additional Datasets; 4 Shillings in the &pound; Tax, 1693-94",
    "lsdsmdaFile" : "Additional Datasets; Aldgate Marriage Duty Tax, 1695",
    "lsdsparFile" : "Additional Datasets; Aldgate Parish Registers, 1681-1709",
    "lsdspolFile" : "Additional Datasets; Aldgate Poll Tax, 1690-98",
    "fireFile" : "Additional Datasets; Fire Insurance Registers, 1777-86",
    "ahdsitpFile" : "Additional Datasets; Income Tax Payments 1799-1802",
    "ahdsdirFile" : "Additional Datasets; London/Westminster Directory, 1774",
    "ahdsmarFile" : "Additional Datasets; Marine Society Boys, 1770-1804",
    "assocrecFile" : "Additional Datasets; Old Bailey Associated Records",
    "canterburyFile" : "Additional Datasets; PCC Abstracts of Wills",
    "sldswhrFile" : "Additional Datasets; St Luke's Workhouse Registers",
    "smdssetFile" : "Additional Datasets; St Martin's Settlement Exams, 1725-93",
    "smdswhrFile" : "Additional Datasets; St Martins Workhouse Registers, 1725-1819",
    "ahdseicFile" : "Additional Datasets; Salaries Paid to East India Clerks, 1760-1820",
    "westminsterPollbook" : "Additional Datasets; Westminster Pollbooks, 1749-1820",
    "westminsterRatebook" : "Additional Datasets; Westminster Ratebooks, 1749-1820",

    "lookup_geotype|place" : "location",

    "geocode_folder" : "Place type",
    "LL_PL" : "Location",
    "LL_PA" : "Parish",
    "LL_WA" : "Ward",

    "rent_tax_gte" : "minimum rent tax",
    "rent_tax_lte" : "maximum rent tax",
    "per_tax_gte" : "minimum personal tax",
    "per_tax_lte" : "maximum personal tax",
    "tax_gte" : "minimum rateable value",
    "tax_lte" : "maximum rateable value",

    "cdeath" : "cause of death",

}

export default lookups;
