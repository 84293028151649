
import { useCallback } from "react";
import { useLocation, Link } from "react-router-dom";

import useForm from "../../../hooks/useForm";

//import usePretty from "../../../hooks/usePretty";
import usePaging from "../../../hooks/usePaging";

//import SearchButton from "./SearchButton";
import AddToMapButton from "./AddToMapButton";
import DisplayOptions from "./DisplayOptions";

import useResults from "../../../hooks/useResultsNew";

import Loading from "../../Loading";
import Error from "../../Error";
import Hit from "../Hit";

import queryString from "query-string";

import useHitTerm from "../../../hooks/useHitTerm";

import Post from "../../Post";
import options_syn from '../../../data/loclon/options_syn';

const FormLlguild = (props) => {

    document.title = "Hospital and Guild 1721-87 | Locating London's Past";

    const location = useLocation();
    let qs = queryString.parse(location.search, { arrayFormat: "bracket" });

    const [ values, handleChange, handleSubmit, handleReset ] =
    useForm(false, false);

    const pretty = props.pretty;
    //const [ pretty ] = usePretty();

    const [ hitTerm, hitsTerm, keyTerm, layerControlTerm ] = useHitTerm();

    const preprocess = useCallback( () => {

        let qs = queryString.parse(location.search, { arrayFormat: "bracket" });

        const path = `${process.env.REACT_APP_API_URL}/view/loclon_llguild`;

        qs['series'] = 'geocode_success';

        const stringified = queryString.stringify(qs, { arrayFormat: "bracket" });

        return `${ path }?${ stringified }`;

    }, [ location.search ]);
    
    const handleSubmitSearch = (event) => {

        //event.preventDefault();
        handleSubmit(event);
        props.addQueryLayer( 'llguild', values );

    }
    
    const showOnlyMappableHits = (event) => {

        console.log('showOnlyMappableHits');
        handleChange({target: { name: 'geocode_success', value: 'yes' } });
        handleSubmit(event);

    }

    const showAllHits = (event) => {

        console.log('showAllHits');
        handleChange({target: { name: 'geocode_success', value: '' } });
        handleSubmit(event);

    }

    const [ results, isLoading, error ] =
    useResults( null, false, preprocess );

    let hits;

    const pagesize = 10; // Paging requires that we know the page size 
    const [ page, handleChangePageField, handleSubmitPageForm, handleNextPageButton, handlePreviousPageButton, handleLastPageButton, handleFirstPageButton ] =
    usePaging(pagesize);

    if (results.hits && results.hits.hits) {

        hits = results.hits.hits.map((hit, idx) => (

            <Hit
            key={ hit._id }
            hit={ hit }
            idx={ ((parseInt(values['from']) || 0) + idx + 1) }
            last={ ((idx + 1) === Math.min((parseInt(values['from']) || 0) + pagesize, results.hits.total.value)) }
            addHitLayer={ props.addHitLayer }
            pretty={ pretty }
            /> 

        ));

    }

    return (

        <>

            <div>

                <div className="setinfo">
                    <Post url={ `${process.env.REACT_APP_WORDPRESS_URL}/posts?slug=synopsis-llguild` } options={ options_syn } />
                    {/* <span>Two different types of hospital record.  </span> */}
                    <Link target="_blank" to="/about/london-lives#toc7">More information ...</Link>
                </div>

                <form onSubmit={ handleSubmitSearch } onReset={ handleReset }>

                    <div className="row form-layerdisplay">
                        <div className="col-12 text-end">
                            <div className="input-group">
                                <select className='form-select' onChange={ handleChange } value={ values['layerdisplay'] || '' } name="layerdisplay" id="form_layerdisplay">
                                    <DisplayOptions searchmode={ 'llguild' } />
                                </select>
                                <AddToMapButton/>
                            </div>
                            <button data-bs-helpslug="help-addtomap" type="button" className="btn border-0 p-0 m-0 align-top help-link" data-bs-toggle="modal" data-bs-target="#helpModal" onClick={ props.handleChangeHelpslug }>What's this?</button>
                        </div>
                    </div>

                    <div className="row mb-1"><div className="col-12"><div className="input-group"><select className='form-select' onChange={ handleChange } value={ values['div1type'] || '' } name="div1type" id="form_div1type"><option value="">&lt;Any Document type&gt;</option><option value="BR_RApage">Bridewell Annual registers of apprentices placed by parish</option><option value="CC_MCpage">Carpenters' Company Court/Committee Minute Books</option></select><button type="button" className="btn btn-outline-input-group btn-light bg-white border-start-0 text-muted" data-bs-toggle="modal" data-bs-target="#helpModal" data-bs-helpslug="help-hospitalandguild-documenttypes" onClick={ props.handleChangeHelpslug }><i className="bi bi-question-circle" /></button></div></div></div>
                    <div className="row mb-1"><div className="col-12"><div className="input-group"><input className='form-control' placeholder={ 'Enter keywords ...' } value={ values['text'] || '' } onChange={ handleChange }  name="text" id="form_text" type="text"/><button type="button" className="btn btn-outline-input-group btn-light bg-white border-start-0 text-muted" data-bs-toggle="modal" data-bs-target="#helpModal" data-bs-helpslug="help-keyword" onClick={ props.handleChangeHelpslug }><i className="bi bi-question-circle" /></button></div></div></div>
                    <div className="row mb-1"><div className="col-12"><div className="input-group"><input className='form-control' placeholder={ 'Search place names ...' } value={ values['placename'] || '' } onChange={ handleChange }  name="placename" id="form_placename" type="text"/><button type="button" className="btn btn-outline-input-group btn-light bg-white border-start-0 text-muted" data-bs-toggle="modal" data-bs-target="#helpModal" data-bs-helpslug="help-londonlives-placenames" onClick={ props.handleChangeHelpslug }><i className="bi bi-question-circle" /></button></div></div></div>
                    <div className="row mb-1"><div className="col-12"><div className="input-group"><span className="input-group-text"><strong>Year</strong></span><select className='form-select' onChange={ handleChange } value={ values['year_gte'] || '' } name="year_gte" id="form_year_gte"><option value="">1721</option><option value="1731">1731</option><option value="1737">1737</option><option value="1749">1749</option><option value="1757">1757</option><option value="1784">1784</option><option value="1787">1787</option></select><span className="input-group-text">To</span><select className='form-select' onChange={ handleChange } value={ values['year_lte'] || '' } name="year_lte" id="form_year_lte"><option value="1721">1721</option><option value="1731">1731</option><option value="1737">1737</option><option value="1749">1749</option><option value="1757">1757</option><option value="1784">1784</option><option value="">1787</option></select><button type="button" className="btn btn-outline-input-group btn-light bg-white border-start-0 text-muted" data-bs-toggle="modal" data-bs-target="#helpModal" data-bs-helpslug="help-year" onClick={ props.handleChangeHelpslug }><i className="bi bi-question-circle" /></button></div></div></div>

                    <div className="row mb-1 d-none"><div className="col-12"><div className="input-group"><select className='form-select' onChange={ handleChange } value={ values['geocode_success'] || '' } name="geocode_success" id="form_geocode_success"><option value="">&lt; Show all hits &gt;</option><option value="yes">Show only mappable hits</option></select><button type="button" className="btn btn-outline-input-group btn-light bg-white border-start-0 text-muted" data-bs-toggle="modal" data-bs-target="#helpModal" data-bs-helpslug="geocode_success" onClick={ props.handleChangeHelpslug }><i className="bi bi-question-circle" /></button></div></div></div>

                </form>
 
                { results.hits && results.hits.total.value > 0 && isLoading === false && (

                    <div className="mb-2">

                        <span><strong className="fs-6 me-1">{ pretty(results.hits.total.value) }</strong> record{ results.hits.total.value !== 1 && ( <>s</> ) } matched this query. </span>

                        { values['geocode_success'] && values['geocode_success'] === 'yes' && ( 

                            <div>
                                <b>Showing only mappable { hitsTerm('llguild') }</b>
                                <button data-bs-helpslug="help-adddata-mappablehits" type="button" className="btn border-0 text-white ps-1 pe-1 pt-0 pb-0 align-top" data-bs-toggle="modal" data-bs-target="#helpModal" onClick={ props.handleChangeHelpslug }><i className="bi bi-question-circle" /></button>
                                <span><a className="fake-link" onClick={ showAllHits }>Show all { hitsTerm('llguild') }</a>. </span>
                            </div>

                        ) }

                        { results.aggregations && results.aggregations.geocode_success && results.aggregations.geocode_success.buckets.length === 2 && ( 

                            <span>
                                <b>{ pretty(results.aggregations.geocode_success.buckets[0].doc_count) }</b>
                                <span> ({ Math.round((results.aggregations.geocode_success.buckets[0].doc_count / results.hits.total.value) * 10000) / 100 }%) can be mapped. </span>
                            
                                { values['geocode_success'] !== 'yes' && ( 

                                    <>
                                    <a className="fake-link" onClick={ showOnlyMappableHits }>Show only mappable { hitsTerm('llguild') }</a>
                                    <button data-bs-helpslug="help-adddata-mappablehits" type="button" className="btn border-0 text-white ps-1 pe-1 pt-0 pb-0 align-top" data-bs-toggle="modal" data-bs-target="#helpModal" onClick={ props.handleChangeHelpslug }><i className="bi bi-question-circle" /></button>
                                    </>

                                ) }

                            </span>

                        ) }                         

                    </div>

                ) }

                { results && results.hits && results.hits.total.value > 0 && (

                    <>

                        <div className="mb-1">
                            Showing records <strong>{ (parseInt(values['from']) || 0) + 1 }</strong> to <strong>{ Math.min((parseInt(values['from']) || 0) + pagesize, results.hits.total.value) }</strong> of <strong>{ pretty(results.hits.total.value) }</strong>
                        </div>
                        
                        <div className="paging-wrapper border-bottom pb-1 mb-2">

                            <div className="paging row g-0">

                                <div className="col-3">
                                    <button type="button" disabled={ page < 2 } className="paging-first btn btn-link text-start" onClick={ event => handleFirstPageButton(event, results.hits.total.value) }><i className="bi bi-chevron-double-left"></i></button>
                                    <button type="button" disabled={ page < 2 } className="paging-previous btn btn-link text-start" onClick={ event => handlePreviousPageButton(event, results.hits.total.value) }><i className="bi bi-chevron-left"></i></button>
                                </div>

                                <div className="col-6 text-center">
                                    <form id="paging-form" onSubmit={ event => handleSubmitPageForm(event, results.hits.total.value) }>
                                        <span className="paging-text">Page:</span>
                                        <input type="text" className="form-control ms-2 me-2 paging-input" name="page" id="field_page" value={ page } onChange={ handleChangePageField } />
                                        <span className="paging-text me-1">of </span><span className="paging-text">{ pretty(Math.ceil(results.hits.total.value / pagesize)) }</span>
                                    </form>
                                </div>

                                <div className="col-3 text-end">
                                    <button type="button" disabled={ page >= Math.ceil(results.hits.total.value / pagesize) } className="paging-next btn btn-link text-start" onClick={ event => handleNextPageButton(event, results.hits.total.value) }><i className="bi bi-chevron-right"></i></button>
                                    <button type="button" disabled={ page >= Math.ceil(results.hits.total.value / pagesize) } className="paging-last btn btn-link text-start" onClick={ event => handleLastPageButton(event, results.hits.total.value) }><i className="bi bi-chevron-double-right"></i></button>
                                </div>

                            </div>

                        </div>

                    </>

                ) }                            
                 
                <div className="hits">

                    { isLoading && ( <div className="mt-5 mb-5 pt-5 pb-5"><Loading /></div> ) }

                    { error && ( <div className="mt-5 mb-5 pt-5 pb-5"><Error error={ error } /></div> ) }

                    { (!isLoading && !error) && (

                        <>
                            { hits }
                        </>

                    ) }

                    { results && results.hits && results.hits.total.value === 0 && (

                        <div>

                            <strong className="fs-6 ms-1 me-1">No records</strong> matched this query.

                        </div>

                    ) }
           
                </div>

            </div>

        </>

    );

};

export default FormLlguild;
