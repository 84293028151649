
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

//import Footer from "./Footer";

import MapGis from "./MapGis";
import About from "./About";
//import Record from "./Record";

//import Search from "./Search";

//import Offcanvas from "./Offcanvas";
import Navbar from "./Navbar";

import StaticRedirector from "./StaticRedirector";

const Base = () => {

    return (

        <>

            <Router basename={process.env.REACT_APP_BASENAME}>

                <Navbar/>
                
                

                {/* To keep the MapGis component perpetually mounted, we only hide it when /about/ is active */}

                <Switch>
                    <Route path="/about/:slug" component={ About } />
                    <Route path="/static/:page" component={ StaticRedirector } />
                    <Route path="/:searchmode" component={ MapGis } />
                    <Route path="/" component={ MapGis } />
                </Switch>

            </Router>

        </>

    );

};

export default Base;
