
//import { Link, useLocation } from "react-router-dom";

//import parse from 'html-react-parser';

//import queryString from "query-string";

const Hit = (props) => {
    
    const pretty = props.pretty;

    return (
        
        <div className={ 'col-12 mb-4' }>

            <span><b>{ props.idx }.</b> </span>

            { props.hit._source.placename && ( <span>"{ props.hit._source.placename }". </span> ) }

            { props.hit._source.geocode_geodescription && (

                <span>
                    <span>
                        <a className="fake-link" title={ props.hit._source.geocode_folder + ' ' + props.hit._source.geocode_idkey } onClick={ function() { props.addHitLayer( props.hit ); }  }>
                            { props.hit._source.geocode_geodescription }
                        </a> <span className={ "geotype geotype-" + props.hit._source.geocode_placetype }>{ pretty(props.hit._source.geocode_placetype, ['lookup_geotype']) }</span>
                    </span>
                    {/*
                    <br/><span><b>Folder:</b> { props.hit._source.geocode_folder }. </span>
                    <br/><span><b>Placename</b>: { props.hit._source.geocode_placename }. </span>
                    <br/><span><b>Parish</b>: { props.hit._source.geocode_parish }. </span>
                    <br/><span><b>Ward</b>: { props.hit._source.geocode_ward }. </span>
                    */}
                </span>

            ) }           

            <br/>

            {/*
            { props.hit._source.type && (

                <span>{ props.hit._source.type.map((type, n) => { 

                    return (

                        <span key={ n }>{ props.pretty(type) }{ n === props.hit._source.type.length - 1 ? '' : ', ' } </span>); } 

                    ) }

                </span>

            ) }
            */}

            { props.hit._source.div1_title && ( 

                <span> in trial of <a target="_blank" href={ "https://www.oldbaileyonline.org/record/" + props.hit._source.div1id }>{ props.hit._source.div1_title }</a>. </span>

            ) }

            { props.hit._source.div1type && ( 

                <span><a target="_blank" href={ "https://www.londonlives.org/browse.jsp?div=" + props.hit._source.div1id }>{ pretty(props.hit._source.div1type) }</a>. </span>

            ) }

            { props.hit._source.event && ( <span>{ pretty(props.hit._source.event) }. </span> ) }

            { props.hit._source.object && ( <span><b>Object:</b> { pretty(props.hit._source.object) }. </span> ) }
            { props.hit._source.decoration && props.hit._source.decoration.length > 2 && ( <span><b>Decoration:</b> { props.hit._source.decoration }. </span> ) }

            { props.hit._source.year && ( <span>{ props.hit._source.year }. </span> ) }

            { props.hit._source.name && ( <span>{ pretty(props.hit._source.name) }. </span> ) }
            { props.hit._source.householder && ( <span><b>Householder: </b> { pretty(props.hit._source.householder) }. </span> ) }
            { props.hit._source.forename && ( <span>{ props.hit._source.forename } </span> ) }
            { props.hit._source.surname && ( <span>{ props.hit._source.surname }. </span> ) }

            { props.hit._source.gender && ( <span><b>Gender: </b>{ props.hit._source.gender }. </span> ) }

            { props.hit._source.occupation && ( <span><b>Occupation: </b> { pretty(props.hit._source.occupation) }. </span> ) }
            { props.hit._source.occ_group && ( <span>{ pretty(props.hit._source.occ_group) } </span> ) }
            { props.hit._source.occ_code && ( <span>({ pretty(props.hit._source.occ_code) }). </span> ) }

            { props.hit._source.insval && ( <span><b>Insured Value:</b> { props.hit._source.insval }. </span> ) }
            { props.hit._source.rent_tax && ( <span><b>Rent Tax:</b> { props.hit._source.rent_tax }. </span> ) }
            { props.hit._source.per_tax && ( <span><b>Personal Tax:</b> { props.hit._source.per_tax }. </span> ) }
            { props.hit._source.tax && ( <span><b>Tax:</b> { props.hit._source.tax }. </span> ) }
            { props.hit._source.vote && ( <span><b>Vote:</b> { props.hit._source.vote }. </span> ) }
            { props.hit._source.hearths_total && ( <span><b>Total Hearths:</b> { props.hit._source.hearths_total }. </span> ) }
            { props.hit._source.hearths_paid && ( <span><b>Hearths Paid:</b> { props.hit._source.hearths_paid }. </span> ) }
            { props.hit._source.hearths_unpaid && ( <span><b>Hearths Unpaid:</b> { props.hit._source.hearths_unpaid }. </span> ) }
            { props.hit._source.week && ( <span><b>Week:</b> { props.hit._source.week }. </span> ) }
            { props.hit._source.deaths && ( <span><b>Deaths:</b> { props.hit._source.deaths }. </span> ) }

            { props.hit._source.comments && ( <span><b>Comments:</b> { props.hit._source.comments }. </span> ) }

            { props.hit._source.cdeath && ( <span><b>Cause of Death: </b> { pretty(props.hit._source.cdeath) }. </span> ) }

            { props.hit._source.ll_id && ( 

                <span><a target="_blank" href={ "https://www.londonlives.org/browse.jsp?div=" + props.hit._source.ll_id }>View full document</a>. </span>

            ) }


        </div>

    );

};

export default Hit;
