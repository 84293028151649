
import React from 'react'; // Needed to use the full Fragment syntax

import { Link } from "react-router-dom";

import { domToReact } from 'html-react-parser';

const options = {

    notitle: true,

    replace: (domNode) => {

        if (domNode.name === 'a' && domNode.attribs && domNode.attribs.href) {

            if (domNode.attribs.href.startsWith('https://www.dhi.ac.uk/blogs/locating-london/')) {

                return <Link to={ '/about/' + domNode.attribs.href.substring(44, domNode.attribs.href.length) }>{ domToReact(domNode.children, options) }</Link>

            }
        }       

        if (domNode.name === 'p') {

            //console.log(domNode);

            return <span>{ domToReact(domNode.children, options) }</span>;

        }
       
    }
};

export default options;
