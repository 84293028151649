
import { useParams, useLocation, Redirect } from "react-router-dom";

const SearchRedirector = () => {
    
    const { page } = useParams();

    const location = useLocation();

    const pagename = page.slice(0, -5);

    const kebabize = (str) => str.replace(/[A-Z]+(?![a-z])|[A-Z]/g, ($, ofs) => (ofs ? "-" : "") + $.toLowerCase())
    
    const slug = kebabize(pagename);

    return (     

        <>
            {/*<div className="mt-5 pt-5">Static Redirector { '/about/' + slug + location.hash }</div>*/}
            <Redirect to={ '/about/' + slug + location.hash } />
        </>
    );

};

export default SearchRedirector;
