
const AddToMapButton = () => {
    
    return (

        <>

            <button type="submit" className="btn btn-outline-input-group btn-light bg-primary text-white search-feature-inline-submit">
                <i className="bi bi-plus" /> Add to map
            </button>

        </>

    );

};

export default AddToMapButton;
