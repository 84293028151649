
import OffcanvasGis from "./OffcanvasGis";
import OffcanvasWelcome from "./OffcanvasWelcome";
import Map from "./Map";

import { useState, useEffect } from "react";

import { useParams, useLocation, Link } from "react-router-dom";

import axios from "axios";
import queryString from "query-string";

import parishes from '../../data/loclon/parishes';
import population from '../../data/loclon/population';
import wards from '../../data/loclon/wards';

import usePretty from "../../hooks/usePretty";
import lookups from '../../data/loclon/lookups';

import Post from "../Post";
import options_help from '../../data/loclon/options_help';

import useHitTerm from "../../hooks/useHitTerm";

import initLayerData from "../../data/loclon/initLayerData";

import parse from 'html-react-parser';

const MapGis = () => {

    document.title = "Locating London's Past";

    const [ pretty ] = usePretty(lookups);

    const [ , , keyTerm, ] = useHitTerm();

    //const parish_set = new Set(parishes.map((parish, idx) => ( parish.parish )));
    const ward_set = new Set(wards.map((ward, idx) => ( ward.ward )));

    const parish_object = {}
    parishes.forEach((parish, idx) => { parish_object[parish.parish] = parish; });

    const population_object = {}
    population.forEach((population, idx) => { population_object[population.placename] = population; });

    const ward_object = {}
    wards.forEach((ward, idx) => { ward_object[ward.ward] = ward; });

    const [ layerData, setLayerData ] = useState(initLayerData);
    const [ activeLayer, setActiveLayer ] = useState(1);
    const [ layerIdCounter, setLayerIdCounter ] = useState(7);
    const [ position, setPosition ] = useState([ 51.51511, -0.08977 ]); // Default coordinates, you can change them
    const [ zoom, setZoom ] = useState(15); // Default zoom level, you can change it
    const [ mapUpdateView, setMapUpdateView ] = useState(false); // Set this to true when the map needs to have its view automatically updated.
    const [ defaultColor, setDefaultColor ] = useState('greenopacity');

    const { searchmode = '' } = useParams();
    const location = useLocation();

    useEffect(() => {

        //console.log('MapGis useEffect Ran');

        //console.log(searchmode);
        //console.log(location);

        if (location.pathname !== '/' || location.search !== '') {

            showOffcanvasGis(true);

        } else {

            showOffcanvasWelcome(true);

        }

        // Populate the demolayer
        const demoidx = findDemoLayer();

        if (demoidx !== -1) {

            //populateLayer(demoidx);
            setTimeout(() => {
                populateLayer(demoidx);
            }, "300");

        }
        
    }, []); // <-- empty dependency array. Causes this useEffect to run once when the app first loads, and not again.

    useEffect(() => {

        //console.log('MapGis mapUpdateView useEffect Ran');

        if (mapUpdateView) {

            //console.log('mapUpdateView was true');
            //console.log('setting it to false');
            setMapUpdateView(false);

        } else {

            //console.log('mapUpdateView was false');
            
        }
        
    }, [ mapUpdateView ]);

    const resetLayers = () => {

        setActiveLayer(1);
        layerData.splice(6);
        setLayerData( [ ...layerData ]);

    };

    const findDemoLayer = () => {

        let idx = 0;

        while (idx < layerData.length) {

            const layer = layerData[idx];

            if (layer.demolayer) {

                return idx;

            }

            idx++;

        }

        return -1;

    }

    const editDemoLayer = () => {

        // The demolayer is now no longer a demolayer

        const demoidx = findDemoLayer();

        if (demoidx !== -1) {

            delete layerData[demoidx].demolayer;
            delete layerData[demoidx].legend;
            delete layerData[demoidx].specialDataUrl;
            setActiveLayer(demoidx);

        }

    };

    const dismissDemoLayer = () => {

        const demoidx = findDemoLayer();

        if (demoidx !== -1) {
            
            layerData.splice(demoidx, 1);
            setActiveLayer(1);
            setLayerData( [ ...layerData ]);

        }

    };

    const [ helpslug, setHelpslug ] = useState();

    const handleChangeHelpslug = (event) => {

        //console.log('handleChangeHelpslug');

        var button = event.currentTarget;
        var helpslug = button.getAttribute('data-bs-helpslug');
        if (helpslug) {
            setHelpslug(helpslug);
        }
    }

    const OSToGeoJSONPoint = (os) => {

        const latlng = os.split(' ');
        const x = parseFloat(latlng[0]);
        const y = parseFloat(latlng[1]);

        var osgb = new window.GT_OSGB();
	    osgb.setGridCoordinates(x, y);
	    var wgs84 = osgb.getWGS84();
        return [wgs84.latitude, wgs84.longitude];

    };

    const OSToGeoJSON = (os) => {

        let geoJSON = [];

        os.split(' ').forEach( (point) => {

            const latlng = point.split(',');

            //console.log(latlng);

            if (latlng[1]) {

                //console.log(parseFloat(latlng[1]));
                geoJSON.push( [ parseFloat(latlng[1]), parseFloat(latlng[0]) ] );

            }

        } );

        return geoJSON;

    };

    const OSToLeafletPolygons = (os, pathOptions, popup, intensity, title, disregard) => {

        let polygons = [];
        let positions = [];

        os.split('P').forEach( (polygon_coords) => {

            if (polygon_coords.length > 0) {

                const geoJSON = OSToGeoJSON(polygon_coords);

                if (geoJSON.length > 2) {

                    positions.push(geoJSON);

                }

            }

        } );

        if (positions.length > 0) {

            polygons.push( {
                "positions": positions,
                "pathOptions": pathOptions,
                "popup": popup,
                "intensity": intensity,
                "title": title,
                "disregard": disregard,
            } );

        }

        return polygons;

    };

    const latLonBox = (lat, lon, radius) => {

        let geoJSON = [];

        geoJSON.push( [ lat         , lon          ] );
        geoJSON.push( [ lat         , lon + radius ] );
        geoJSON.push( [ lat + radius, lon + radius ] );
        geoJSON.push( [ lat + radius, lon          ] );

        //console.log(geoJSON);

        return geoJSON;

    };

    // https://mokole.com/palette.html

    const keycolors = [
        '#000080',
        '#ff0000',
        '#ffa500',
        '#ffff00',
        '#00ff00',
        '#ff00ff',
        '#f0e68c',
        '#6495ed',
        '#ff1493',
        '#ffc0cb',
        '#a52a2a',
        '#006400',
        '#00ffff',
        '#ff1493',
        '#ffc0cb',
        '#00fa9a',
        '#2f4f4f',
        '#1f77b4',
        '#aec7e8',
        '#ff7f0e',
        '#ffbb78',
        '#2ca02c',
        '#98df8a',
        '#d62728',
        '#ff9896',
        '#9467bd',
        '#c5b0d5',
        '#8c564b',
        '#c49c94',
        '#e377c2',
        '#f7b6d2',
        '#7f7f7f',
        '#c7c7c7',
        '#bcbd22',
        '#dbdb8d',
        '#17becf',
        '#9edae5',
    ];

    const keymarkers = [
        'red',
        'green',
        'blue',
        'orange',
        'cyan',
        'magenta'
    ];

    const getcolor = (value, colorscheme, idx, polymarker) => {

        if (isNaN(value) || value === 0.0) { return 'rgba(0, 0, 0, 0%)'; }

        var h = Math.ceil(((1.0 - value) * 80) + 10);

        if (colorscheme === 'red') {

            return 'rgb(100%, ' + h + '%, ' + h + '%)';

        } else if (colorscheme === 'green') {

            return 'rgb(' + h + '%, 100%, ' + h + '%)';

        } else if (colorscheme === 'blue') {

            return 'rgb(' + h + '%, ' + h + '%, 100%)';

        } else if (colorscheme === 'orange') {

            return 'rgb(100%, 100%, ' + h + '%)';

        } else if (colorscheme === 'cyan') {

            return 'rgb(' + h + '%, 100%, 100%)';

        } else if (colorscheme === 'magenta') {

            return 'rgb(100%, ' + h + '%, 100%)';

        } else if (colorscheme === 'redopacity') {

            h = Math.ceil(value * 100);
            return 'rgba(100%, 0%, 0%, ' + h + '%)';

        } else if (colorscheme === 'greenopacity') {

            h = Math.ceil(value * 100);
            return 'rgba(0%, 70%, 0%, ' + h + '%)';

        } else if (colorscheme === 'blueopacity') {

            h = Math.ceil(value * 100);
            return 'rgba(0%, 0%, 100%, ' + h + '%)';

        } else if (colorscheme === 'orangeopacity') {

            h = Math.ceil(value * 100);
            return 'rgba(100%, 50%, 0%, ' + h + '%)';

        } else if (colorscheme === 'cyanopacity') {

            h = Math.ceil(value * 100);
            return 'rgba(0%, 60%, 60%, ' + h + '%)';

        } else if (colorscheme === 'magentaopacity') {

            h = Math.ceil(value * 100);
            return 'rgba(80%, 0%, 80%, ' + h + '%)';

        } else if (colorscheme === 'key') {

            if (polymarker === 'markers') {

                return keymarkers[idx % keymarkers.length];

            } else {

                return keycolors[idx % keycolors.length];
                //return 'hsl(' + idx + ', 100%, 50%)';
                
            }

        } else {

            // heatmap
            h = Math.ceil((1.0 - value) * 240);
            return 'hsl(' + h + ', 100%, 50%)';

        }

    }

    const getmarkerfile = (value, colorscheme, idx) => {

        if (isNaN(value) || value === 0.0) { return 'marker-original'; }

        if (colorscheme === 'red') {

            return 'marker-red';

        } else if (colorscheme === 'green') {

            return 'marker-green';

        } else if (colorscheme === 'blue') {

            return 'marker-blue';

        } else if (colorscheme === 'orange') {

            return 'marker-orange';

        } else if (colorscheme === 'cyan') {

            return 'marker-cyan';

        } else if (colorscheme === 'magenta') {

            return 'marker-magenta';

        } else if (colorscheme === 'redopacity') {

            return 'marker-red';

        } else if (colorscheme === 'greenopacity') {

            return 'marker-green';

        } else if (colorscheme === 'blueopacity') {

            return 'marker-blue';

        } else if (colorscheme === 'orangeopacity') {

            return 'marker-orange';

        } else if (colorscheme === 'cyanopacity') {

            return 'marker-cyan';

        } else if (colorscheme === 'magentaopacity') {

            return 'marker-magenta';

        } else if (colorscheme === 'key') {

            return 'marker-' + keymarkers[idx % keymarkers.length];

        } else {

            // heatmap
            // red .. orange .. green .. cyan .. blue
            
            if (value > 0.9) {

                return 'marker-red';

            } else if (value > 0.6) {

                return 'marker-orange';

            } else if (value > 0.4) {

                return 'marker-green';

            } else if (value > 0.1) {

                return 'marker-cyan';

            } else {

                return 'marker-blue';
            }

        }

    }

    const advanceDefaultColor = () => {

        if (defaultColor === 'redopacity') { setDefaultColor('greenopacity'); }
        if (defaultColor === 'greenopacity') { setDefaultColor('blueopacity'); }
        if (defaultColor === 'blueopacity') { setDefaultColor('orangeopacity'); }
        if (defaultColor === 'orangeopacity') { setDefaultColor('cyanopacity'); }
        if (defaultColor === 'cyanopacity') { setDefaultColor('magentaopacity'); }
        if (defaultColor === 'magentaopacity') { setDefaultColor('redopacity'); }

    }

    const fakePpopESData = (existingLayerData) => {

        const fakedata = {};

        //console.log('fakePpopESData');

        //console.log(existingLayerData);

        fakedata.aggregations = {};

        fakedata.aggregations.geocode_parishes = {};

        fakedata.aggregations.geocode_parishes.buckets = [];

        population.forEach((obj, idx) => {

            const fakebucket = {};

            //console.log(obj);

            fakebucket.key = obj.placename;

            if (existingLayerData.qs.ppopdata === 'pop1690s') { fakebucket.doc_count = parseInt(obj.pop1690s); }
            if (existingLayerData.qs.ppopdata === 'den1690s') { fakebucket.doc_count = parseInt(obj.den1690s); }
            if (existingLayerData.qs.ppopdata === 'pop1695') { fakebucket.doc_count = parseInt(obj.pop1695); }
            if (existingLayerData.qs.ppopdata === 'den1695') { fakebucket.doc_count = parseInt(obj.den1695); }
            if (existingLayerData.qs.ppopdata === 'pop1740s') { fakebucket.doc_count = parseInt(obj.pop1740s); }
            if (existingLayerData.qs.ppopdata === 'den1740s') { fakebucket.doc_count = parseInt(obj.den1740s); }
            if (existingLayerData.qs.ppopdata === 'pop1801') { fakebucket.doc_count = parseInt(obj.pop1801); }
            if (existingLayerData.qs.ppopdata === 'den1801') { fakebucket.doc_count = parseInt(obj.den1801); }
            if (existingLayerData.qs.ppopdata === 'area') { fakebucket.doc_count = parseInt(obj.pop1690s); }

            fakedata.aggregations.geocode_parishes.buckets.push(fakebucket);

        });

        //console.log(fakedata);

        return fakedata;

    };

    const polysExtent = (polygons) => {

        //console.log('polysExtent');

        let latmin = 99999999;
        let latmax = -99999999;
        let lngmin = 99999999;
        let lngmax = -99999999;

        polygons.forEach((polygon, idx) => {

            //console.log('polygon:')
            //console.log(polygon);

            polygon.positions[0].forEach((position, idx) => {

                latmin = Math.min(latmin, position[0])
                latmax = Math.max(latmax, position[0])
                lngmin = Math.min(lngmin, position[1])
                lngmax = Math.max(lngmax, position[1])

                //console.log(latmin);
                //console.log(position[0]);

            });

        });

        return [latmin, latmax, lngmin, lngmax];

    };

    const polysCentre = (polygons) => {

        //console.log('polysCentre');
        //console.log('polygons:');
        //console.log(polygons);

        // [latmin, latmax, lngmin, lngmax]
        const extent = polysExtent(polygons);

        //console.log('extent:');
        //console.log(extent);

        const latmin = extent[0];
        const latmax = extent[1];
        const lngmin = extent[2];
        const lngmax = extent[3];      

        return [((latmax - latmin) / 2) + latmin, ((lngmax - lngmin) / 2) + lngmin];

    };

    const maxOpacity = (colstring) => {

        //console.log(colstring);
        colstring = colstring.replace(/(rgba\([0-9]*%, [0-9]*%, [0-9]*%, )[0-9]*%\)/, "$1100%)");
        //colstring = colstring.replace(/r/, "bbaaccoonn");
        //console.log(colstring);

        return colstring;
    }

    const generateTargetSet = (polygons, count, total) => {

        const targets = [];

        targets.push(polysCentre(polygons));

        // [latmin, latmax, lngmin, lngmax]
        const extent = polysExtent(polygons);
        const latmin = extent[0];
        const latmax = extent[1];
        const lngmin = extent[2];
        const lngmax = extent[3];


        //targets.push([latmin, lngmin]);
        //targets.push([latmax, lngmax]);

        const latrange = latmax - latmin;
        const lngrange = lngmax - lngmin;

        // We scale the count so that we never display more than 5,000 circles in total.
        const limit = 5000 / total;
        if (limit < 1) { count = Math.ceil(count * limit); }

        // We already have one added at the centre, so we start at 1
        for (let i = 1; i < count; i++) {

            targets.push([latmin + (latrange * Math.random()), lngmin + (lngrange * Math.random())]);
          
        }
        
        return targets;

    };

    const showOffcanvasGis = ( show ) => {

        const oe = document.querySelector("#offcanvasGis");
        const classname = oe.className;    

        const showing = classname.includes('show');

        if ((show && !showing) || (!show && showing)) {

            const nt = document.querySelector(".offcanvas-gis-button");

            if (nt) {
                nt.click(); // user click to hide, .hide() reloads the page in Safari!
            }

        }

    }

    const showOffcanvasWelcome = ( show ) => {

        //console.log('MapGis showOffcanvasWelcome ran');

        const oe = document.querySelector("#offcanvasWelcome");
        const classname = oe.className;    

        const showing = classname.includes('show');

        if ((show && !showing) || (!show && showing)) {

            const nt = document.querySelector(".offcanvas-welcome-button");

            if (nt) {
                nt.click(); // user click to hide, .hide() reloads the page in Safari!
            }

        }

    }

    const setMapPosition = ( latlng ) => {

        //console.log('setMapPosition');
        //console.log( latlng );

        if (Array.isArray(latlng) && latlng.length === 2 && typeof latlng[0] === 'number' && typeof latlng[1] === 'number' && !isNaN(latlng[0]) && !isNaN(latlng[1])) {

            setPosition(latlng);
            setMapUpdateView(true);

        } else {

            console.log('Invalid argument to setMapPosition:');
            console.log(latlng);

        }

    }

    const setMapPositionAndZoom = ( latlng, zoom ) => {

        //console.log('setMapPositionZoom');
        //console.log( latlng );
        //console.log( zoom );

        if (Array.isArray(latlng) && latlng.length === 2 && typeof latlng[0] === 'number' && typeof latlng[1] === 'number' && !isNaN(latlng[0]) && !isNaN(latlng[1]) && typeof zoom === 'number' && Number.isInteger(zoom) && zoom >= 13 && zoom <= 18) {

            setPosition(latlng);
            setZoom(zoom);
            setMapUpdateView(true);

        } else {

            console.log('Invalid argument to setMapPositionZoom:');
            console.log(latlng);
            console.log(zoom);

        }

    }

    const deleteLayer = ( idx ) => {

        //console.log('deleteLayer');     

        //const newLayerData = layerData;
        //newLayerData.splice(idx, 1);

        //console.log('layerData:');
        //console.log(layerData.length);

        const newLayerData = layerData.filter(function(layer, layeridx) {

            return layeridx !== idx;

        });

        //console.log('newLayerData:');
        //console.log(newLayerData.length);

        setLayerData( newLayerData ); // [...foo] is the js array spread syntax. It is needed here because react needs to see a new object in order to update the state

    };

    const getNewLayerId = () => {

        const newLayerId = layerIdCounter;
        setLayerIdCounter(newLayerId + 1);
        return newLayerId;

    }

    /*
    const findLayerIdx = ( layerid ) => {      

        return layerData.findIndex(function(element, index, array){ return element.id === layerid });

    }
    */

    const dumpLayers = () => {

        console.log(layerData);

    };

    const disregardPlace = ( layeridx, disregardBucketKey ) => {

        // Close the leaflet popup
        const lpcbs = document.getElementsByClassName('leaflet-popup-close-button');
        if (lpcbs.length === 1) {
            lpcbs[0].click(); // user click to hide
        }

        //console.log('disregardPlace');
        //console.log(layeridx);
        //console.log(disregardBucketKey);

        //console.log('layerData:');
        //console.log(layerData.length);

        let newDisregard = [];
        const existingLayerData = layerData[layeridx];
        if (existingLayerData.disregard) { newDisregard = existingLayerData.disregard; }
        newDisregard.push(disregardBucketKey);

        const newLayerData = layerData.map( (layer, idx) => {

            if (idx === layeridx) {

                const updatedLayer = layer;
                updatedLayer.disregard = newDisregard;
                return updatedLayer;

            } else {

                return layer;
            }

        });

        setLayerData( newLayerData, processLayerData(layeridx, existingLayerData, layerData[layeridx].results) ); // [...foo] is the js array spread syntax. It is needed here because react needs to see a new object in order to update the state

    };

    /*
    const disregardPlace = ( layd, bucket ) => {

        // Close the leaflet popup
        const lpcbs = document.getElementsByClassName('leaflet-popup-close-button');
        if (lpcbs.length == 1) {
            lpcbs[0].click(); // user click to hide
        }

        //console.log('layerData:');
        //console.log(layerData.length);
        
        const layeridx = findLayerIdx(layd.id);

        let newDisregard = [];
        const existingLayerData = layerData[layeridx];
        if (existingLayerData.disregard) { newDisregard = existingLayerData.disregard; }
        newDisregard.push(bucket.key);

        const newLayerData = layerData.map( (layer, idx) => {

            if (idx === layeridx) {

                const updatedLayer = layer;
                updatedLayer.disregard = newDisregard;
                return updatedLayer;

            } else {

                return layer;
            }

        });

        //console.log('newLayerData:');
        //console.log(newLayerData.length);

        setLayerData( newLayerData, processLayerData(layeridx, existingLayerData, layerData[layeridx].results) ); // [...foo] is the js array spread syntax. It is needed here because react needs to see a new object in order to update the state

        //existingLayerData.disregard = disregard;
        //layerData[idx].disregard = disregard;

        //setLayerData( [ ...layerData ], processLayerData(idx, existingLayerData, layerData[idx].results) ); // [...foo] is the js array spread syntax. It is needed here because react needs to see a new object in order to update the state

    };
    */

    const processLayerData = (idx, existingLayerData, data) => {

        layerData[idx].results = data;

        //console.log('processLayerData');

        //console.log(data);

        //console.log(existingLayerData.display);
        //console.log(existingLayerData.colorscheme);

        if (existingLayerData.type === 'hit') {

            let polygons = [];
            let circles = [];
            let markers = [];
            let keys = [];

            const geodescription = existingLayerData.hit._source.geocode_geodescription;
            const geotype = existingLayerData.hit._source.geocode_placetype;
            const coords = existingLayerData.hit._source.geocode_geocoordinates;

            const intensity = 1.0;
            //const percent = 100;

            //const pathOptions = { color: heatcol(intensity), fillOpacity: 0.9, stroke: true, weight: 2 };
            const pathOptions = { color: getcolor(intensity, existingLayerData.colorscheme, idx, existingLayerData.polymarker), stroke: (existingLayerData.stroke === 'stroke' ? true : false), weight: 1 };
            const popup = (

                <div>
                    <div>
                        {/* KEY-POPUP-A */}
                        <span className="agg-legend-square me-2" style={ { 'background': getcolor(intensity, existingLayerData.colorscheme, idx, existingLayerData.polymarker) }  } ></span>
                        <b>{ geodescription }</b> <span className={ "geotype geotype-" + geotype }>{ pretty(geotype, ['lookup_geotype']) }</span>
                    </div>
                </div>

            );

            const title = geodescription;

            if (coords.includes('P')) { 

                let these_polygons = [];
                these_polygons.push( ...OSToLeafletPolygons(coords, pathOptions, popup, intensity, title, null) );

                if (these_polygons.length > 0) {

                    const target = polysCentre(these_polygons);

                    if (existingLayerData.polymarker === 'polygons') {

                        polygons.push( ...these_polygons );

                    } else if (existingLayerData.polymarker === 'circles') {

                        circles.push({ 'position': target, 'pathOptions': pathOptions, 'popup': popup, 'intensity': intensity, 'title': title });

                    } else if (existingLayerData.polymarker === 'markers') {

                        markers.push({ 'position': target, 'pathOptions': pathOptions, 'popup': popup, 'intensity': intensity, 'markerfile': getmarkerfile(intensity, existingLayerData.colorscheme, idx), 'title': title, 'disregard': null });

                    } else if (existingLayerData.polymarker === 'multicircles') {

                        pathOptions.color = maxOpacity(pathOptions.color); // No opacity for multicircles
                        const tset = generateTargetSet(these_polygons, 1, 1);

                        tset.forEach((targ, idx) => {

                            circles.push({ 'position': targ, 'pathOptions': pathOptions, 'popup': popup, 'intensity': intensity, 'title': title });

                        });

                    }

                }

            } else {

                const position = OSToGeoJSONPoint(coords);

                //circles.push({ 'position': position, 'pathOptions': pathOptions, 'popup': popup, 'intensity': intensity });
                markers.push({ 'position': position, 'pathOptions': pathOptions, 'popup': popup, 'intensity': intensity, 'markerfile': getmarkerfile(intensity, existingLayerData.colorscheme, idx), 'title': title, 'disregard': null });

                keys.push( (
                    <div key={ existingLayerData.hit._source.geocode_idkey }>
                        {/* KEY-POPUP-B */}
                        <span className="agg-legend-square me-2" style={ { backgroundColor: getcolor(intensity, existingLayerData.colorscheme, idx, existingLayerData.polymarker) } }></span>
                        <button className="btn btn-link me-2" type="button" onClick={ function() { setMapPosition( position ); }  }>
                            <i className="bi bi-crosshair" />
                        </button>
                        { geodescription }
                    </div>
                ) );

            }      

            //console.log(circles);
            //console.log(markers);

            //if (polygons.length > 0) { layerData[idx].polygons = polygons; }
            //if (circles.length > 0) { layerData[idx].circles = circles; }
            //if (keys.length > 0) { layerData[idx].key = keys; }
            
            layerData[idx].polygons = polygons;
            layerData[idx].circles = circles;
            layerData[idx].markers = markers;
            layerData[idx].key = null;

        } else if (existingLayerData.type === 'query') {

            if (

                existingLayerData.display === 'parish' ||
                existingLayerData.display === 'parish-area' ||
                existingLayerData.display === 'parish-pop1690s' ||
                existingLayerData.display === 'parish-pop1695' ||
                existingLayerData.display === 'parish-pop1740s' ||
                existingLayerData.display === 'parish-pop1801' ||
                existingLayerData.display === 'parish-insval-avg' ||
                existingLayerData.display === 'parish-rent-tax-avg' ||
                existingLayerData.display === 'parish-per-tax-avg' ||
                existingLayerData.display === 'parish-tax-avg' ||
                existingLayerData.display === 'parish-hearths-total-avg' ||
                existingLayerData.display === 'parish-deaths-sum' ||
                existingLayerData.display === 'parish-deaths-sum-area' ||
                existingLayerData.display === 'parish-deaths-sum-pop1690s' ||
                existingLayerData.display === 'parish-deaths-sum-pop1695' ||
                existingLayerData.display === 'parish-deaths-sum-pop1740s' ||
                existingLayerData.display === 'parish-deaths-sum-pop1801'

            ) {

                // We can implement moderation by area / population etc just by always recalculating 'thisval'

                // Let's get the total. Annoyingly we have to calculate it because not every hit has a place and not every parish can be mapped.
                // We also need to get the max like this because when we are doing moderations by area / population etc it might not be the first value.
                let total = 0;
                let max = 0;
               
                let buckets = null;

                if (existingLayerData.display === 'parish-insval-avg') { buckets = data.aggregations.geocode_parishes_insval_avg.buckets; }
                else if (existingLayerData.display === 'parish-rent-tax-avg') { buckets = data.aggregations.geocode_parishes_rent_tax_avg.buckets; }
                else if (existingLayerData.display === 'parish-per-tax-avg') { buckets = data.aggregations.geocode_parishes_per_tax_avg.buckets; }
                else if (existingLayerData.display === 'parish-tax-avg') { buckets = data.aggregations.geocode_parishes_tax_avg.buckets; }
                else if (existingLayerData.display === 'parish-hearths-total-avg') { buckets = data.aggregations.geocode_parishes_hearths_total_avg.buckets; }
                else if (existingLayerData.display === 'parish-deaths-sum') { buckets = data.aggregations.geocode_parishes_deaths_sum.buckets; }
                else if (existingLayerData.display === 'parish-deaths-sum-area') { buckets = data.aggregations.geocode_parishes_deaths_sum.buckets; }
                else if (existingLayerData.display === 'parish-deaths-sum-pop1690s') { buckets = data.aggregations.geocode_parishes_deaths_sum.buckets; }
                else if (existingLayerData.display === 'parish-deaths-sum-pop1695') { buckets = data.aggregations.geocode_parishes_deaths_sum.buckets; }
                else if (existingLayerData.display === 'parish-deaths-sum-pop1740s') { buckets = data.aggregations.geocode_parishes_deaths_sum.buckets; }
                else if (existingLayerData.display === 'parish-deaths-sum-pop1801') { buckets = data.aggregations.geocode_parishes_deaths_sum.buckets; }

                else { buckets = data.aggregations.geocode_parishes.buckets; }

                if (existingLayerData.disregard) { buckets = buckets.filter((bucket) => !existingLayerData.disregard.includes(bucket.key)); }

                //console.log(population_object);

                buckets.forEach((bucket, idx) => {

                    bucket.thisval = 0; // Every bucket needs thisval to have a value, or the sort won't work.

                    bucket.parish = parish_object[bucket.key];

                    if (bucket.parish) { // We only want to count parishes which we can map

                        if (existingLayerData.display === 'parish') { bucket.thisval = bucket.doc_count; }
                        //if (existingLayerData.display === 'parish') { bucket.thisval = bucket.parishes_reciprocal_sum.value; }
                        
                        else if (existingLayerData.display === 'parish-insval-avg') { bucket.thisval = bucket.insval_avg.value; }
                        else if (existingLayerData.display === 'parish-rent-tax-avg') { bucket.thisval = bucket.rent_tax_avg.value; }
                        else if (existingLayerData.display === 'parish-per-tax-avg') { bucket.thisval = bucket.per_tax_avg.value; }
                        else if (existingLayerData.display === 'parish-tax-avg') { bucket.thisval = bucket.tax_avg.value; }
                        else if (existingLayerData.display === 'parish-hearths-total-avg') { bucket.thisval = bucket.hearths_total_avg.value; }
                        else if (existingLayerData.display === 'parish-deaths-sum') { bucket.thisval = bucket.deaths_sum.value; }

                        else if (

                            existingLayerData.display === 'parish-area' ||
                            existingLayerData.display === 'parish-pop1690s' ||
                            existingLayerData.display === 'parish-pop1695' ||
                            existingLayerData.display === 'parish-pop1740s' ||
                            existingLayerData.display === 'parish-pop1801' ||
                            existingLayerData.display === 'parish-deaths-sum-area' ||
                            existingLayerData.display === 'parish-deaths-sum-pop1690s' ||
                            existingLayerData.display === 'parish-deaths-sum-pop1695' ||
                            existingLayerData.display === 'parish-deaths-sum-pop1740s' ||
                            existingLayerData.display === 'parish-deaths-sum-pop1801'

                        ) {

                            //console.log(bucket.key);

                            bucket.pop = population_object[bucket.key];
                            
                            if (bucket.pop) {

                                if (existingLayerData.display === 'parish-area' && bucket.pop.area && bucket.pop.area != null) { bucket.thisval = bucket.doc_count / bucket.pop.area; }
                                if (existingLayerData.display === 'parish-pop1690s' && bucket.pop.pop1690s && bucket.pop.pop1690s != null) { bucket.thisval = bucket.doc_count / bucket.pop.pop1690s; }
                                if (existingLayerData.display === 'parish-pop1695' && bucket.pop.pop1695 && bucket.pop.pop1695 != null) { bucket.thisval = bucket.doc_count / bucket.pop.pop1695; }
                                if (existingLayerData.display === 'parish-pop1740s' && bucket.pop.pop1740s && bucket.pop.pop1740s != null) { bucket.thisval = bucket.doc_count / bucket.pop.pop1740s; }
                                if (existingLayerData.display === 'parish-pop1801' && bucket.pop.pop1801 && bucket.pop.pop1801 != null) { bucket.thisval = bucket.doc_count / bucket.pop.pop1801; }

                                if (existingLayerData.display === 'parish-deaths-sum-area' && bucket.pop.area && bucket.pop.area != null) { bucket.thisval = bucket.deaths_sum.value / bucket.pop.area; }
                                if (existingLayerData.display === 'parish-deaths-sum-pop1690s' && bucket.pop.pop1690s && bucket.pop.pop1690s != null) { bucket.thisval = bucket.deaths_sum.value / bucket.pop.pop1690s; }
                                if (existingLayerData.display === 'parish-deaths-sum-pop1695' && bucket.pop.pop1695 && bucket.pop.pop1695 != null) { bucket.thisval = bucket.deaths_sum.value / bucket.pop.pop1695; }
                                if (existingLayerData.display === 'parish-deaths-sum-pop1740s' && bucket.pop.pop1740s && bucket.pop.pop1740s != null) { bucket.thisval = bucket.deaths_sum.value / bucket.pop.pop1740s; }
                                if (existingLayerData.display === 'parish-deaths-sum-pop1801' && bucket.pop.pop1801 && bucket.pop.pop1801 != null) { bucket.thisval = bucket.deaths_sum.value / bucket.pop.pop1801; }

                            }

                        }

                        if (bucket.thisval) {

                            total = total + bucket.thisval;
                            if (bucket.thisval > max) { max = bucket.thisval; }

                        }

                    } else {

                        console.log('bucket.key IS NOT in parish_set: ***' + bucket.key + '***');

                    }

                });

                // We are also going to have to reorder the buckets because when we are doing moderations by area / population etc the order might change.
                const sorted_buckets = buckets.sort((a, b) => b.thisval - a.thisval);

                //console.log(sorted_buckets);

                let polygons = [];
                let circles = [];
                let markers = [];
                let keys = [];

                //console.log(parish_object);

                sorted_buckets.forEach((bucket, idx) => {

                    if (bucket.thisval > 0) { // We only want to count parishes which we can map
                    //if (parish_set.has(bucket.key)) { // We only want to count parishes which we can map

                        //const parish = parish_object[bucket.key];
                        const parish = bucket.parish;
                        //const pop = bucket.pop;

                        const intensity = bucket.thisval / max;
                        const percent = Math.round((bucket.thisval / total) * 10000) / 100;

                        const pathOptions = { color: getcolor(intensity, existingLayerData.colorscheme, idx, existingLayerData.polymarker), stroke: (existingLayerData.stroke === 'stroke' ? true : false), weight: 1 };
                        const popup = (

                            <div> 
                                {/* KEY-POPUP-C */}
                                <div>
                                <span className="agg-legend-square me-2" style={ { 'background': getcolor(intensity, existingLayerData.colorscheme, idx, existingLayerData.polymarker) }  } ></span>
                                <span><b>{ parish.parish }</b> <span className="geotype geotype-parish">Parish</span> </span>
                                </div>

                                { existingLayerData.display === 'parish' && existingLayerData.searchmode !== 'ppop' && ( <> { pretty(bucket.doc_count, ['number']) } { keyTerm(existingLayerData.searchmode) } ({ percent }%)</> ) }
                                { existingLayerData.display === 'parish' && existingLayerData.searchmode === 'ppop' && existingLayerData.qs.ppopdata === 'pop1690s' && ( <> { pretty(bucket.doc_count, ['number']) } people</> ) }
                                { existingLayerData.display === 'parish' && existingLayerData.searchmode === 'ppop' && existingLayerData.qs.ppopdata === 'den1690s' && ( <> { pretty(bucket.doc_count, ['number']) } people per km<sup>2</sup></> ) }
                                { existingLayerData.display === 'parish' && existingLayerData.searchmode === 'ppop' && existingLayerData.qs.ppopdata === 'pop1695' && ( <> { pretty(bucket.doc_count, ['number']) } people</> ) }
                                { existingLayerData.display === 'parish' && existingLayerData.searchmode === 'ppop' && existingLayerData.qs.ppopdata === 'den1695' && ( <> { pretty(bucket.doc_count, ['number']) } people per km<sup>2</sup></> ) }
                                { existingLayerData.display === 'parish' && existingLayerData.searchmode === 'ppop' && existingLayerData.qs.ppopdata === 'pop1740s' && ( <> { pretty(bucket.doc_count, ['number']) } people</> ) }
                                { existingLayerData.display === 'parish' && existingLayerData.searchmode === 'ppop' && existingLayerData.qs.ppopdata === 'den1740s' && ( <> { pretty(bucket.doc_count, ['number']) } people per km<sup>2</sup></> ) }
                                { existingLayerData.display === 'parish' && existingLayerData.searchmode === 'ppop' && existingLayerData.qs.ppopdata === 'pop1801' && ( <> { pretty(bucket.doc_count, ['number']) } people</> ) }
                                { existingLayerData.display === 'parish' && existingLayerData.searchmode === 'ppop' && existingLayerData.qs.ppopdata === 'den1801' && ( <> { pretty(bucket.doc_count, ['number']) } people per km<sup>2</sup></> ) }
                                { existingLayerData.display === 'parish' && existingLayerData.searchmode === 'ppop' && existingLayerData.qs.ppopdata === 'area' && ( <> { pretty(bucket.doc_count, ['number']) } m<sup>2</sup></> ) }

                                { existingLayerData.display === 'parish-area' && ( <> { pretty(bucket.doc_count, ['number']) } { keyTerm(existingLayerData.searchmode) } / { pretty(bucket.pop.area, ['number']) } m<sup>2</sup> = { bucket.thisval.toFixed(4) } { keyTerm(existingLayerData.searchmode) } per m<sup>2</sup></> ) }
                                { existingLayerData.display === 'parish-pop1690s' && ( <> { pretty(bucket.doc_count, ['number']) } { keyTerm(existingLayerData.searchmode) } / { pretty(bucket.pop.pop1690s, ['number']) } people = { bucket.thisval.toFixed(4) } { keyTerm(existingLayerData.searchmode) } per person</> ) }
                                { existingLayerData.display === 'parish-pop1695' && ( <> { pretty(bucket.doc_count, ['number']) } { keyTerm(existingLayerData.searchmode) } / { pretty(bucket.pop.pop1695, ['number']) } people = { bucket.thisval.toFixed(4) } { keyTerm(existingLayerData.searchmode) } per person</> ) }
                                { existingLayerData.display === 'parish-pop1740s' && ( <> { pretty(bucket.doc_count, ['number']) } { keyTerm(existingLayerData.searchmode) } / { pretty(bucket.pop.pop1740s, ['number']) } people = { bucket.thisval.toFixed(4) } { keyTerm(existingLayerData.searchmode) } per person</> ) }
                                { existingLayerData.display === 'parish-pop1801' && ( <> { pretty(bucket.doc_count, ['number']) } { keyTerm(existingLayerData.searchmode) } / { pretty(bucket.pop.pop1801, ['number']) } people = { bucket.thisval.toFixed(4) } { keyTerm(existingLayerData.searchmode) } per person</> ) }

                                { existingLayerData.display === 'parish-deaths-sum' && ( <> { bucket.thisval } {/*/ { pretty(total, ['number']) } */}deaths</> ) }
                                { existingLayerData.display === 'parish-deaths-sum-pop1690s' && ( <> { pretty(bucket.deaths_sum.value, ['number']) } deaths / { pretty(bucket.pop.pop1690s, ['number']) } people = { (bucket.thisval * 1000).toFixed(1) } deaths per 1000 of the population.</> ) }
                                { existingLayerData.display === 'parish-deaths-sum-pop1695' && ( <> { pretty(bucket.deaths_sum.value, ['number']) } deaths / { pretty(bucket.pop.pop1695, ['number']) } people = { (bucket.thisval * 1000).toFixed(1) } deaths per 1000 of the population.</> ) }
                                { existingLayerData.display === 'parish-deaths-sum-area' && ( <> { pretty(bucket.deaths_sum.value, ['number']) } deaths / { pretty(bucket.pop.area, ['number']) } m<sup>2</sup> = { bucket.thisval.toFixed(4) } deaths per m<sup>2</sup>.</> ) }

                                { existingLayerData.display === 'parish-hearths-total-avg' && ( <span>{ bucket.thisval.toFixed(4) } hearths per household </span> ) }
                                { existingLayerData.display === 'parish-insval-avg' && ( <span>{ pretty(bucket.thisval, ['number']) } average insured value </span> ) }
                                { existingLayerData.display === 'parish-rent-tax-avg' && ( <span>{ pretty(bucket.thisval, ['number']) } average rent tax (£) </span> ) }
                                { existingLayerData.display === 'parish-per-tax-avg' && ( <span>{ pretty(bucket.thisval, ['number']) } average personal tax (£) </span> ) }
                                { existingLayerData.display === 'parish-tax-avg' && ( <span>{ pretty(bucket.thisval, ['number']) } average rental values </span> ) }

                            {/*
                            <div>
                                <button className="btn btn-link btn-sm" type="button" onClick={ function() { disregardPlace( existingLayerData, bucket ); }  }>
                                    Disregard this place A
                                </button>
                            </div>
                            */}
                            </div>


                        );

                        const title = parish.parish;
                        const disregard = bucket.key;

                        let these_polygons = [];
                        these_polygons.push( ...OSToLeafletPolygons(parish.geo_coordinates, pathOptions, popup, intensity, title, disregard) );

                        const target = polysCentre(these_polygons);

                        if (existingLayerData.polymarker === 'polygons') {

                            polygons.push( ...these_polygons );

                        } else if (existingLayerData.polymarker === 'circles') {

                            circles.push({ 'position': target, 'pathOptions': pathOptions, 'popup': popup, 'intensity': intensity, 'title': title });

                        } else if (existingLayerData.polymarker === 'markers') {

                            markers.push({ 'position': target, 'pathOptions': pathOptions, 'popup': popup, 'intensity': intensity, 'markerfile': getmarkerfile(intensity, existingLayerData.colorscheme, idx), 'title': title, 'disregard': disregard });

                        } else if (existingLayerData.polymarker === 'multicircles') {

                            pathOptions.color = maxOpacity(pathOptions.color); // No opacity for multicircles
                            const tset = generateTargetSet(these_polygons, bucket.doc_count, total);

                            tset.forEach((targ, idx) => {

                                circles.push({ 'position': targ, 'pathOptions': pathOptions, 'popup': popup, 'intensity': intensity });

                            });

                        }

                        //console.log('************************************************');
                        //console.log(existingLayerData);
                        //console.log('************************************************');

                        keys.push( (
                            <div key={ parish.parish }>
                                {/* KEY-POPUP-D */}
                                <span className="agg-legend-square me-2" style={ { backgroundColor:  getcolor(intensity, existingLayerData.colorscheme, idx, existingLayerData.polymarker) } }></span>
                                <button className="btn btn-link me-2" type="button" onClick={ function() { setMapPosition( target ); }  }>
                                    <i className="bi bi-crosshair" />
                                </button>
                                <span>{ parish.parish } <span className="geotype geotype-parish">Parish</span> </span>

                                {/* existingLayerData.display === 'parish' && ( <> { pretty(bucket.parishes_reciprocal_sum.value, ['number']) } { pretty(total, ['number']) } { keyTerm(existingLayerData.searchmode) } ({ percent }%)</> ) */}
                                {/* existingLayerData.display === 'parish' && ( <> { pretty(bucket.doc_count, ['number']) } { keyTerm(existingLayerData.searchmode) } ({ percent }%)</> ) */}

                                { existingLayerData.display === 'parish' && existingLayerData.searchmode !== 'ppop' && ( <> { pretty(bucket.doc_count, ['number']) } { keyTerm(existingLayerData.searchmode) } ({ percent }%)</> ) }
                                { existingLayerData.display === 'parish' && existingLayerData.searchmode === 'ppop' && existingLayerData.qs.ppopdata === 'pop1690s' && ( <> { pretty(bucket.doc_count, ['number']) } people</> ) }
                                { existingLayerData.display === 'parish' && existingLayerData.searchmode === 'ppop' && existingLayerData.qs.ppopdata === 'den1690s' && ( <> { pretty(bucket.doc_count, ['number']) } people per km<sup>2</sup></> ) }
                                { existingLayerData.display === 'parish' && existingLayerData.searchmode === 'ppop' && existingLayerData.qs.ppopdata === 'pop1695' && ( <> { pretty(bucket.doc_count, ['number']) } people</> ) }
                                { existingLayerData.display === 'parish' && existingLayerData.searchmode === 'ppop' && existingLayerData.qs.ppopdata === 'den1695' && ( <> { pretty(bucket.doc_count, ['number']) } people per km<sup>2</sup></> ) }
                                { existingLayerData.display === 'parish' && existingLayerData.searchmode === 'ppop' && existingLayerData.qs.ppopdata === 'pop1740s' && ( <> { pretty(bucket.doc_count, ['number']) } people</> ) }
                                { existingLayerData.display === 'parish' && existingLayerData.searchmode === 'ppop' && existingLayerData.qs.ppopdata === 'den1740s' && ( <> { pretty(bucket.doc_count, ['number']) } people per km<sup>2</sup></> ) }
                                { existingLayerData.display === 'parish' && existingLayerData.searchmode === 'ppop' && existingLayerData.qs.ppopdata === 'pop1801' && ( <> { pretty(bucket.doc_count, ['number']) } people</> ) }
                                { existingLayerData.display === 'parish' && existingLayerData.searchmode === 'ppop' && existingLayerData.qs.ppopdata === 'den1801' && ( <> { pretty(bucket.doc_count, ['number']) } people per km<sup>2</sup></> ) }
                                { existingLayerData.display === 'parish' && existingLayerData.searchmode === 'ppop' && existingLayerData.qs.ppopdata === 'area' && ( <> { pretty(bucket.doc_count, ['number']) } m<sup>2</sup></> ) }

                                { existingLayerData.display === 'parish-area' && ( <> { pretty(bucket.doc_count, ['number']) } { keyTerm(existingLayerData.searchmode) } / { pretty(bucket.pop.area, ['number']) } m<sup>2</sup> = { bucket.thisval.toFixed(4) } { keyTerm(existingLayerData.searchmode) } per m<sup>2</sup></> ) }
                                { existingLayerData.display === 'parish-pop1690s' && ( <> { pretty(bucket.doc_count, ['number']) } { keyTerm(existingLayerData.searchmode) } / { pretty(bucket.pop.pop1690s, ['number']) } people = { bucket.thisval.toFixed(4) } { keyTerm(existingLayerData.searchmode) } per person</> ) }
                                { existingLayerData.display === 'parish-pop1695' && ( <> { pretty(bucket.doc_count, ['number']) } { keyTerm(existingLayerData.searchmode) } / { pretty(bucket.pop.pop1695, ['number']) } people = { bucket.thisval.toFixed(4) } { keyTerm(existingLayerData.searchmode) } per person</> ) }
                                { existingLayerData.display === 'parish-pop1740s' && ( <> { pretty(bucket.doc_count, ['number']) } { keyTerm(existingLayerData.searchmode) } / { pretty(bucket.pop.pop1740s, ['number']) } people = { bucket.thisval.toFixed(4) } { keyTerm(existingLayerData.searchmode) } per person</> ) }
                                { existingLayerData.display === 'parish-pop1801' && ( <> { pretty(bucket.doc_count, ['number']) } { keyTerm(existingLayerData.searchmode) } / { pretty(bucket.pop.pop1801, ['number']) } people = { bucket.thisval.toFixed(4) } { keyTerm(existingLayerData.searchmode) } per person</> ) }

                                { existingLayerData.display === 'parish-deaths-sum' && ( <> { bucket.thisval } {/*/ { pretty(total, ['number']) } */}deaths</> ) }
                                { existingLayerData.display === 'parish-deaths-sum-pop1690s' && ( <> { pretty(bucket.deaths_sum.value, ['number']) } deaths / { pretty(bucket.pop.pop1690s, ['number']) } people = { (bucket.thisval * 1000).toFixed(1) } deaths per 1000 of the population.</> ) }
                                { existingLayerData.display === 'parish-deaths-sum-pop1695' && ( <> { pretty(bucket.deaths_sum.value, ['number']) } deaths / { pretty(bucket.pop.pop1695, ['number']) } people = { (bucket.thisval * 1000).toFixed(1) } deaths per 1000 of the population.</> ) }
                                { existingLayerData.display === 'parish-deaths-sum-area' && ( <> { pretty(bucket.deaths_sum.value, ['number']) } deaths / { pretty(bucket.pop.area, ['number']) } m<sup>2</sup> = { bucket.thisval.toFixed(4) } deaths per m<sup>2</sup>.</> ) }

                                { existingLayerData.display === 'parish-hearths-total-avg' && ( <span>{ bucket.thisval.toFixed(4) } hearths per household </span> ) }
                                { existingLayerData.display === 'parish-insval-avg' && ( <span>{ pretty(bucket.thisval, ['number']) } average insured value </span> ) }
                                { existingLayerData.display === 'parish-rent-tax-avg' && ( <span>{ pretty(bucket.thisval, ['number']) } average rent tax (£) </span> ) }
                                { existingLayerData.display === 'parish-per-tax-avg' && ( <span>{ pretty(bucket.thisval, ['number']) } average personal tax (£) </span> ) }
                                { existingLayerData.display === 'parish-tax-avg' && ( <span>{ pretty(bucket.thisval, ['number']) } average rental values </span> ) }

                            </div>
                        ) );

                    }

                });

                layerData[idx].polygons = polygons;
                layerData[idx].circles = circles;
                layerData[idx].markers = markers;
                layerData[idx].key = keys;

            } else if (
                existingLayerData.display === 'ward' ||
                existingLayerData.display === 'ward-insval-avg' ||
                existingLayerData.display === 'ward-rent-tax-avg' ||
                existingLayerData.display === 'ward-per-tax-avg' ||
                existingLayerData.display === 'ward-tax-avg' ||
                existingLayerData.display === 'ward-hearths-total-avg' ||
                existingLayerData.display === 'ward-deaths-sum'
            ) {

                let total = 0;
                let max = 0;
               
                let buckets = null;

                if (existingLayerData.display === 'ward-insval-avg') { buckets = data.aggregations.geocode_wards_insval_avg.buckets; }
                else if (existingLayerData.display === 'ward-rent-tax-avg') { buckets = data.aggregations.geocode_wards_rent_tax_avg.buckets; }
                else if (existingLayerData.display === 'ward-per-tax-avg') { buckets = data.aggregations.geocode_wards_per_tax_avg.buckets; }
                else if (existingLayerData.display === 'ward-tax-avg') { buckets = data.aggregations.geocode_wards_tax_avg.buckets; }
                else if (existingLayerData.display === 'ward-hearths-total-avg') { buckets = data.aggregations.geocode_wards_hearths_total_avg.buckets; }
                else if (existingLayerData.display === 'ward-deaths-sum') { buckets = data.aggregations.geocode_wards_deaths_sum.buckets; }

                else { buckets = data.aggregations.geocode_wards.buckets; }

                if (existingLayerData.disregard) { buckets = buckets.filter((bucket) => !existingLayerData.disregard.includes(bucket.key)); }

                buckets.forEach((bucket, idx) => {

                    bucket.thisval = 0; // Every bucket needs thisval to have a value, or the sort won't work.

                    if (ward_set.has(bucket.key)) { // We only want to count wards which we can map

                        if (existingLayerData.display === 'ward') { bucket.thisval = bucket.doc_count; }
                        else if (existingLayerData.display === 'ward-insval-avg') { bucket.thisval = bucket.insval_avg.value; }
                        else if (existingLayerData.display === 'ward-rent-tax-avg') { bucket.thisval = bucket.rent_tax_avg.value; }
                        else if (existingLayerData.display === 'ward-per-tax-avg') { bucket.thisval = bucket.per_tax_avg.value; }
                        else if (existingLayerData.display === 'ward-tax-avg') { bucket.thisval = bucket.tax_avg.value; }
                        else if (existingLayerData.display === 'ward-hearths-total-avg') { bucket.thisval = bucket.hearths_total_avg.value; }
                        else if (existingLayerData.display === 'ward-deaths-sum') { bucket.thisval = bucket.deaths_sum.value; }

                    } else {

                        console.log('bucket.key IS NOT in ward_set: ***' + bucket.key + '***');

                    }

                    if (bucket.thisval) {

                        total = total + bucket.thisval;
                        if (bucket.thisval > max) { max = bucket.thisval; }

                    }

                });

                const sorted_buckets = buckets.sort((a, b) => b.thisval - a.thisval);

                let polygons = [];
                let circles = [];
                let markers = [];
                let keys = [];

                //console.log(ward_object);

                sorted_buckets.forEach((bucket, idx) => {

                    if (ward_set.has(bucket.key)) { // We only want to count wards which we can map

                        const ward = ward_object[bucket.key];

                        const intensity = bucket.thisval / max;
                        const percent = Math.round((bucket.thisval / total) * 10000) / 100;

                        const pathOptions = { color: getcolor(intensity, existingLayerData.colorscheme, idx, existingLayerData.polymarker), stroke: (existingLayerData.stroke === 'stroke' ? true : false), weight: 1 };

                        const popup = (

                            <div>
                                {/* KEY-POPUP-E */}
                                <div>
                                <span className="agg-legend-square me-2" style={ { 'background': getcolor(intensity, existingLayerData.colorscheme, idx, existingLayerData.polymarker) }  } ></span>
                                <b>{ ward.ward }</b> <span className="geotype geotype-ward">Ward</span>
                                </div>
                                <div>

                                    { existingLayerData.display === 'ward' && ( <span> { pretty(bucket.thisval, ['number']) }{/* / { pretty(total, ['number']) */} { keyTerm(existingLayerData.searchmode) } ({ percent }%)</span> ) }
                                    { existingLayerData.display === 'ward-deaths-sum' && ( <span>{ pretty(bucket.thisval, ['number']) } deaths </span> ) }
                                    { existingLayerData.display === 'ward-hearths-total-avg' && ( <span>{ pretty(bucket.thisval, ['number']) } hearths per household </span> ) }
                                    { existingLayerData.display === 'ward-insval-avg' && ( <span>{ pretty(bucket.thisval, ['number']) } average insured value </span> ) }
                                    { existingLayerData.display === 'ward-rent-tax-avg' && ( <span>{ pretty(bucket.thisval, ['number']) } average rent tax (£) </span> ) }
                                    { existingLayerData.display === 'ward-per-tax-avg' && ( <span>{ pretty(bucket.thisval, ['number']) } average personal tax (£) </span> ) }
                                    { existingLayerData.display === 'ward-tax-avg' && ( <span>{ pretty(bucket.thisval, ['number']) } average rental value </span> ) }

                                </div>
                                {/*
                                <div>
                                    <button className="btn btn-link btn-sm" type="button" onClick={ function() { disregardPlace( existingLayerData, bucket ); }  }>
                                        Disregard this place B
                                    </button>
                                </div>
                                */}
                            </div>
                        );

                        const title = ward.ward;
                        const disregard = bucket.key;

                        let these_polygons = [];
                        these_polygons.push( ...OSToLeafletPolygons(ward.geo_coordinates, pathOptions, popup, intensity, title, disregard) );

                        const target = polysCentre(these_polygons);

                        if (existingLayerData.polymarker === 'polygons') {

                            polygons.push( ...these_polygons );

                        } else if (existingLayerData.polymarker === 'circles') {

                            circles.push({ 'position': target, 'pathOptions': pathOptions, 'popup': popup, 'intensity': intensity, 'title': title })

                        } else if (existingLayerData.polymarker === 'markers') {

                            markers.push({ 'position': target, 'pathOptions': pathOptions, 'popup': popup, 'intensity': intensity, 'markerfile': getmarkerfile(intensity, existingLayerData.colorscheme, idx), 'title': title, 'disregard': disregard });

                        } else if (existingLayerData.polymarker === 'multicircles') {

                            pathOptions.color = maxOpacity(pathOptions.color); // No opacity for multicircles
                            const tset = generateTargetSet(these_polygons, bucket.thisval, total);

                            tset.forEach((targ, idx) => {

                                circles.push({ 'position': targ, 'pathOptions': pathOptions, 'popup': popup, 'intensity': intensity });

                            });

                        }

                        keys.push( (

                            <div key={ ward.ward }>
                                {/* KEY-POPUP-F */}
                                <span className="agg-legend-square me-2" style={ { backgroundColor:  getcolor(intensity, existingLayerData.colorscheme, idx, existingLayerData.polymarker) } }></span>
                                <button className="btn btn-link me-2" type="button" onClick={ function() { setMapPosition( target ); }  }>
                                    <i className="bi bi-crosshair" />
                                </button>
                                { ward.ward } <span className="geotype geotype-ward">Ward </span>

                                { existingLayerData.display === 'ward' && ( <span> { pretty(bucket.thisval, ['number']) }{/* / { pretty(total, ['number']) */} { keyTerm(existingLayerData.searchmode) } ({ percent }%)</span> ) }
                                { existingLayerData.display === 'ward-deaths-sum' && ( <span> { pretty(bucket.thisval, ['number']) }deaths </span> ) }
                                { existingLayerData.display === 'ward-hearths-total-avg' && ( <span> { pretty(bucket.thisval, ['number']) } hearths per household </span> ) }
                                { existingLayerData.display === 'ward-insval-avg' && ( <span>{ pretty(bucket.thisval, ['number']) } average insured value </span> ) }
                                { existingLayerData.display === 'ward-rent-tax-avg' && ( <span>{ pretty(bucket.thisval, ['number']) } average rent tax (£) </span> ) }
                                { existingLayerData.display === 'ward-per-tax-avg' && ( <span>{ pretty(bucket.thisval, ['number']) } average personal tax (£) </span> ) }
                                { existingLayerData.display === 'ward-tax-avg' && ( <span>{ pretty(bucket.thisval, ['number']) } average rental value </span> ) }

                            </div>

                        ) );

                    }

                });

                layerData[idx].polygons = polygons;
                layerData[idx].circles = circles;
                layerData[idx].markers = markers;
                layerData[idx].key = keys;
 
            } else if (
                existingLayerData.display === 'heat-blocks-3dp' ||
                existingLayerData.display === 'heat-blocks-3dp-insval-avg' ||
                existingLayerData.display === 'heat-blocks-3dp-rent-tax-avg' ||
                existingLayerData.display === 'heat-blocks-3dp-per-tax-avg' ||
                existingLayerData.display === 'heat-blocks-3dp-tax-avg' ||
                existingLayerData.display === 'heat-blocks-3dp-hearths-total-avg' ||
                existingLayerData.display === 'heat-blocks-3dp-deaths-sum'
            ) {

                let total = 0;
                let max = 0;

                let buckets = null;

                if (existingLayerData.display === 'heat-blocks-3dp-insval-avg') { buckets = data.aggregations.geocode_heatmap_cells_3dp_insval_avg.buckets; }
                else if (existingLayerData.display === 'heat-blocks-3dp-rent-tax-avg') { buckets = data.aggregations.geocode_heatmap_cells_3dp_rent_tax_avg.buckets; }
                else if (existingLayerData.display === 'heat-blocks-3dp-per-tax-avg') { buckets = data.aggregations.geocode_heatmap_cells_3dp_per_tax_avg.buckets; }
                else if (existingLayerData.display === 'heat-blocks-3dp-tax-avg') { buckets = data.aggregations.geocode_heatmap_cells_3dp_tax_avg.buckets; }
                else if (existingLayerData.display === 'heat-blocks-3dp-hearths-total-avg') { buckets = data.aggregations.geocode_heatmap_cells_3dp_hearths_total_avg.buckets; }
                else if (existingLayerData.display === 'heat-blocks-3dp-deaths-sum') { buckets = data.aggregations.geocode_heatmap_cells_3dp_deaths_sum.buckets; }

                else { buckets = data.aggregations.geocode_heatmap_cells_3dp.buckets; }

                if (existingLayerData.disregard) { buckets = buckets.filter((bucket) => !existingLayerData.disregard.includes(bucket.key)); }

                buckets.forEach((bucket, idx) => {

                    if (existingLayerData.display === 'heat-blocks-3dp-insval-avg') { bucket.thisval = bucket.insval_avg.value; }
                    else if (existingLayerData.display === 'heat-blocks-3dp-rent-tax-avg') { bucket.thisval = bucket.rent_tax_avg.value; }
                    else if (existingLayerData.display === 'heat-blocks-3dp-per-tax-avg') { bucket.thisval = bucket.per_tax_avg.value; }
                    else if (existingLayerData.display === 'heat-blocks-3dp-tax-avg') { bucket.thisval = bucket.tax_avg.value; }
                    else if (existingLayerData.display === 'heat-blocks-3dp-hearths-total-avg') { bucket.thisval = bucket.hearths_total_avg.value; }
                    else if (existingLayerData.display === 'heat-blocks-3dp-deaths-sum') { bucket.thisval = bucket.deaths_sum.value; }
                    else { bucket.thisval = bucket.doc_count; }

                    total = total + bucket.thisval;
                    if (bucket.thisval > max) { max = bucket.thisval; }

                });

                const sorted_buckets = buckets.sort((a, b) => b.thisval - a.thisval);

                let polygons = [];
                let circles = [];
                let markers = [];
                let keys = [];

                sorted_buckets.forEach((bucket, idx) => {

                    const split = bucket.key.split(',')
                    const lat = parseFloat(split[1]); // OS coords are given lon lat
                    const lon = parseFloat(split[0]);

                    const intensity = bucket.thisval / max;
                    const percent = Math.round((bucket.thisval / total) * 10000) / 100;

                    const pathOptions = { color: getcolor(intensity, existingLayerData.colorscheme, idx, existingLayerData.polymarker), stroke: (existingLayerData.stroke === 'stroke' ? true : false), weight: 1 };
                    const popup = ( 

                            <div>
                                {/* KEY-POPUP-G */}
                                <div>
                                <span className="agg-legend-square me-2" style={ { 'background': getcolor(intensity, existingLayerData.colorscheme, idx, existingLayerData.polymarker) }  } ></span>
                                <b>{ bucket.key }</b> <span className="geotype geotype-gridref">Gridref</span>
                                </div>
                                <div>
                                    <span> {/* / { pretty(total, ['number']) }*/} </span>

                                    { existingLayerData.display === 'heat-blocks-3dp' && ( <span>{ pretty(bucket.thisval, ['number']) } { keyTerm(existingLayerData.searchmode) } ({ percent }%)</span> ) }
                                    { existingLayerData.display === 'heat-blocks-3dp-deaths-sum' && ( <span>{ pretty(bucket.thisval, ['number']) } deaths </span> ) }
                                    { existingLayerData.display === 'heat-blocks-3dp-hearths-total-avg' && ( <span>{ pretty(bucket.thisval, ['number']) } hearths per household </span> ) }
                                    { existingLayerData.display === 'heat-blocks-3dp-insval-avg' && ( <span>{ pretty(bucket.thisval, ['number']) } average insured value </span> ) }
                                    { existingLayerData.display === 'heat-blocks-3dp-rent-tax-avg' && ( <span>{ pretty(bucket.thisval, ['number']) } average rent tax (£) </span> ) }
                                    { existingLayerData.display === 'heat-blocks-3dp-per-tax-avg' && ( <span>{ pretty(bucket.thisval, ['number']) } average personal tax (£) </span> ) }
                                    { existingLayerData.display === 'heat-blocks-3dp-tax-avg' && ( <span>{ pretty(bucket.thisval, ['number']) } average rental value </span> ) }

                                </div>
                                {/*
                                <div>
                                    <button className="btn btn-link btn-sm" type="button" onClick={ function() { disregardPlace( existingLayerData, bucket ); }  }>
                                        Disregard this place C
                                    </button>
                                </div>
                                */}
                            </div>

                    );

                    const title = bucket.key;
                    const disregard = bucket.key;

                    const polygon = {
                        "positions": [latLonBox(lat, lon, 0.001)],
                        //"pathOptions": { color: heatcol(intensity), fillOpacity: 0.9, stroke: true, weight: 2 },
                        "pathOptions": pathOptions,
                        "popup": popup,
                        "disregard": disregard,
                    };

                    const target = polysCentre( [polygon] );

                    if (existingLayerData.polymarker === 'polygons') {

                        polygons.push(polygon);

                    } else if (existingLayerData.polymarker === 'circles') {

                        circles.push({ 'position': target, 'pathOptions': pathOptions, 'popup': popup, 'intensity': intensity, 'title': title });

                    } else if (existingLayerData.polymarker === 'markers') {

                        markers.push({ 'position': target, 'pathOptions': pathOptions, 'popup': popup, 'intensity': intensity, 'markerfile': getmarkerfile(intensity, existingLayerData.colorscheme, idx), 'title': title, 'disregard': disregard });

                    } else if (existingLayerData.polymarker === 'multicircles') {

                        pathOptions.color = maxOpacity(pathOptions.color); // No opacity for multicircles
                        const tset = generateTargetSet([ polygon ], bucket.thisval, total);

                        tset.forEach((targ, idx) => {

                            circles.push({ 'position': targ, 'pathOptions': pathOptions, 'popup': popup, 'intensity': intensity });

                        });

                    }

                    keys.push( (

                        <div key={ bucket.key }>
                            {/* KEY-POPUP-H */}
                            <span className="agg-legend-square me-2" style={ { backgroundColor: getcolor(intensity, existingLayerData.colorscheme, idx, existingLayerData.polymarker) } }></span>
                            <button className="btn btn-link me-2" type="button" onClick={ function() { setMapPosition( target ); }  }>
                                <i className="bi bi-crosshair" />
                            </button> 
                            <span>{ bucket.key } <span className="geotype geotype-gridref">Gridref</span> </span>

                            { existingLayerData.display === 'heat-blocks-3dp' && ( <span>{ pretty(bucket.thisval, ['number']) } { keyTerm(existingLayerData.searchmode) } ({ percent }%)</span> ) }
                            { existingLayerData.display === 'heat-blocks-3dp-deaths-sum' && ( <span>{ pretty(bucket.thisval, ['number']) } deaths </span> ) }
                            { existingLayerData.display === 'heat-blocks-3dp-hearths-total-avg' && ( <span>{ pretty(bucket.thisval, ['number']) } hearths per household </span> ) }
                            { existingLayerData.display === 'heat-blocks-3dp-insval-avg' && ( <span>{ pretty(bucket.thisval, ['number']) } average insured value </span> ) }
                            { existingLayerData.display === 'heat-blocks-3dp-rent-tax-avg' && ( <span>{ pretty(bucket.thisval, ['number']) } average rent tax (£) </span> ) }
                            { existingLayerData.display === 'heat-blocks-3dp-per-tax-avg' && ( <span>{ pretty(bucket.thisval, ['number']) } average personal tax (£) </span> ) }
                            { existingLayerData.display === 'heat-blocks-3dp-tax-avg' && ( <span>{ pretty(bucket.thisval, ['number']) } average rental value </span> ) }

                        </div>

                    ) );

                });         

                //console.log(polygons);

                layerData[idx].polygons = polygons;
                layerData[idx].circles = circles;
                layerData[idx].markers = markers;
                layerData[idx].key = keys;

            } else if (
                existingLayerData.display === 'place' ||
                existingLayerData.display === 'place-insval-avg' ||
                existingLayerData.display === 'place-rent-tax-avg' ||
                existingLayerData.display === 'place-per-tax-avg' ||
                existingLayerData.display === 'place-tax-avg' ||
                existingLayerData.display === 'place-hearths-total-avg' ||
                existingLayerData.display === 'place-deaths-sum'
            ) {
       
                let total = 0;
                let max = 0;
 
                let buckets = null;

                if (existingLayerData.display === 'place-insval-avg') { buckets = data.aggregations.geocode_place_insval_avg.buckets; }
                else if (existingLayerData.display === 'place-rent-tax-avg') { buckets = data.aggregations.geocode_place_rent_tax_avg.buckets; }
                else if (existingLayerData.display === 'place-per-tax-avg') { buckets = data.aggregations.geocode_place_per_tax_avg.buckets; }
                else if (existingLayerData.display === 'place-tax-avg') { buckets = data.aggregations.geocode_place_tax_avg.buckets; }
                else if (existingLayerData.display === 'place-hearths-total-avg') { buckets = data.aggregations.geocode_place_hearths_total_avg.buckets; }
                else if (existingLayerData.display === 'place-deaths-sum') { buckets = data.aggregations.geocode_place_deaths_sum.buckets; }

                else { buckets = data.aggregations.geocode_place.buckets; }

                if (existingLayerData.disregard) { buckets = buckets.filter((bucket) => !existingLayerData.disregard.includes(bucket.key)); }

                buckets.forEach((bucket, idx) => {

                    if (existingLayerData.display === 'place-insval-avg') { bucket.thisval = bucket.insval_avg.value; }
                    else if (existingLayerData.display === 'place-rent-tax-avg') { bucket.thisval = bucket.rent_tax_avg.value; }
                    else if (existingLayerData.display === 'place-per-tax-avg') { bucket.thisval = bucket.per_tax_avg.value; }
                    else if (existingLayerData.display === 'place-tax-avg') { bucket.thisval = bucket.tax_avg.value; }
                    else if (existingLayerData.display === 'place-hearths-total-avg') { bucket.thisval = bucket.hearths_total_avg.value; }
                    else if (existingLayerData.display === 'place-deaths-sum') { bucket.thisval = bucket.deaths_sum.value; }

                    else { bucket.thisval = bucket.doc_count; }

                    total = total + bucket.thisval;
                    if (bucket.thisval > max) { max = bucket.thisval; }

                });

                const sorted_buckets = buckets.sort((a, b) => b.thisval - a.thisval);

                let polygons = [];
                let circles = [];
                let markers = [];
                let keys = [];

                sorted_buckets.forEach((bucket, idx) => {

                    const split = bucket.key.split('|')
                    const geodescription = split[0];
                    const geotype = split[1];
                    const coords = split[2];

                    const intensity = bucket.thisval / max;
                    const percent = Math.round((bucket.thisval / total) * 10000) / 100;

                    //const pathOptions = { color: heatcol(intensity), fillOpacity: 0.9, stroke: true, weight: 2 };
                    const pathOptions = { color: getcolor(intensity, existingLayerData.colorscheme, idx, existingLayerData.polymarker), stroke: (existingLayerData.stroke === 'stroke' ? true : false), weight: 1 };
                    const popup = (

                        <div>
                            {/* KEY-POPUP-I */}
                            <div>
                                <span className="agg-legend-square me-2" style={ { 'background': getcolor(intensity, existingLayerData.colorscheme, idx, existingLayerData.polymarker) }  } ></span>
                                <b>{ geodescription }</b> <span className={ "geotype geotype-" + geotype }>{ pretty(geotype, ['lookup_geotype']) }</span>
                            </div>
                            <div>
                                <span>{/* / { pretty(total, ['number']) }*/} </span>

                                { existingLayerData.searchmode === 'molacp' && ( <span>{ pretty(bucket.thisval, ['number']) } finds </span> ) }
                                { existingLayerData.searchmode === 'molag' && ( <span>{ pretty(bucket.thisval, ['number']) } finds </span> ) }

                                { existingLayerData.searchmode !== 'molacp' && existingLayerData.searchmode !== 'molag' && existingLayerData.searchmode !== 'place' && (

                                    <>
                                    { existingLayerData.display === 'place' && ( <span>{ pretty(bucket.thisval, ['number']) } { keyTerm(existingLayerData.searchmode) } ({ percent }%)</span> ) }
                                    { existingLayerData.display === 'place-hearths-total-avg' && ( <span>{ pretty(bucket.thisval, ['number']) } hearths per household </span> ) }
                                    { existingLayerData.display === 'place-insval-avg' && ( <span>{ pretty(bucket.thisval, ['number']) } average insured value </span> ) }
                                    { existingLayerData.display === 'place-rent-tax-avg' && ( <span>{ pretty(bucket.thisval, ['number']) } average rent tax (£) </span> ) }
                                    { existingLayerData.display === 'place-per-tax-avg' && ( <span>{ pretty(bucket.thisval, ['number']) } average personal tax (£) </span> ) }
                                    { existingLayerData.display === 'place-tax-avg' && ( <span>{ pretty(bucket.thisval, ['number']) } average rental value </span> ) }
                                    </>

                                )}

                            </div>
                            {/*
                            <div>
                                <button className="btn btn-link btn-sm" type="button" onClick={ function() { disregardPlace( existingLayerData, bucket ); }  }>
                                    Disregard this place D
                                </button>
                            </div>
                            */}
                        </div>

                    );

                    const title = geodescription;
                    const disregard = bucket.key;

                    if (coords.includes('P')) { 

                        let these_polygons = [];
                        these_polygons.push( ...OSToLeafletPolygons(coords, pathOptions, popup, intensity, title, disregard) );

                        if (these_polygons.length > 0) {

                            const target = polysCentre(these_polygons);

                            if (existingLayerData.polymarker === 'polygons') {

                                polygons.push( ...these_polygons );

                            } else if (existingLayerData.polymarker === 'circles') {

                                circles.push({ 'position': target, 'pathOptions': pathOptions, 'popup': popup, 'intensity': intensity });

                            } else if (existingLayerData.polymarker === 'markers') {

                                markers.push({ 'position': target, 'pathOptions': pathOptions, 'popup': popup, 'intensity': intensity, 'markerfile': getmarkerfile(intensity, existingLayerData.colorscheme, idx), 'title': title, 'disregard': disregard });

                            } else if (existingLayerData.polymarker === 'multicircles') {

                                pathOptions.color = maxOpacity(pathOptions.color); // No opacity for multicircles
                                const tset = generateTargetSet(these_polygons, bucket.thisval, total);

                                tset.forEach((targ, idx) => {

                                    circles.push({ 'position': targ, 'pathOptions': pathOptions, 'popup': popup, 'intensity': intensity });

                                });

                            }

                            keys.push( (

                                <div key={ bucket.key }>
                                    {/* KEY-POPUP-J */}
                                    <span className="agg-legend-square me-2" style={ { backgroundColor: getcolor(intensity, existingLayerData.colorscheme, idx, existingLayerData.polymarker) } }></span>
                                    <button className="btn btn-link me-2" type="button" onClick={ function() { setMapPosition( target ); }  }>
                                        <i className="bi bi-crosshair" />
                                    </button>
                                    <span>{ geodescription } <span className={ "geotype geotype-" + geotype }>{ pretty(geotype, ['lookup_geotype']) }</span> </span>

                                    { existingLayerData.searchmode === 'molacp' && ( <span>{ pretty(bucket.thisval, ['number']) } finds </span> ) }
                                    { existingLayerData.searchmode === 'molag' && ( <span>{ pretty(bucket.thisval, ['number']) } finds </span> ) }

                                    { existingLayerData.searchmode !== 'molacp' && existingLayerData.searchmode !== 'molag' && existingLayerData.searchmode !== 'place' && (

                                        <>
                                        { existingLayerData.display === 'place' && ( <span>{ pretty(bucket.thisval, ['number']) } { keyTerm(existingLayerData.searchmode) } ({ percent }%)</span> ) }
                                        { existingLayerData.display === 'place-hearths-total-avg' && ( <span>{ pretty(bucket.thisval, ['number']) } hearths per household </span> ) }
                                        { existingLayerData.display === 'place-insval-avg' && ( <span>{ pretty(bucket.thisval, ['number']) } average insured value </span> ) }
                                        { existingLayerData.display === 'place-rent-tax-avg' && ( <span>{ pretty(bucket.thisval, ['number']) } average rent tax (£) </span> ) }
                                        { existingLayerData.display === 'place-per-tax-avg' && ( <span>{ pretty(bucket.thisval, ['number']) } average personal tax (£) </span> ) }
                                        { existingLayerData.display === 'place-tax-avg' && ( <span>{ pretty(bucket.thisval, ['number']) } average rental value </span> ) }
                                        </>

                                    )}

                                </div>

                            ) );

                        }

                    } else {

                        const position = OSToGeoJSONPoint(coords);

                        //circles.push({ 'position': position, 'pathOptions': pathOptions, 'popup': popup, 'intensity': intensity });
                        markers.push({ 'position': position, 'pathOptions': pathOptions, 'popup': popup, 'intensity': intensity, 'markerfile': getmarkerfile(intensity, existingLayerData.colorscheme, idx), 'title': title, 'disregard': disregard });

                        let reactkey = bucket.key;
                        if (existingLayerData.hit && existingLayerData.hit._source && existingLayerData.hit._source.geocode_idkey) { reactkey = existingLayerData.hit._source.geocode_idkey; }

                        keys.push( (

                            <div key={ reactkey }>
                                {/* KEY-POPUP-K */}
                                <span className="agg-legend-square me-2" style={ { backgroundColor: getcolor(intensity, existingLayerData.colorscheme, idx, existingLayerData.polymarker) } }></span>
                                <button className="btn btn-link me-2" type="button" onClick={ function() { setMapPosition( position ); }  }>
                                    <i className="bi bi-crosshair" />
                                </button>
                                <span>{ geodescription } { bucket.thisval }{/* / { total }*/} </span>
                                { existingLayerData.searchmode === 'molacp' && ( <span>finds </span> ) }
                                { existingLayerData.searchmode === 'molag' && ( <span>finds </span> ) }
                                {/*<span>({ percent }%)</span>*/}
                            </div>

                        ) );

                    }


                });         

                //console.log(circles);
                //console.log(markers);

                //if (polygons.length > 0) { layerData[idx].polygons = polygons; }
                //if (circles.length > 0) { layerData[idx].circles = circles; }
                //if (keys.length > 0) { layerData[idx].key = keys; }
                
                layerData[idx].polygons = polygons;
                layerData[idx].circles = circles;
                layerData[idx].markers = markers;
                layerData[idx].key = keys;

            }

        }

        setLayerData( [ ...layerData ] ); // [...foo] is the js array spread syntax. It is needed here because react needs to see a new object in order to update the state
        setActiveLayer(idx);

        // For place query layers, we might set the map position and zoom to make it clear there is new information
        if (existingLayerData.type === 'query' && existingLayerData.searchmode === 'place' && data.hits && data.hits.hits) {

            if (data.hits.hits.length === 1) {

                // With just one hit, we reposition the map right to it.
                const coords = data.hits.hits[0]._source.geocode_geocoordinates;
                let these_polygons = [];
                these_polygons.push( ...OSToLeafletPolygons(coords, null, null, null, null, null) );
                const mptarget = polysCentre(these_polygons)
                setMapPosition( mptarget );

            } else if (data.hits.hits.length > 1) {

                // With multiple hits, we zoom out to show the whole map.
                setMapPositionAndZoom([51.50811, -0.07577], 14);

            }

        }

    };

    const populateLayer = (idx) => {

        //console.log('populateLayer');    

        const existingLayerData = layerData[idx];

        let path = null;

        if (existingLayerData.type === 'hit') { processLayerData(idx, existingLayerData, null); } // No need to query the API for hit layers

        if (existingLayerData.searchmode === 'ppop') { // No need to query the API for ppop query layers

            const data = fakePpopESData(existingLayerData);
            processLayerData(idx, existingLayerData, data);

        }

        if (existingLayerData.searchmode === 'place') { path = `${process.env.REACT_APP_API_URL}/view/loclon_polygon`; }       
        if (existingLayerData.searchmode === 'oldbailey') { path = `${process.env.REACT_APP_API_URL}/view/loclon_oldbailey`; }       
        if (existingLayerData.searchmode === 'llcoroner') { path = `${process.env.REACT_APP_API_URL}/view/loclon_llcoroner`; }
        if (existingLayerData.searchmode === 'llcriminal') { path = `${process.env.REACT_APP_API_URL}/view/loclon_llcriminal`; }
        if (existingLayerData.searchmode === 'llguild') { path = `${process.env.REACT_APP_API_URL}/view/loclon_llguild`; }
        if (existingLayerData.searchmode === 'llpoor') { path = `${process.env.REACT_APP_API_URL}/view/loclon_llpoor`; }
        if (existingLayerData.searchmode === 'fire') { path = `${process.env.REACT_APP_API_URL}/view/loclon_fire`; }
        if (existingLayerData.searchmode === 'ahdsfsp') { path = `${process.env.REACT_APP_API_URL}/view/loclon_ahdsfsp`; }
        if (existingLayerData.searchmode === 'ahdsdir') { path = `${process.env.REACT_APP_API_URL}/view/loclon_ahdsdir`; }
        if (existingLayerData.searchmode === 'pcc') { path = `${process.env.REACT_APP_API_URL}/view/loclon_pcc`; }
        if (existingLayerData.searchmode === 'lsdspar') { path = `${process.env.REACT_APP_API_URL}/view/loclon_lsdspar`; }
        if (existingLayerData.searchmode === 'westrate') { path = `${process.env.REACT_APP_API_URL}/view/loclon_westrate`; }
        if (existingLayerData.searchmode === 'westpoll') { path = `${process.env.REACT_APP_API_URL}/view/loclon_westpoll`; }
        if (existingLayerData.searchmode === 'cmhht') { path = `${process.env.REACT_APP_API_URL}/view/loclon_cmhht`; }
        if (existingLayerData.searchmode === 'cmhpd') { path = `${process.env.REACT_APP_API_URL}/view/loclon_cmhpd`; }
        if (existingLayerData.searchmode === 'molag') { path = `${process.env.REACT_APP_API_URL}/view/loclon_molag`; }
        if (existingLayerData.searchmode === 'molacp') { path = `${process.env.REACT_APP_API_URL}/view/loclon_molacp`; }

        if (path !== null) {

            let params = { ...existingLayerData.qs };

            if (

                existingLayerData.display === 'parish' ||
                existingLayerData.display === 'parish-area' ||
                existingLayerData.display === 'parish-pop1690s' ||
                existingLayerData.display === 'parish-pop1695' ||
                existingLayerData.display === 'parish-pop1740s' ||
                existingLayerData.display === 'parish-pop1801'

            ) { params['series'] = 'geocode_parishes'; }

            else if (existingLayerData.display === 'place-insval-avg') { params['series'] = 'geocode_place_insval_avg'; }
            else if (existingLayerData.display === 'parish-insval-avg') { params['series'] = 'geocode_parishes_insval_avg'; }
            else if (existingLayerData.display === 'ward-insval-avg') { params['series'] = 'geocode_wards_insval_avg'; }
            else if (existingLayerData.display === 'heat-blocks-3dp-insval-avg') { params['series'] = 'geocode_heatmap_cells_3dp_insval_avg'; }

            else if (existingLayerData.display === 'place-rent-tax-avg') { params['series'] = 'geocode_place_rent_tax_avg'; }
            else if (existingLayerData.display === 'parish-rent-tax-avg') { params['series'] = 'geocode_parishes_rent_tax_avg'; }
            else if (existingLayerData.display === 'ward-rent-tax-avg') { params['series'] = 'geocode_wards_rent_tax_avg'; }
            else if (existingLayerData.display === 'heat-blocks-3dp-rent-tax-avg') { params['series'] = 'geocode_heatmap_cells_3dp_rent_tax_avg'; }

            else if (existingLayerData.display === 'place-per-tax-avg') { params['series'] = 'geocode_place_per_tax_avg'; }
            else if (existingLayerData.display === 'parish-per-tax-avg') { params['series'] = 'geocode_parishes_per_tax_avg'; }
            else if (existingLayerData.display === 'ward-per-tax-avg') { params['series'] = 'geocode_wards_per_tax_avg'; }
            else if (existingLayerData.display === 'heat-blocks-3dp-per-tax-avg') { params['series'] = 'geocode_heatmap_cells_3dp_per_tax_avg'; }

            else if (existingLayerData.display === 'place-tax-avg') { params['series'] = 'geocode_place_tax_avg'; }
            else if (existingLayerData.display === 'parish-tax-avg') { params['series'] = 'geocode_parishes_tax_avg'; }
            else if (existingLayerData.display === 'ward-tax-avg') { params['series'] = 'geocode_wards_tax_avg'; }
            else if (existingLayerData.display === 'heat-blocks-3dp-tax-avg') { params['series'] = 'geocode_heatmap_cells_3dp_tax_avg'; }

            else if (existingLayerData.display === 'place-hearths-total-avg') { params['series'] = 'geocode_place_hearths_total_avg'; }
            else if (existingLayerData.display === 'parish-hearths-total-avg') { params['series'] = 'geocode_parishes_hearths_total_avg'; }
            else if (existingLayerData.display === 'ward-hearths-total-avg') { params['series'] = 'geocode_wards_hearths_total_avg'; }
            else if (existingLayerData.display === 'heat-blocks-3dp-hearths-total-avg') { params['series'] = 'geocode_heatmap_cells_3dp_hearths_total_avg'; }

            else if (existingLayerData.display === 'place-deaths-sum') { params['series'] = 'geocode_place_deaths_sum'; }
            else if (existingLayerData.display === 'parish-deaths-sum') { params['series'] = 'geocode_parishes_deaths_sum'; }
            else if (existingLayerData.display === 'parish-deaths-sum-area') { params['series'] = 'geocode_parishes_deaths_sum'; }
            else if (existingLayerData.display === 'parish-deaths-sum-pop1690s') { params['series'] = 'geocode_parishes_deaths_sum'; }
            else if (existingLayerData.display === 'parish-deaths-sum-pop1695') { params['series'] = 'geocode_parishes_deaths_sum'; }
            else if (existingLayerData.display === 'parish-deaths-sum-pop1740s') { params['series'] = 'geocode_parishes_deaths_sum'; }
            else if (existingLayerData.display === 'parish-deaths-sum-pop1801') { params['series'] = 'geocode_parishes_deaths_sum'; }
            else if (existingLayerData.display === 'ward-deaths-sum') { params['series'] = 'geocode_wards_deaths_sum'; }
            else if (existingLayerData.display === 'heat-blocks-3dp-deaths-sum') { params['series'] = 'geocode_heatmap_cells_3dp_deaths_sum'; }

            else if (existingLayerData.display === 'ward') { params['series'] = 'geocode_wards'; }
            else if (existingLayerData.display === 'heat' || existingLayerData.display === 'heat-blocks') { params['series'] = 'geocode_heatmap'; }
            else if (existingLayerData.display === 'heat-blocks-3dp') { params['series'] = 'geocode_heatmap_cells_3dp';}
            else if (existingLayerData.display === 'heat-blocks-2dp') { params['series'] = 'geocode_heatmap_cells_2dp';}
            else if (existingLayerData.display === 'place') { params['series'] = 'geocode_place'; }
            
            const stringified = queryString.stringify(params, { arrayFormat: "bracket" });
            let url = `${ path }?${ stringified }`;

            if (existingLayerData.specialDataUrl) {

                //console.log('Using specialDataUrl');
                url = existingLayerData.specialDataUrl;

            }

            //console.log(url);

            const fetchData = async () => {

                try {

                    const resp = await axios.get(url, {
                        withCredentials: false,
                    });

                    const data = await resp?.data;

                    //console.log(data);

                    processLayerData(idx, existingLayerData, data);

                } catch (error) {

                    console.log(error);

                }
            };

            fetchData();

        }

    };

    const addQueryLayer = (searchmode, qs) => {

        let defaultDisplay = 'place';
        let defaultColorscheme = defaultColor;
        let defaultStroke = 'stroke';

        if (searchmode === 'ppop') { defaultDisplay = 'parish'; defaultStroke = 'nostroke'; defaultColorscheme = 'heat'; }
        if (searchmode === 'fire') { defaultDisplay = 'place-insval-avg'; }
        if (searchmode === 'ahdsfsp') { defaultDisplay = 'place-rent-tax-avg'; }
        if (searchmode === 'westrate') { defaultDisplay = 'place-tax-avg'; }
        if (searchmode === 'cmhht') { defaultDisplay = 'place-hearths-total-avg'; }
        if (searchmode === 'cmhpd') { defaultDisplay = 'parish-deaths-sum-pop1690s'; }
        if (searchmode === 'place') { defaultColorscheme = 'key'; }

        //console.log('QQSS');
        //console.log(qs);

        if (qs && qs.layerdisplay) { defaultDisplay = qs.layerdisplay; } // If the user specified a layerdisplay, we use it.

        const newLayerData = { 
            "type": "query",
            //"description": pretty(qs, ['query-brief']),
            "visible": true,
            "opacity": 0.6,
            "display": defaultDisplay,
            "stroke": defaultStroke,
            "colorscheme": defaultColorscheme,
            "searchmode": searchmode,
            "qs": qs,
            "polymarker": 'polygons',
            "id": getNewLayerId()
        }

        //layerData.push(newLayerData);
        layerData.splice(6, 0, newLayerData);

        //const idx = layerData.length - 1;

        setLayerData( [ ...layerData ], populateLayer(6) ); // [...foo] is the js array spread syntax. It is needed here because react needs to see a new object in order to update the state

        advanceDefaultColor();

    };

    const addHitLayer = (hit) => {

        //console.log('addHitLayer');

        let defaultColorscheme = defaultColor;
        let defaultStroke = 'stroke';

        const searchmode = hit._index.split('dhids_loclon_').pop();

        const coords = hit._source.geocode_geocoordinates;
       
        const title = hit._source.geocode_geodescription;

        let target = null;

        if (coords.includes('P')) {

            let these_polygons = [];
            these_polygons.push( ...OSToLeafletPolygons(coords, {}, null, 1, title, null) );
            target = polysCentre(these_polygons);

        } else {

            target = OSToGeoJSONPoint(coords);

        }

        const newLayerData = { 
            "type": "hit",
            "description": hit._source.geocode_geodescription,
            "geotype": hit._source.geocode_placetype,
            "visible": true,
            "opacity": 0.2,
            "stroke": defaultStroke,
            "colorscheme": defaultColorscheme,
            "hit": hit,
            "target": target,
            "searchmode": searchmode,
            "polymarker": 'polygons',
            "id": getNewLayerId()
        }

        //layerData.push(newLayerData);
        layerData.splice(6, 0, newLayerData);

        //const idx = layerData.length - 1;

        setLayerData( [ ...layerData ], populateLayer(6) ); // [...foo] is the js array spread syntax. It is needed here because react needs to see a new object in order to update the state

        setMapPosition(target);

        advanceDefaultColor();

        /*
        //const geoJSON = OSToGeoJSON(hit._source.geocode_geocoordinates);

        const pathOptions = { color: 'red' };
        const popup = ( <><p><b>{ hit._source.geocode_geodescription }</b>.</p></> );

        let polygons = [];

        polygons.push( ...OSToLeafletPolygons(hit._source.geocode_geocoordinates, pathOptions, popup, 1.0) );

        const newLayerData = { "type": "hit", "description": hit._source.geocode_geodescription, "polygons": polygons, "visible": true, "opacity": 0.2, "hit": hit }

        layerData.push(newLayerData);

        setLayerData( [ ...layerData ] ); // [...foo] is the js array spread syntax. It is needed here because react needs to see a new object in order to update the state
        
        //console.log(polygons[0].positions[0]);

        setMapPosition(polysCentre(polygons));
        */

    };

    const setLayerOpts = ( idx, values ) => {

        //console.log('setLayerOpts');
        
        //console.log(values);

        layerData[idx].display = values.layerdisplay;
        layerData[idx].colorscheme = values.colorscheme;
        layerData[idx].stroke = values.stroke;
        layerData[idx].polymarker = values.polymarker;

        // We have to prohibit certain combinations of options
        if (layerData[idx].display === 'heat-blocks-3dp') { layerData[idx].polymarker = 'polygons'; }

        setLayerData( [ ...layerData ], populateLayer(idx) ); // [...foo] is the js array spread syntax. It is needed here because react needs to see a new object in order to update the state

    };

    const setLayerVisibility = ( idx, visibility ) => {

        //console.log('toggleLayerVisibility');      

        layerData[idx].visible = visibility;

        setLayerData( [ ...layerData ] ); // [...foo] is the js array spread syntax. It is needed here because react needs to see a new object in order to update the state

        if (visibility) { setActiveLayer(idx); }

    };

    const handleOpacityChange = (event) => {

        //console.log('handleOpacityChange');      

        layerData[activeLayer].opacity = event.target.value;

        setLayerData( [ ...layerData ] ); // [...foo] is the js array spread syntax. It is needed here because react needs to see a new object in order to update the state

    };

    return (

        <div className={ "map-gis-container" + ( location.pathname.startsWith('/about/') ? ' d-none' : '' ) } >
        {/* To keep the MapGis component perpetually mounted, we only hide it when /about/ is active */}

            <div className="modal fade" id="helpModal" tabIndex="-1" aria-labelledby="helpModalLabel" aria-hidden="true">
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-body">
                    <button type="button" className="btn float-end" data-bs-dismiss="modal" aria-label="Close">
                        <i className="bi bi-x-lg"></i>
                    </button>

                    { helpslug && (

                        <div className="ob-body-text">
                            <Post url={ `${process.env.REACT_APP_WORDPRESS_URL}/posts?slug=` + helpslug } options={ options_help } />
                        </div>

                    ) }

                    <button type="button" className="btn btn-secondary float-end" data-bs-dismiss="modal">Close</button>
                  </div>
                </div>
              </div>
            </div>

            <OffcanvasGis
                layerData={ layerData }
                activeLayer={ activeLayer }
                deleteLayer={ deleteLayer }
                addHitLayer={ addHitLayer }
                addQueryLayer={ addQueryLayer }
                setLayerOpts={ setLayerOpts }
                setLayerVisibility={ setLayerVisibility }
                setMapPosition={ setMapPosition }
                setActiveLayer={ setActiveLayer }
                handleOpacityChange={ handleOpacityChange }
                searchmode={ searchmode }
                handleChangeHelpslug={ handleChangeHelpslug }
                pretty={ pretty }
                resetLayers={ resetLayers }
                dismissDemoLayer={ dismissDemoLayer }
                dumpLayers={ dumpLayers }
            />

            <OffcanvasWelcome
                dismissDemoLayer={ dismissDemoLayer }
                editDemoLayer={ editDemoLayer }
                showOffcanvasWelcome={ showOffcanvasWelcome }
            />

            <Map
                layerData={ layerData }
                position={ position }
                zoom={ zoom }
                mapUpdateView={ mapUpdateView }
                setMapUpdateView={ setMapUpdateView }
                disregardPlace={ disregardPlace }
            />

            { layerData[activeLayer] && layerData[activeLayer].legend && (

                <div className="map-legend">

                    { parse(layerData[activeLayer].legend) }

                    { layerData[activeLayer].demolayer && (

                        <div onClick={ editDemoLayer } data-bs-toggle="offcanvas" data-bs-target="#offcanvasGis" aria-controls="offcanvasGis">
                            <Link className="explore-link" to={ layerData[activeLayer].specialEditUrl }>
                                Click here to explore this data layer
                            </Link>
                        </div>

                    )}

                </div>

            ) }

        </div>
 
    );

};

export default MapGis;
