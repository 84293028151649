
import { useEffect } from "react";

import {
    MapContainer,
    TileLayer,
    //LayersControl,

    Circle,
    CircleMarker,
    Marker,
    //Popup,
    Polygon,
    LayerGroup,
    Popup,
    //  FeatureGroup,
    useMap,
} from "react-leaflet";

import "leaflet/dist/leaflet.css";
//import { useRef, useEffect } from "react";
//import MarkerClusterGroup from "react-leaflet-cluster";

//import icon from "../../data/loclon/markers/marker-icon-2x.png";
import iconShadow from "../../data/loclon/markers/marker-shadow.png";
import L from "leaflet";
//import MarkerLayer from "./MarkerLayer";

//import { HeatmapLayer } from 'react-leaflet-heatmap-layer-v3';

// fix broken icon
//let DefaultIcon = L.icon({
//    iconUrl: icon,
//    shadowUrl: iconShadow,
//});
//L.Marker.prototype.options.icon = DefaultIcon;

const Map = (props) => {

    // console.log(places);
    // const position = [ 51.50811, -0.07577 ]; // Default coordinates, you can change them
    // const zoom = 16; // Default zoom level, you can change it
    
    const layers = props.layerData.map((ldata, layeridx) => {       

        //console.log('Map processing layer ' + idx);
        //console.log(ldata);

        if (ldata.visible) {

            if (ldata.type === 'query' || ldata.type === 'hit') {
                
                if (

                    ldata.display === 'parish' ||
                    ldata.display === 'parish-area' ||
                    ldata.display === 'parish-pop1690s' ||
                    ldata.display === 'parish-pop1695' ||
                    ldata.display === 'parish-pop1740s' ||
                    ldata.display === 'parish-pop1801' ||
                    ldata.display === 'ward' ||
                    ldata.display === 'heat-blocks' ||
                    ldata.display === 'heat-blocks-3dp' ||
                    ldata.display === 'heat-blocks-2dp' ||
                    ldata.display === 'place' ||

                    ldata.display === 'place-insval-avg' ||
                    ldata.display === 'parish-insval-avg' ||
                    ldata.display === 'ward-insval-avg' ||
                    ldata.display === 'heat-blocks-3dp-insval-avg' ||

                    ldata.display === 'place-rent-tax-avg' ||
                    ldata.display === 'parish-rent-tax-avg' ||
                    ldata.display === 'ward-rent-tax-avg' ||
                    ldata.display === 'heat-blocks-3dp-rent-tax-avg' ||

                    ldata.display === 'place-per-tax-avg' ||
                    ldata.display === 'parish-per-tax-avg' ||
                    ldata.display === 'ward-per-tax-avg' ||
                    ldata.display === 'heat-blocks-3dp-per-tax-avg' ||

                    ldata.display === 'place-tax-avg' ||
                    ldata.display === 'parish-tax-avg' ||
                    ldata.display === 'ward-tax-avg' ||
                    ldata.display === 'heat-blocks-3dp-tax-avg' ||

                    ldata.display === 'place-hearths-total-avg' ||
                    ldata.display === 'parish-hearths-total-avg' ||
                    ldata.display === 'ward-hearths-total-avg' ||
                    ldata.display === 'heat-blocks-3dp-hearths-total-avg' ||

                    ldata.display === 'place-deaths-sum' ||
                    ldata.display === 'parish-deaths-sum' ||
                    ldata.display === 'parish-deaths-sum-area' ||
                    ldata.display === 'parish-deaths-sum-pop1690s' ||
                    ldata.display === 'parish-deaths-sum-pop1695' ||
                    ldata.display === 'parish-deaths-sum-pop1740s' ||
                    ldata.display === 'parish-deaths-sum-pop1801' ||
                    ldata.display === 'ward-deaths-sum' ||
                    ldata.display === 'heat-blocks-3dp-deaths-sum' ||

                    ldata.display === 'pineveryhit' ||
                    !ldata.display

                ) {

                    let polygons = [];
                    let circles = [];
                    let markers = [];


                    if (ldata.polygons && ldata.polygons.length > 0) {

                        polygons = ldata.polygons.toReversed().map((polygon, polygonidx) => {

                            let pathOptions = polygon.pathOptions;

                            pathOptions.fillOpacity = ldata.opacity;

                            if (polygon) {

                                return (

                                    <Polygon key={ polygonidx + '-' + ldata.opacity + '-' + ldata.colorscheme } pathOptions={ pathOptions } positions={ polygon.positions } title={ polygon.title }>
                                        <Popup>

                                            { polygon.popup }

                                            { polygon.disregard && (

                                                <div>
                                                    <button className="btn btn-link btn-sm" type="button" onClick={ function() { props.disregardPlace( layeridx, polygon.disregard ); }  }>
                                                        Disregard this place
                                                    </button>
                                                </div>

                                            )}

                                        </Popup>
                                    </Polygon>

                                );

                            }

                            return null;

                        });
                        
                        /*
                        return (

                            <LayerGroup key={ idx + '-group-' + ldata.opacity + '-' + ldata.colorscheme }>
                                { polygons }
                            </LayerGroup>

                        );
                        */

                    }

                    if (ldata.circles && ldata.circles.length > 0) {

                        //console.log('We have circles');
                        //console.log(ldata.circles);

                        circles = ldata.circles.toReversed().map((circle, circleidx) => {

                            //let pathOptions = polygon.pathOptions;
                            //pathOptions.fillOpacity = ldata.opacity;

                            if (circle) {

                                //console.log(idx + '-' + ldata.opacity + '-' + ldata.colorscheme);

                                // [51.505, -0.09]
                                 
                                // fillColor={ circle.color }
                                // fillOpacity={ ldata.opacity }
                                // stroke={ false }

                                let pathOptions = circle.pathOptions;

                                pathOptions.fillOpacity = ldata.opacity;

                                //pathOptions.fillOpacity = ldata.opacity;

                                //if (ldata.colorscheme === 'redopacity') {

                                //    pathOptions.fillOpacity = circle.intensity * ldata.opacity;

                                //}

                                //console.log(pathOptions);

                                if (ldata.polymarker === 'multicircles') {


                                    return (

                                        <>
                                        {/*
                                        <Marker key={ idx + '--' + ldata.opacity } position={ circle.position }/>
                                        */}

                                        <Circle
                                        center={ circle.position }	
                                        key={ circleidx + '-' + ldata.opacity + '-' + ldata.colorscheme }
                                        pathOptions={ pathOptions }
                                        radius={ 24 }
                                        title={ circle.title }
                                        >
                                            <Popup>
                                                { circle.popup }
                                            </Popup>
                                        </Circle>


                                        </>

                                    );

                                } else {

                                    return (

                                        <>
                                        {/*
                                        <Marker key={ idx + '--' + ldata.opacity } position={ circle.position }/>
                                        */}

                                        <CircleMarker
                                        center={ circle.position }	
                                        key={ circleidx + '-' + ldata.opacity + '-' + ldata.colorscheme }
                                        pathOptions={ pathOptions }
                                        radius={ 16 }
                                        title={ circle.title }
                                        >
                                            <Popup>
                                                { circle.popup }
                                            </Popup>
                                        </CircleMarker>


                                        </>

                                    );

                                }

                            }

                            return null;

                        });
                        
                        //console.log(circles);

                    }

                    if (ldata.markers && ldata.markers.length > 0) {

                        //console.log('We have markers');
                        //console.log(ldata.markers);

                        markers = ldata.markers.toReversed().map((marker, markeridx) => {

                            //let pathOptions = polygon.pathOptions;
                            //pathOptions.fillOpacity = ldata.opacity;

                            if (marker) {

                                //console.log(idx + '-' + ldata.opacity + '-' + ldata.colorscheme);

                                // [51.505, -0.09]
                                 
                                // fillColor={ marker.color }
                                // fillOpacity={ ldata.opacity }
                                // stroke={ false }

                                let pathOptions = marker.pathOptions;

                                pathOptions.fillOpacity = ldata.opacity;

                                //pathOptions.fillOpacity = ldata.opacity;

                                //if (ldata.colorscheme === 'redopacity') {

                                //    pathOptions.fillOpacity = marker.intensity * ldata.opacity;

                                //}

                                //console.log(pathOptions);

                                let scale = marker.intensity * 2;

                                if (scale < 0.5) { scale = 0.5; }

                                if (ldata.type === 'hit') { scale = 1.0; }

                                let DefaultIcon = L.icon({
                                    //iconUrl: icon,
                                    iconUrl: '/markers/' + marker.markerfile + '.svg',
                                    shadowUrl: iconShadow,
                                    iconSize: [24 * scale, 40 * scale],
                                    iconAnchor: [12 * scale, 40 * scale],
                                    shadowSize: [40 * scale, 40 * scale],
                                    shadowAnchor: [12 * scale, 40 * scale],
                                });

                                return (

                                    <>
                                    {/*
                                    <Marker key={ idx + '--' + ldata.opacity } position={ marker.position }/>
                                    */}

                                    <Marker
                                    position={ marker.position }	
                                    key={ markeridx + '-' + ldata.opacity + '-' + ldata.colorscheme }
                                    icon={ DefaultIcon }
                                    title={ marker.title }
                                    >
                                        <Popup>
                                            { marker.popup }

                                            { marker.disregard && (

                                                <div>
                                                    <button className="btn btn-link btn-sm" type="button" onClick={ function() { props.disregardPlace( layeridx, marker.disregard ); }  }>
                                                        Disregard this place
                                                    </button>
                                                </div>

                                            )}

                                        </Popup>
                                    </Marker>


                                    </>

                                );

                            }

                            return null;

                        });
                        
                        //console.log(markers);

                    }

                    //console.log(polygons);
                    //console.log(circles);
                    //console.log(markers);

                    const groupkey = layeridx + '-group-' + ldata.colorscheme + '-' + ldata.opacity + '-';
                    //console.log(groupkey);

                    return (

                        <LayerGroup key={ groupkey }>
                            { polygons }
                            { circles }
                            { markers }
                        </LayerGroup>

                    );

                } else if (ldata.display === 'heat') {

                    /*
                    if (ldata.heatpoints) {

                        
                        let points = [];

                        ldata.polygons.forEach((polygon, idx) => {

                            if (polygon) {

                                polygon.positions.forEach((position, idx) => {

                                    //console.log(position);

                                    points.push({ lat: position[0], lon: position[1], i: 1 });

                                });

                            }


                        });

                        //console.log(points);
                        
                        
                        return (

                            <LayerGroup key={ idx + '-group-' + ldata.opacity + '-' + ldata.colorscheme }>

                                
                                <HeatmapLayer
					            points={ ldata.heatpoints }
					            longitudeExtractor={ m => m.lon }
					            latitudeExtractor={ m => m.lat }
					            intensityExtractor={ m => m.i }
                                /*
                                maxOpacity={0.1}
                                gradient={ {
                                    0.4: 'blue',
                                    //0.6: 'aqua',
                                    0.7: 'cyan',
                                    //0.8: 'lime',
                                    1.0: 'aqua'
                                } }
                                />
                                

                            </LayerGroup>

                        );

                    }
                    */

                }
          
            } else if (ldata.type === 'tile') {

                return (

                    <TileLayer key={ layeridx + '-' + ldata.opacity + '-' + ldata.colorscheme }
                    attribution={ ldata.attribution }
                    url={ ldata.url }
                    opacity={ ldata.opacity }
                    />

                );

            }

        }

        return null;

    });

    function ChangeView(props) {

        const map = useMap();

//        map.setView(center, zoom);
//        //map.setView(center);
//        props.setMapUpdateView(false);

        useEffect(() => {

            //console.log('ChangeView useEffect ran');

            if (props.mapUpdateView) {

                //console.log('mapUpdateView was true');
                //console.log('setting the view');
                map.setView(props.center, props.zoom);

            } else {

                //console.log('mapUpdateView was false');
                //console.log('refusing to set the view');

            }
            
        }, [props.center, props.zoom, props.mapUpdateView]);

        return null;

    }

    const ResizeMap = () => {

        const map = useMap();
        map._onResize();
        return null;

    };

    return (


        <MapContainer
        center={ props.position }
        zoom={ props.zoom }
        minZoom={ 13 }
        maxZoom={ 18 }
        style={ { height: "100%", width: "100%" } }
        >

            <ChangeView center={ props.position } zoom={ props.zoom } mapUpdateView={ props.mapUpdateView } />

            {/*
            { props.mapUpdateView && (

                <ChangeView center={ props.position } zoom={ props.zoom } />

            ) }
            */}

            { layers }

            <ResizeMap />

        </MapContainer>

    );
};

export default Map;
