
const population = [  { "placename" : "All Hallows Barking" , "area" : "48252" , "pop1690s" : "2136" , "den1690s" : "44268" , "pop1695" : "2136" , "den1695" : "44268" , "pop1740s" : "2328" , "den1740s" : "48247" , "pop1801" : "2087" , "den1801" : "43252" }  , 
 { "placename" : "All Hallows Bread Street" , "area" : "10416" , "pop1690s" : "516" , "den1690s" : "49539" , "pop1695" : "516" , "den1695" : "49539" , "pop1740s" : "480" , "den1740s" : "46083" , "pop1801" : "430" , "den1801" : "41283" }  , 
 { "placename" : "All Hallows Honey Lane" , "area" : "3933" , "pop1690s" : "197" , "den1690s" : "50089" , "pop1695" : "197" , "den1695" : "50089" , "pop1740s" : "195" , "den1740s" : "49580" , "pop1801" : "175" , "den1801" : "44495" }  , 
 { "placename" : "St Alphage" , "area" : "16702" , "pop1690s" : "810" , "den1690s" : "48497" , "pop1695" : "810" , "den1695" : "48497" , "pop1740s" : "909" , "den1740s" : "54425" , "pop1801" : "1008" , "den1801" : "60352" }  , 
 { "placename" : "All Hallows London Wall" , "area" : "34241" , "pop1690s" : "1552" , "den1690s" : "45326" , "pop1695" : "1552" , "den1695" : "45326" , "pop1740s" : "1731" , "den1740s" : "50553" , "pop1801" : "1552" , "den1801" : "45326" }  , 
 { "placename" : "All Hallows Staining" , "area" : "16645" , "pop1690s" : "869" , "den1690s" : "52208" , "pop1695" : "869" , "den1695" : "52208" , "pop1740s" : "804" , "den1740s" : "48303" , "pop1801" : "721" , "den1801" : "43316" }  , 
 { "placename" : "All Hallows The Great" , "area" : "34436" , "pop1690s" : "1173" , "den1690s" : "34077" , "pop1695" : "1121" , "den1695" : "34077" , "pop1740s" : "1131" , "den1740s" : "32851" , "pop1801" : "1014" , "den1801" : "29446" }  , 
 { "placename" : "All Hallows The Less" , "area" : "14700" , "pop1690s" : "390" , "den1690s" : "26515" , "pop1695" : "448" , "den1695" : "26515" , "pop1740s" : "398" , "den1740s" : "27108" , "pop1801" : "244" , "den1801" : "16599" }  , 
 { "placename" : "Allhallows Lombard Street" , "area" : "11582" , "pop1690s" : "648" , "den1690s" : "55949" , "pop1695" : "648" , "den1695" : "55949" , "pop1740s" : "757" , "den1740s" : "65360" , "pop1801" : "679" , "den1801" : "58625" }  , 
 { "placename" : "Christ Church" , "area" : "49322" , "pop1690s" : "2320" , "den1690s" : "47035" , "pop1695" : "2296" , "den1695" : "47035" , "pop1740s" : "2546" , "den1740s" : "51617" , "pop1801" : "2818" , "den1801" : "57135" }  , 
 { "placename" : "Holy Trinity the Less" , "area" : "6268" , "pop1690s" : "333" , "den1690s" : "53127" , "pop1695" : null , "den1695" : "53127" , "pop1740s" : "299" , "den1740s" : "47703" , "pop1801" : "232" , "den1801" : "37013" }  , 
 { "placename" : "Precinct of Whitefriars" , "area" : "34564" , "pop1690s" : "1398" , "den1690s" : "40447" , "pop1695" : "1486" , "den1695" : "40447" , "pop1740s" : "873" , "den1740s" : "25257" , "pop1801" : "783" , "den1801" : "22654" }  , 
 { "placename" : "St Swithin" , "area" : "12353" , "pop1690s" : "521" , "den1690s" : "42183" , "pop1695" : null , "den1695" : "42183" , "pop1740s" : "529" , "den1740s" : "42824" , "pop1801" : "474" , "den1801" : "38371" }  , 
 { "placename" : "St Alban" , "area" : "13693" , "pop1690s" : "675" , "den1690s" : "49295" , "pop1695" : "675" , "den1695" : "49295" , "pop1740s" : "620" , "den1740s" : "45268" , "pop1801" : "682" , "den1801" : "49806" }  , 
 { "placename" : "St Andrew by the Wardrobe" , "area" : "35834" , "pop1690s" : "1065" , "den1690s" : "29723" , "pop1695" : "509" , "den1695" : "29723" , "pop1740s" : "1004" , "den1740s" : "28018" , "pop1801" : "900" , "den1801" : "25116" }  , 
 { "placename" : "St Andrew Hubbar" , "area" : "8371" , "pop1690s" : "463" , "den1690s" : "55310" , "pop1695" : "463" , "den1695" : "55310" , "pop1740s" : "419" , "den1740s" : "50054" , "pop1801" : "376" , "den1801" : "44917" }  , 
 { "placename" : "St Andrew Undershaft" , "area" : "37664" , "pop1690s" : "1227" , "den1690s" : "32578" , "pop1695" : "1227" , "den1695" : "32578" , "pop1740s" : "1458" , "den1740s" : "38711" , "pop1801" : "1307" , "den1801" : "34702" }  , 
 { "placename" : "St Anne and St Agnes" , "area" : "10935" , "pop1690s" : "857" , "den1690s" : "78372" , "pop1695" : "857" , "den1695" : "78372" , "pop1740s" : "925" , "den1740s" : "84624" , "pop1801" : "952" , "den1801" : "87060" }  , 
 { "placename" : "St Anne Blackfriars" , "area" : "49156" , "pop1690s" : "2789" , "den1690s" : "56735" , "pop1695" : "2833" , "den1695" : "56735" , "pop1740s" : "2592" , "den1740s" : "52730" , "pop1801" : "3071" , "den1801" : "62475" }  , 
 { "placename" : "St Antholin" , "area" : "10278" , "pop1690s" : "455" , "den1690s" : "44269" , "pop1695" : "455" , "den1695" : "44269" , "pop1740s" : "405" , "den1740s" : "39405" , "pop1801" : "363" , "den1801" : "35318" }  , 
 { "placename" : "St Augustine" , "area" : "7171" , "pop1690s" : "510" , "den1690s" : "71120" , "pop1695" : "510" , "den1695" : "71120" , "pop1740s" : "371" , "den1740s" : "51736" , "pop1801" : "333" , "den1801" : "46437" }  , 
 { "placename" : "St Batholomew by the Exchange" , "area" : "16607" , "pop1690s" : "806" , "den1690s" : "48534" , "pop1695" : "806" , "den1695" : "48534" , "pop1740s" : "625" , "den1740s" : "37635" , "pop1801" : "560" , "den1801" : "33721" }  , 
 { "placename" : "St Benet Fink" , "area" : "11759" , "pop1690s" : "610" , "den1690s" : "51875" , "pop1695" : "610" , "den1695" : "51875" , "pop1740s" : "601" , "den1740s" : "51110" , "pop1801" : "539" , "den1801" : "45837" }  , 
 { "placename" : "St Benet Gracechurch" , "area" : "7771" , "pop1690s" : "398" , "den1690s" : "51216" , "pop1695" : "398" , "den1695" : "51216" , "pop1740s" : "414" , "den1740s" : "53275" , "pop1801" : "429" , "den1801" : "55205" }  , 
 { "placename" : "St Benet Paul's Wharf" , "area" : "29241" , "pop1690s" : "894" , "den1690s" : "30580" , "pop1695" : "558" , "den1695" : "30580" , "pop1740s" : "692" , "den1740s" : "23665" , "pop1801" : "620" , "den1801" : "21203" }  , 
 { "placename" : "St Benet Sherehog" , "area" : "4310" , "pop1690s" : "230" , "den1690s" : "53364" , "pop1695" : "230" , "den1695" : "53364" , "pop1740s" : "207" , "den1740s" : "48028" , "pop1801" : "196" , "den1801" : "45476" }  , 
 { "placename" : "St Botolph Billingsgate" , "area" : "12325" , "pop1690s" : "227" , "den1690s" : "18434" , "pop1695" : "354" , "den1695" : "18434" , "pop1740s" : "164" , "den1740s" : "13292" , "pop1801" : "176" , "den1801" : "14280" }  , 
 { "placename" : "St Christopher le Stocks" , "area" : "11327" , "pop1690s" : "304" , "den1690s" : "26866" , "pop1695" : "584" , "den1695" : "26866" , "pop1740s" : "246" , "den1740s" : "21694" , "pop1801" : "133" , "den1801" : "11742" }  , 
 { "placename" : "St Clement Eastcheap" , "area" : "7343" , "pop1690s" : "369" , "den1690s" : "50252" , "pop1695" : "369" , "den1695" : "50252" , "pop1740s" : "393" , "den1740s" : "53520" , "pop1801" : "352" , "den1801" : "47937" }  , 
 { "placename" : "St Dionis Backchurch" , "area" : "18989" , "pop1690s" : "953" , "den1690s" : "50187" , "pop1695" : "953" , "den1695" : "50187" , "pop1740s" : "968" , "den1740s" : "50977" , "pop1801" : "868" , "den1801" : "45711" }  , 
 { "placename" : "St Dunstan in the East" , "area" : "51116" , "pop1690s" : "1939" , "den1690s" : "37933" , "pop1695" : "1939" , "den1695" : "37933" , "pop1740s" : "1799" , "den1740s" : "35194" , "pop1801" : "1613" , "den1801" : "31556" }  , 
 { "placename" : "St Edmund the King" , "area" : "9674" , "pop1690s" : "675" , "den1690s" : "69808" , "pop1695" : "624" , "den1695" : "69808" , "pop1740s" : "532" , "den1740s" : "54993" , "pop1801" : "477" , "den1801" : "49307" }  , 
 { "placename" : "St Ethelburga" , "area" : "13440" , "pop1690s" : "655" , "den1690s" : "48735" , "pop1695" : "655" , "den1695" : "48735" , "pop1740s" : "604" , "den1740s" : "44967" , "pop1801" : "599" , "den1801" : "44568" }  , 
 { "placename" : "St Faith under St Paul's" , "area" : "22716" , "pop1690s" : "1292" , "den1690s" : "56876" , "pop1695" : "1292" , "den1695" : "56876" , "pop1740s" : "1075" , "den1740s" : "47323" , "pop1801" : "964" , "den1801" : "42437" }  , 
 { "placename" : "St Gabriel Fenchurch" , "area" : "11279" , "pop1690s" : "572" , "den1690s" : "50714" , "pop1695" : "572" , "den1695" : "50714" , "pop1740s" : "568" , "den1740s" : "50359" , "pop1801" : "509" , "den1801" : "45128" }  , 
 { "placename" : "St George Botolph Lane" , "area" : "5169" , "pop1690s" : "227" , "den1690s" : "43953" , "pop1695" : "306" , "den1695" : "43953" , "pop1740s" : "246" , "den1740s" : "47539" , "pop1801" : "254" , "den1801" : "49139" }  , 
 { "placename" : "St Gregory by St Paul's" , "area" : "46197" , "pop1690s" : "1584" , "den1690s" : "34290" , "pop1695" : "1653" , "den1695" : "34290" , "pop1740s" : "1589" , "den1740s" : "34407" , "pop1801" : "1634" , "den1801" : "35370" }  , 
 { "placename" : "St Helen" , "area" : "29055" , "pop1690s" : "868" , "den1690s" : "29874" , "pop1695" : "868" , "den1695" : "29874" , "pop1740s" : "706" , "den1740s" : "24306" , "pop1801" : "635" , "den1801" : "21855" }  , 
 { "placename" : "St James Duke Street" , "area" : "12939" , "pop1690s" : "931" , "den1690s" : "71953" , "pop1695" : "931" , "den1695" : "71953" , "pop1740s" : "950" , "den1740s" : "73421" , "pop1801" : "852" , "den1801" : "65847" }  , 
 { "placename" : "St James Garlick" , "area" : "16144" , "pop1690s" : "819" , "den1690s" : "50740" , "pop1695" : "884" , "den1695" : "50740" , "pop1740s" : "653" , "den1740s" : "40448" , "pop1801" : "585" , "den1801" : "36236" }  , 
 { "placename" : "St John the Baptist" , "area" : "7577" , "pop1690s" : "448" , "den1690s" : "59144" , "pop1695" : "435" , "den1695" : "59144" , "pop1740s" : "525" , "den1740s" : "69245" , "pop1801" : "412" , "den1801" : "54375" }  , 
 { "placename" : "St John the Evangelist" , "area" : "3293" , "pop1690s" : "153" , "den1690s" : "46462" , "pop1695" : "153" , "den1695" : "46462" , "pop1740s" : "189" , "den1740s" : "57394" , "pop1801" : "225" , "den1801" : "68327" }  , 
 { "placename" : "St John Zachary" , "area" : "8821" , "pop1690s" : "479" , "den1690s" : "54302" , "pop1695" : "479" , "den1695" : "54302" , "pop1740s" : "493" , "den1740s" : "55889" , "pop1801" : "507" , "den1801" : "57476" }  , 
 { "placename" : "St Katherine Coleman Street" , "area" : "25067" , "pop1690s" : "1021" , "den1690s" : "40744" , "pop1695" : "1142" , "den1695" : "40744" , "pop1740s" : "874" , "den1740s" : "34884" , "pop1801" : "732" , "den1801" : "29202" }  , 
 { "placename" : "St Katherine Creechurch" , "area" : "37324" , "pop1690s" : "1463" , "den1690s" : "39203" , "pop1695" : "1630" , "den1695" : "39203" , "pop1740s" : "1636" , "den1740s" : "43832" , "pop1801" : "1727" , "den1801" : "46270" }  , 
 { "placename" : "St Laurence Pountney" , "area" : "12170" , "pop1690s" : "426" , "den1690s" : "35004" , "pop1695" : "426" , "den1695" : "35004" , "pop1740s" : "396" , "den1740s" : "32539" , "pop1801" : "355" , "den1801" : "29170" }  , 
 { "placename" : "St Lawrence Jewry" , "area" : "23063" , "pop1690s" : "1107" , "den1690s" : "47990" , "pop1695" : "1082" , "den1695" : "47990" , "pop1740s" : "892" , "den1740s" : "38677" , "pop1801" : "800" , "den1801" : "34688" }  , 
 { "placename" : "St Leonard Eastc" , "area" : "5681" , "pop1690s" : "363" , "den1690s" : "63897" , "pop1695" : "363" , "den1695" : "63897" , "pop1740s" : "339" , "den1740s" : "59673" , "pop1801" : "304" , "den1801" : "53512" }  , 
 { "placename" : "St Leonard Foster Lane" , "area" : "10142" , "pop1690s" : "1002" , "den1690s" : "98797" , "pop1695" : "1002" , "den1695" : "98797" , "pop1740s" : "1009" , "den1740s" : "99487" , "pop1801" : "905" , "den1801" : "89233" }  , 
 { "placename" : "St Magnus the Martyr" , "area" : "14974" , "pop1690s" : "838" , "den1690s" : "55957" , "pop1695" : "757" , "den1695" : "55957" , "pop1740s" : "615" , "den1740s" : "41100" , "pop1801" : "289" , "den1801" : "38600" }  , 
 { "placename" : "St Margaret Fish" , "area" : "8186" , "pop1690s" : "461" , "den1690s" : "56316" , "pop1695" : "461" , "den1695" : "56316" , "pop1740s" : "407" , "den1740s" : "49719" , "pop1801" : "365" , "den1801" : "44588" }  , 
 { "placename" : "St Margaret Lothbury" , "area" : "15486" , "pop1690s" : "968" , "den1690s" : "62508" , "pop1695" : "968" , "den1695" : "62508" , "pop1740s" : "635" , "den1740s" : "41005" , "pop1801" : "569" , "den1801" : "36743" }  , 
 { "placename" : "St Margaret Moses" , "area" : "6343" , "pop1690s" : "279" , "den1690s" : "43985" , "pop1695" : "279" , "den1695" : "43985" , "pop1740s" : "296" , "den1740s" : "46666" , "pop1801" : "265" , "den1801" : "41778" }  , 
 { "placename" : "St Margaret Pattens" , "area" : "6474" , "pop1690s" : "336" , "den1690s" : "51835" , "pop1695" : "313" , "den1695" : "51835" , "pop1740s" : "288" , "den1740s" : "44453" , "pop1801" : "221" , "den1801" : "34137" }  , 
 { "placename" : "St Martin Ludgate" , "area" : "19515" , "pop1690s" : "1134" , "den1690s" : "58109" , "pop1695" : "1134" , "den1695" : "58109" , "pop1740s" : "1182" , "den1740s" : "60569" , "pop1801" : "1229" , "den1801" : "62977" }  , 
 { "placename" : "St Martin Orgar" , "area" : "11439" , "pop1690s" : "520" , "den1690s" : "45459" , "pop1695" : "520" , "den1695" : "45459" , "pop1740s" : "438" , "den1740s" : "38290" , "pop1801" : "393" , "den1801" : "34356" }  , 
 { "placename" : "St Martin Outwich" , "area" : "12744" , "pop1690s" : "434" , "den1690s" : "34055" , "pop1695" : "434" , "den1695" : "34055" , "pop1740s" : "364" , "den1740s" : "28562" , "pop1801" : "326" , "den1801" : "25581" }  , 
 { "placename" : "St Martin Pomary" , "area" : "4764" , "pop1690s" : "255" , "den1690s" : "53526" , "pop1695" : "255" , "den1695" : "53526" , "pop1740s" : "210" , "den1740s" : "44146" , "pop1801" : "192" , "den1801" : "40302" }  , 
 { "placename" : "St Martin Vintry" , "area" : "21148" , "pop1690s" : "794" , "den1690s" : "37551" , "pop1695" : "854" , "den1695" : "37551" , "pop1740s" : "755" , "den1740s" : "35696" , "pop1801" : "543" , "den1801" : "25676" }  , 
 { "placename" : "St Mary Abchurch" , "area" : "10376" , "pop1690s" : "643" , "den1690s" : "61970" , "pop1695" : "643" , "den1695" : "61970" , "pop1740s" : "612" , "den1740s" : "58982" , "pop1801" : "549" , "den1801" : "52911" }  , 
 { "placename" : "St Mary Aldermanbury" , "area" : "18060" , "pop1690s" : "774" , "den1690s" : "42857" , "pop1695" : "774" , "den1695" : "42857" , "pop1740s" : "793" , "den1740s" : "43909" , "pop1801" : "812" , "den1801" : "44961" }  , 
 { "placename" : "St Mary Aldermary" , "area" : "9675" , "pop1690s" : "615" , "den1690s" : "63554" , "pop1695" : "564" , "den1695" : "63554" , "pop1740s" : "505" , "den1740s" : "52170" , "pop1801" : "562" , "den1801" : "58088" }  , 
 { "placename" : "St Mary at Hill" , "area" : "18682" , "pop1690s" : "795" , "den1690s" : "42554" , "pop1695" : "795" , "den1695" : "42554" , "pop1740s" : "748" , "den1740s" : "40052" , "pop1801" : "762" , "den1801" : "40788" }  , 
 { "placename" : "St Mary Bothaw" , "area" : "7508" , "pop1690s" : "321" , "den1690s" : "42754" , "pop1695" : "321" , "den1695" : "42754" , "pop1740s" : "263" , "den1740s" : "35029" , "pop1801" : "236" , "den1801" : "31433" }  , 
 { "placename" : "St Mary Colechurch" , "area" : "6239" , "pop1690s" : "357" , "den1690s" : "57221" , "pop1695" : "357" , "den1695" : "57221" , "pop1740s" : "339" , "den1740s" : "54336" , "pop1801" : "304" , "den1801" : "48726" }  , 
 { "placename" : "St Mary le Bow" , "area" : "11092" , "pop1690s" : "679" , "den1690s" : "61215" , "pop1695" : "679" , "den1695" : "61215" , "pop1740s" : "522" , "den1740s" : "47061" , "pop1801" : "468" , "den1801" : "42193" }  , 
 { "placename" : "St Mary Magdalen Bermondsey Old Fish Street" , "area" : "9710" , "pop1690s" : "642" , "den1690s" : "66115" , "pop1695" : "461" , "den1695" : "66115" , "pop1740s" : "655" , "den1740s" : "67485" , "pop1801" : "521" , "den1801" : "53656" }  , 
 { "placename" : "St Mary Magdalen Milk Street" , "area" : "6943" , "pop1690s" : "370" , "den1690s" : "53291" , "pop1695" : "500" , "den1695" : "53291" , "pop1740s" : "231" , "den1740s" : "33271" , "pop1801" : "207" , "den1801" : "29814" }  , 
 { "placename" : "St Mary Mounthaw" , "area" : "3811" , "pop1690s" : "251" , "den1690s" : "65862" , "pop1695" : "251" , "den1695" : "65862" , "pop1740s" : "308" , "den1740s" : "80819" , "pop1801" : "365" , "den1801" : "95775" }  , 
 { "placename" : "St Mary Somerset" , "area" : "18428" , "pop1690s" : "671" , "den1690s" : "36421" , "pop1695" : "640" , "den1695" : "36421" , "pop1740s" : "655" , "den1740s" : "35559" , "pop1801" : "459" , "den1801" : "24908" }  , 
 { "placename" : "St Mary Staining" , "area" : "5169" , "pop1690s" : "340" , "den1690s" : "65728" , "pop1695" : "275" , "den1695" : "65728" , "pop1740s" : "341" , "den1740s" : "65955" , "pop1801" : "239" , "den1801" : "46237" }  , 
 { "placename" : "St Mary Woolchurch" , "area" : "9416" , "pop1690s" : "482" , "den1690s" : "51189" , "pop1695" : "487" , "den1695" : "51189" , "pop1740s" : "301" , "den1740s" : "31967" , "pop1801" : "270" , "den1801" : "28675" }  , 
 { "placename" : "St Mary Woolnoth" , "area" : "10428" , "pop1690s" : "690" , "den1690s" : "66160" , "pop1695" : "586" , "den1695" : "66160" , "pop1740s" : "615" , "den1740s" : "58976" , "pop1801" : "551" , "den1801" : "52839" }  , 
 { "placename" : "St Matthew Friday Street" , "area" : "5908" , "pop1690s" : "319" , "den1690s" : "53978" , "pop1695" : "288" , "den1695" : "53978" , "pop1740s" : "233" , "den1740s" : "39438" , "pop1801" : "209" , "den1801" : "35376" }  , 
 { "placename" : "St Michael Bassishaw" , "area" : "23251" , "pop1690s" : "908" , "den1690s" : "39052" , "pop1695" : "908" , "den1695" : "39052" , "pop1740s" : "833" , "den1740s" : "35826" , "pop1801" : "747" , "den1801" : "32128" }  , 
 { "placename" : "St Michael Cornhill" , "area" : "14851" , "pop1690s" : "824" , "den1690s" : "55484" , "pop1695" : "824" , "den1695" : "55484" , "pop1740s" : "771" , "den1740s" : "51916" , "pop1801" : "691" , "den1801" : "46529" }  , 
 { "placename" : "St Michael Crooked Lane" , "area" : "13023" , "pop1690s" : "634" , "den1690s" : "48655" , "pop1695" : "627" , "den1695" : "48655" , "pop1740s" : "624" , "den1740s" : "47915" , "pop1801" : "618" , "den1801" : "47455" }  , 
 { "placename" : "St Michael Le Querne" , "area" : "6579" , "pop1690s" : "441" , "den1690s" : "67031" , "pop1695" : "441" , "den1695" : "67031" , "pop1740s" : "435" , "den1740s" : "66119" , "pop1801" : "390" , "den1801" : "59280" }  , 
 { "placename" : "St Michael Paternoster Royal" , "area" : "8594" , "pop1690s" : "384" , "den1690s" : "44626" , "pop1695" : "351" , "den1695" : "44626" , "pop1740s" : "342" , "den1740s" : "39795" , "pop1801" : "307" , "den1801" : "35723" }  , 
 { "placename" : "St Michael Queenhithe" , "area" : "16016" , "pop1690s" : "840" , "den1690s" : "52447" , "pop1695" : "709" , "den1695" : "52447" , "pop1740s" : "673" , "den1740s" : "42020" , "pop1801" : "595" , "den1801" : "37150" }  , 
 { "placename" : "St Michael Wood Street" , "area" : "8131" , "pop1690s" : "499" , "den1690s" : "61370" , "pop1695" : "499" , "den1695" : "61370" , "pop1740s" : "537" , "den1740s" : "66044" , "pop1801" : "574" , "den1801" : "70594" }  , 
 { "placename" : "St Mildred Bread Street" , "area" : "6070" , "pop1690s" : "321" , "den1690s" : "52881" , "pop1695" : "339" , "den1695" : "52881" , "pop1740s" : "313" , "den1740s" : "51565" , "pop1801" : "281" , "den1801" : "46293" }  , 
 { "placename" : "St Mildred Poultry" , "area" : "9886" , "pop1690s" : "560" , "den1690s" : "56646" , "pop1695" : "560" , "den1695" : "56646" , "pop1740s" : "562" , "den1740s" : "56848" , "pop1801" : "504" , "den1801" : "50981" }  , 
 { "placename" : "St Nicholas Acon" , "area" : "6190" , "pop1690s" : "491" , "den1690s" : "79321" , "pop1695" : null , "den1695" : "79321" , "pop1740s" : "307" , "den1740s" : "49596" , "pop1801" : "275" , "den1801" : "44426" }  , 
 { "placename" : "St Nicholas Cole Abbey" , "area" : "6449" , "pop1690s" : "379" , "den1690s" : "58823" , "pop1695" : null , "den1695" : "58823" , "pop1740s" : "235" , "den1740s" : "36387" , "pop1801" : "257" , "den1801" : "39851" }  , 
 { "placename" : "St Nicholas Olave" , "area" : "5683" , "pop1690s" : "381" , "den1690s" : "67042" , "pop1695" : null , "den1695" : "67042" , "pop1740s" : "361" , "den1740s" : "63523" , "pop1801" : "324" , "den1801" : "57012" }  , 
 { "placename" : "St Olave Hart Street" , "area" : "42682" , "pop1690s" : "1048" , "den1690s" : "24564" , "pop1695" : null , "den1695" : "24564" , "pop1740s" : "1132" , "den1740s" : "26522" , "pop1801" : "1216" , "den1801" : "28490" }  , 
 { "placename" : "St Olave Old Jewry" , "area" : "9867" , "pop1690s" : "538" , "den1690s" : "54525" , "pop1695" : null , "den1695" : "54525" , "pop1740s" : "336" , "den1740s" : "34053" , "pop1801" : "301" , "den1801" : "30506" }  , 
 { "placename" : "St Olave Silver Street" , "area" : "13317" , "pop1690s" : "629" , "den1690s" : "47233" , "pop1695" : null , "den1695" : "47233" , "pop1740s" : "854" , "den1740s" : "64129" , "pop1801" : "1078" , "den1801" : "80949" }  , 
 { "placename" : "St Pancras Soper Lane" , "area" : "4718" , "pop1690s" : "388" , "den1690s" : "82238" , "pop1695" : null , "den1695" : "82238" , "pop1740s" : "242" , "den1740s" : "51293" , "pop1801" : "217" , "den1801" : "45994" }  , 
 { "placename" : "St Peter Cornhill" , "area" : "24346" , "pop1690s" : "1249" , "den1690s" : "51302" , "pop1695" : null , "den1695" : "51302" , "pop1740s" : "1119" , "den1740s" : "45962" , "pop1801" : "1003" , "den1801" : "41198" }  , 
 { "placename" : "St Peter le Poor" , "area" : "38022" , "pop1690s" : "1064" , "den1690s" : "27984" , "pop1695" : null , "den1695" : "27984" , "pop1740s" : "967" , "den1740s" : "25433" , "pop1801" : "867" , "den1801" : "22803" }  , 
 { "placename" : "St Peter Paul's Wharf" , "area" : "12151" , "pop1690s" : "471" , "den1690s" : "38762" , "pop1695" : null , "den1695" : "38762" , "pop1740s" : "394" , "den1740s" : "32425" , "pop1801" : "353" , "den1801" : "29051" }  , 
 { "placename" : "St Peter Westcheap" , "area" : "6452" , "pop1690s" : "433" , "den1690s" : "67111" , "pop1695" : null , "den1695" : "67111" , "pop1740s" : "374" , "den1740s" : "57967" , "pop1801" : "335" , "den1801" : "51922" }  , 
 { "placename" : "St Stephen Coleman Street" , "area" : "109321" , "pop1690s" : "3654" , "den1690s" : "33425" , "pop1695" : null , "den1695" : "33425" , "pop1740s" : "3597" , "den1740s" : "32903" , "pop1801" : "3225" , "den1801" : "29500" }  , 
 { "placename" : "St Stephen Walbr" , "area" : "11411" , "pop1690s" : "427" , "den1690s" : "37445" , "pop1695" : null , "den1695" : "37445" , "pop1740s" : "379" , "den1740s" : "33214" , "pop1801" : "340" , "den1801" : "29796" }  , 
 { "placename" : "St Thomas the Apostle" , "area" : "9595" , "pop1690s" : "694" , "den1690s" : "72329" , "pop1695" : null , "den1695" : "72329" , "pop1740s" : "620" , "den1740s" : "64617" , "pop1801" : "556" , "den1801" : "57947" }  , 
 { "placename" : "St Vedast Foster" , "area" : "10156" , "pop1690s" : "661" , "den1690s" : "65059" , "pop1695" : null , "den1695" : "65059" , "pop1740s" : "600" , "den1740s" : "59072" , "pop1801" : "423" , "den1801" : "41650" }  , 
 { "placename" : "Charter House" , "area" : "40775" , "pop1690s" : "202" , "den1690s" : "4954" , "pop1695" : null , "den1695" : "4954" , "pop1740s" : "263" , "den1740s" : "6450" , "pop1801" : "249" , "den1801" : "6107" }  , 
 { "placename" : "Holy Trinity Minories" , "area" : "16230" , "pop1690s" : "411" , "den1690s" : "25300" , "pop1695" : null , "den1695" : "25300" , "pop1740s" : "680" , "den1740s" : "41875" , "pop1801" : "644" , "den1801" : "39680" }  , 
 { "placename" : "Liberty of Glasshouse Yard" , "area" : "22942" , "pop1690s" : "889" , "den1690s" : "38750" , "pop1695" : null , "den1695" : "38750" , "pop1740s" : "1158" , "den1740s" : "50475" , "pop1801" : "1221" , "den1801" : "53221" }  , 
 { "placename" : "Precinct of Bridewelll" , "area" : "24516" , "pop1690s" : "598" , "den1690s" : "24401" , "pop1695" : "662" , "den1695" : "24401" , "pop1740s" : "456" , "den1740s" : "18600" , "pop1801" : "453" , "den1801" : "18478" }  , 
 { "placename" : "St Andrew Holborn" , "area" : "476827" , "pop1690s" : "15097" , "den1690s" : "31661" , "pop1695" : "4094" , "den1695" : "31661" , "pop1740s" : "23316" , "den1740s" : "48897" , "pop1801" : "29224" , "den1801" : "61288" }  , 
 { "placename" : "St Bartholomew the Great" , "area" : "36140" , "pop1690s" : "1511" , "den1690s" : "41814" , "pop1695" : "1557" , "den1695" : "41814" , "pop1740s" : "1842" , "den1740s" : "50965" , "pop1801" : "2645" , "den1801" : "73188" }  , 
 { "placename" : "St Bartholomew the Less" , "area" : "16712" , "pop1690s" : "644" , "den1690s" : "38539" , "pop1695" : "551" , "den1695" : "38539" , "pop1740s" : "474" , "den1740s" : "28363" , "pop1801" : "471" , "den1801" : "28183" }  , 
 { "placename" : "St Botolph Aldersgate" , "area" : "79832" , "pop1690s" : "2992" , "den1690s" : "37479" , "pop1695" : "3334" , "den1695" : "37479" , "pop1740s" : "3895" , "den1740s" : "48790" , "pop1801" : "4161" , "den1801" : "52122" }  , 
 { "placename" : "St Botolph Aldgate" , "area" : "298021" , "pop1690s" : "12810" , "den1690s" : "42985" , "pop1695" : "7758" , "den1695" : "42985" , "pop1740s" : "17484" , "den1740s" : "58669" , "pop1801" : "14842" , "den1801" : "49802" }  , 
 { "placename" : "St Botolph Bishopsgate" , "area" : "241497" , "pop1690s" : "10824" , "den1690s" : "44820" , "pop1695" : "9618" , "den1695" : "44820" , "pop1740s" : "11219" , "den1740s" : "46458" , "pop1801" : "12066" , "den1801" : "49963" }  , 
 { "placename" : "St Bride" , "area" : "116990" , "pop1690s" : "6812" , "den1690s" : "58224" , "pop1695" : "5067" , "den1695" : "58224" , "pop1740s" : "5650" , "den1740s" : "48291" , "pop1801" : "7078" , "den1801" : "60501" }  , 
 { "placename" : "St Dunstan in the West" , "area" : "54582" , "pop1690s" : "4567" , "den1690s" : "83669" , "pop1695" : "2617" , "den1695" : "83669" , "pop1740s" : "3867" , "den1740s" : "70856" , "pop1801" : "3001" , "den1801" : "54981" }  , 
 { "placename" : "St George Southwark" , "area" : "1153194" , "pop1690s" : "7329" , "den1690s" : "6355" , "pop1695" : null , "den1695" : "6355" , "pop1740s" : "7073" , "den1740s" : "6133" , "pop1801" : "22293" , "den1801" : "19332" }  , 
 { "placename" : "St George the Martyr" , "area" : "142887" , "pop1690s" : "4510" , "den1690s" : "31563" , "pop1695" : null , "den1695" : "31563" , "pop1740s" : "7348" , "den1740s" : "51422" , "pop1801" : "6273" , "den1801" : "43902" }  , 
 { "placename" : "St Giles Cripplegate" , "area" : "171803" , "pop1690s" : "12479" , "den1690s" : "72636" , "pop1695" : "8441" , "den1695" : "72636" , "pop1740s" : "14626" , "den1740s" : "85135" , "pop1801" : "11445" , "den1801" : "66617" }  , 
 { "placename" : "St John Horselydown" , "area" : "293203" , "pop1690s" : "8908" , "den1690s" : "30382" , "pop1695" : null , "den1695" : "30382" , "pop1740s" : "8120" , "den1740s" : "27695" , "pop1801" : "8892" , "den1801" : "30327" }  , 
 { "placename" : "St Olave Southwark" , "area" : "199350" , "pop1690s" : "5939" , "den1690s" : "29792" , "pop1695" : null , "den1695" : "29792" , "pop1740s" : "9515" , "den1740s" : "47729" , "pop1801" : "7846" , "den1801" : "39358" }  , 
 { "placename" : "St Saviour Southwark" , "area" : "549099" , "pop1690s" : "10974" , "den1690s" : "19986" , "pop1695" : null , "den1695" : "19986" , "pop1740s" : "14527" , "den1740s" : "26456" , "pop1801" : "25596" , "den1801" : "46615" }  , 
 { "placename" : "St Sepulchre" , "area" : "220783" , "pop1690s" : "12944" , "den1690s" : "58627" , "pop1695" : "7278" , "den1695" : "58627" , "pop1740s" : "14146" , "den1740s" : "64072" , "pop1801" : "11860" , "den1801" : "53718" }  , 
 { "placename" : "St Thomas Southwark" , "area" : "33092" , "pop1690s" : "1772" , "den1690s" : "53538" , "pop1695" : null , "den1695" : "53538" , "pop1740s" : "2907" , "den1740s" : "87846" , "pop1801" : "2888" , "den1801" : "87272" }  , 
 { "placename" : "Liberty of the Rolls" , "area" : "42833" , "pop1690s" : "2627" , "den1690s" : "61331" , "pop1695" : null , "den1695" : "61331" , "pop1740s" : "2425" , "den1740s" : "56615" , "pop1801" : "2409" , "den1801" : "56242" }  , 
 { "placename" : "Liberty of the Savoy" , "area" : "29931" , "pop1690s" : "349" , "den1690s" : "11660" , "pop1695" : null , "den1695" : "11660" , "pop1740s" : "322" , "den1740s" : "10758" , "pop1801" : "320" , "den1801" : "10691" }  , 
 { "placename" : "St Anne Soho" , "area" : "216600" , "pop1690s" : "9532" , "den1690s" : "44006" , "pop1695" : null , "den1695" : "44006" , "pop1740s" : "13741" , "den1740s" : "63439" , "pop1801" : "11637" , "den1801" : "53726" }  , 
 { "placename" : "St Clement Danes" , "area" : "223037" , "pop1690s" : "9917" , "den1690s" : "44465" , "pop1695" : null , "den1695" : "44465" , "pop1740s" : "9287" , "den1740s" : "41638" , "pop1801" : "8717" , "den1801" : "39083" }  , 
 { "placename" : "St George Bloomsbury" , "area" : "489173" , "pop1690s" : "10194" , "den1690s" : "20839" , "pop1695" : null , "den1695" : "20839" , "pop1740s" : "10874" , "den1740s" : "22230" , "pop1801" : "7738" , "den1801" : "15819" }  , 
 { "placename" : "St George Hanover Square" , "area" : "4552946" , "pop1690s" : "9114" , "den1690s" : "2002" , "pop1695" : null , "den1695" : "2002" , "pop1740s" : "18534" , "den1740s" : "4071" , "pop1801" : "38440" , "den1801" : "8443" }  , 
 { "placename" : "St James Westminster" , "area" : "660748" , "pop1690s" : "19722" , "den1690s" : "29848" , "pop1695" : null , "den1695" : "29848" , "pop1740s" : "25600" , "den1740s" : "38744" , "pop1801" : "34462" , "den1801" : "52156" }  , 
 { "placename" : "St John the Evangelist Westminster" , "area" : "907232" , "pop1690s" : "7484" , "den1690s" : "8249" , "pop1695" : null , "den1695" : "8249" , "pop1740s" : "10000" , "den1740s" : "11023" , "pop1801" : "8375" , "den1801" : "9231" }  , 
 { "placename" : "St Margaret Westminster" , "area" : "859695" , "pop1690s" : "11226" , "den1690s" : "13058" , "pop1695" : null , "den1695" : "13058" , "pop1740s" : "15000" , "den1740s" : "17448" , "pop1801" : "17508" , "den1801" : "20365" }  , 
 { "placename" : "St Martin in the Fields" , "area" : "1145374" , "pop1690s" : "20000" , "den1690s" : "17461" , "pop1695" : null , "den1695" : null, "pop1740s" : "27502" , "den1740s" : "24011" , "pop1801" : "25752" , "den1801" : "22483" }  , 
 { "placename" : "St Mary le Strand" , "area" : "58496" , "pop1690s" : "2593" , "den1690s" : "44328" , "pop1695" : null , "den1695" : "44328" , "pop1740s" : "2063" , "den1740s" : "35272" , "pop1801" : "1704" , "den1801" : "29130" }  , 
 { "placename" : "St Paul Covent Garden" , "area" : "106397" , "pop1690s" : "4227" , "den1690s" : "39729" , "pop1695" : null , "den1695" : "39729" , "pop1740s" : "5024" , "den1740s" : "47219" , "pop1801" : "4992" , "den1801" : "46919" }  , 
 { "placename" : "The Temple" , "area" : "69950" , "pop1690s" : "945" , "den1690s" : "13510" , "pop1695" : null , "den1695" : "13510" , "pop1740s" : "873" , "den1740s" : "12480" , "pop1801" : "867" , "den1801" : "12395" }  , 
 { "placename" : "Westminster Abbey" , "area" : "38910" , "pop1690s" : null , "den1690s" : null , "pop1695" : null , "den1695" : null , "pop1740s" : null , "den1740s" : null , "pop1801" : "1685" , "den1801" : "43305" }  , 
 { "placename" : "Christchurch Southwark" , "area" : "335604" , "pop1690s" : "3020" , "den1690s" : "8999" , "pop1695" : null , "den1695" : "8999" , "pop1740s" : "4202" , "den1740s" : "12520" , "pop1801" : "9933" , "den1801" : "29597" }  , 
 { "placename" : "Christchurch Spitalfields" , "area" : "298150" , "pop1690s" : "6287" , "den1690s" : "21087" , "pop1695" : null , "den1695" : "21087" , "pop1740s" : "16200" , "den1740s" : "54337" , "pop1801" : "15091" , "den1801" : "50615" }  , 
 { "placename" : "Liberty of Saffron Hill" , "area" : "127505" , "pop1690s" : "8177" , "den1690s" : "64131" , "pop1695" : null , "den1695" : "64131" , "pop1740s" : "7548" , "den1740s" : "59198" , "pop1801" : "7500" , "den1801" : "58821" }  , 
 { "placename" : "Mile End New Town" , "area" : "2699316" , "pop1690s" : "2096" , "den1690s" : "776" , "pop1695" : null , "den1695" : "776" , "pop1740s" : "3675" , "den1740s" : "1361" , "pop1801" : "5253" , "den1801" : "1946" }  , 
 { "placename" : "Newington" , "area" : "2548920" , "pop1690s" : "4765" , "den1690s" : "1869" , "pop1695" : null , "den1695" : "1869" , "pop1740s" : "7914" , "den1740s" : "3105" , "pop1801" : "1462" , "den1801" : "574" }  , 
 { "placename" : "Precinct of St Katherine" , "area" : "61408" , "pop1690s" : "3516" , "den1690s" : "57261" , "pop1695" : null , "den1695" : "57261" , "pop1740s" : "3366" , "den1740s" : "54814" , "pop1801" : "3215" , "den1801" : "52355" }  , 
 { "placename" : "Ratcliffe" , "area" : "523741" , "pop1690s" : "2514" , "den1690s" : "4800" , "pop1695" : null , "den1695" : "4800" , "pop1740s" : "4090" , "den1740s" : "7809" , "pop1801" : "5666" , "den1801" : "10818" }  , 
 { "placename" : "Shadwell" , "area" : "295705" , "pop1690s" : "9065" , "den1690s" : "30655" , "pop1695" : null , "den1695" : "30655" , "pop1740s" : "10084" , "den1740s" : "34101" , "pop1801" : "8828" , "den1801" : "29854" }  , 
 { "placename" : "St Anne Limehouse" , "area" : "1056920" , "pop1690s" : "2096" , "den1690s" : "1983" , "pop1695" : null , "den1695" : "1983" , "pop1740s" : "4642" , "den1740s" : "4392" , "pop1801" : "4678" , "den1801" : "4426" }  , 
 { "placename" : "St Dunstan Stepney" , "area" : "170645" , "pop1690s" : "10478" , "den1690s" : "61402" , "pop1695" : null , "den1695" : "61402" , "pop1740s" : "12785" , "den1740s" : "74922" , "pop1801" : "15091" , "den1801" : "88435" }  , 
 { "placename" : "St George in the East" , "area" : "983323" , "pop1690s" : "8801" , "den1690s" : "8950" , "pop1695" : null , "den1695" : "8950" , "pop1740s" : "14908" , "den1740s" : "15160" , "pop1801" : "21170" , "den1801" : "21529" }  , 
 { "placename" : "St Giles in the Fields" , "area" : "493753" , "pop1690s" : "18241" , "den1690s" : "36944" , "pop1695" : null , "den1695" : "36944" , "pop1740s" : "23059" , "den1740s" : "46701" , "pop1801" : "28764" , "den1801" : "58256" }  , 
 { "placename" : "St James Clerkenwell" , "area" : "833026" , "pop1690s" : "4965" , "den1690s" : "5960" , "pop1695" : null , "den1695" : "5960" , "pop1740s" : "7809" , "den1740s" : "9374" , "pop1801" : "14973" , "den1801" : "17974" }  , 
 { "placename" : "St John at Hackney" , "area" : "13354027" , "pop1690s" : "2007" , "den1690s" : "150" , "pop1695" : null , "den1695" : "150" , "pop1740s" : "4684" , "den1740s" : "351" , "pop1801" : "12730" , "den1801" : "953" }  , 
 { "placename" : "St Leonard Shoreditch" , "area" : "872877" , "pop1690s" : "3470" , "den1690s" : "3975" , "pop1695" : null , "den1695" : "3975" , "pop1740s" : "6942" , "den1740s" : "7953" , "pop1801" : "12074" , "den1801" : "13832" }  , 
 { "placename" : "St Luke Old Street" , "area" : "910707" , "pop1690s" : "1880" , "den1690s" : "2064" , "pop1695" : null , "den1695" : "2064" , "pop1740s" : "23953" , "den1740s" : "26302" , "pop1801" : "25268" , "den1801" : "27745" }  , 
 { "placename" : "St Mary Lambeth" , "area" : "4267873" , "pop1690s" : "5490" , "den1690s" : "1286" , "pop1695" : null , "den1695" : "1286" , "pop1740s" : "10310" , "den1740s" : "2416" , "pop1801" : "28031" , "den1801" : "6568" }  , 
 { "placename" : "St Mary Magdalen Bermondsey" , "area" : "2578794" , "pop1690s" : "8500" , "den1690s" : "3296" , "pop1695" : null , "den1695" : "3296" , "pop1740s" : "10620" , "den1740s" : "4118" , "pop1801" : "17169" , "den1801" : "6658" }  , 
 { "placename" : "St Mary Rotherhithe" , "area" : "3484320" , "pop1690s" : "3189" , "den1690s" : "915" , "pop1695" : null , "den1695" : "915" , "pop1740s" : "6258" , "den1740s" : "1796" , "pop1801" : "10196" , "den1801" : "2926" }  , 
 { "placename" : "St Mary Whitechapel" , "area" : "688157" , "pop1690s" : "17888" , "den1690s" : "25994" , "pop1695" : null , "den1695" : "25994" , "pop1740s" : "26364" , "den1740s" : "38311" , "pop1801" : "23666" , "den1801" : "34390" }  , 
 { "placename" : "St Matthew Bethnal Green" , "area" : "1758473" , "pop1690s" : "5255" , "den1690s" : "2988" , "pop1695" : null , "den1695" : "2988" , "pop1740s" : "5528" , "den1740s" : "3144" , "pop1801" : "12722" , "den1801" : "7235" }  , 
 { "placename" : "The Tower" , "area" : "92489" , "pop1690s" : "3407" , "den1690s" : "36837" , "pop1695" : null , "den1695" : "36837" , "pop1740s" : "3145" , "den1740s" : "34004" , "pop1801" : "3125" , "den1801" : "33788" }  , 
 { "placename" : "Wapping" , "area" : "210658" , "pop1690s" : "5423" , "den1690s" : "25742" , "pop1695" : null , "den1695" : "25742" , "pop1740s" : "6982" , "den1740s" : "33145" , "pop1801" : "5889" , "den1801" : "27955" } 
 ];

export default population;
