
import usePretty from "../../hooks/usePretty";
import lookups from '../../data/loclon/lookups';

import useHitTerm from "../../hooks/useHitTerm";

const LayersControl = (props) => {

    const [ pretty ] = usePretty(lookups);
  
    const [ , , , layerControlTerm ] = useHitTerm();

    let layersControl;

    if (props.layerData) {

        layersControl = props.layerData.map((ldata, idx) => {

            return (

                <div key={ idx }>

                    { ldata.type === 'separator' && props.layerData.length > (idx + 1) && (

                        <div className={ 'layer-control-separator layer-control-separator-' + idx }>
                            <span className='layer-control-separator-title'>{ ldata.title }</span>
                        </div>

                    ) }

                    { ldata.type !== 'separator' && (

                        <span onClick={ function () { props.setActiveLayer(idx); } }>

                            <div className={ 'layer-control layer-control-' + ldata.type + (idx === props.activeLayer ? ' active' : '') }>

                                <div className={ "layer-control-child-left-" + ldata.type }>

                                    <input className="form-check-input layer-control-visible-checkbox" type="checkbox" value="" id="flexCheckChecked" checked={ ldata.visible } onChange={ function() { props.setLayerVisibility( idx, !ldata.visible ); }  } />


                                    { (ldata.type === 'query' || ldata.type === 'hit') && (

                                        <>
                                            { ldata.colorscheme && ldata.colorscheme === 'heat' && ( <span className="agg-legend-square" style={ { 'background': "linear-gradient(to right, hsl(0, 100%, 50%) 0%, hsl(64, 100%, 50%) 25%, hsl(192, 100%, 50%) 75%, hsl(255, 100%, 50%) 100%)" }  } ></span> ) }
                                            { ldata.colorscheme && ldata.colorscheme === 'redopacity' && ( <span className="agg-legend-square" style={ { 'background': "linear-gradient(to right, rgba(255, 0, 0, 100%) 0%, rgba(255, 0, 0, 0%) 100%)" }  } ></span> ) }
                                            { ldata.colorscheme && ldata.colorscheme === 'greenopacity' && ( <span className="agg-legend-square" style={ { 'background': "linear-gradient(to right, rgba(0, 170, 0, 100%) 0%, rgba(0, 170, 0, 0%) 100%)"}  } ></span> ) }
                                            { ldata.colorscheme && ldata.colorscheme === 'blueopacity' && ( <span className="agg-legend-square" style={ { 'background': "linear-gradient(to right, rgba(0, 0, 255, 100%) 0%, rgba(0, 0, 255, 0%) 100%)"}  } ></span> ) }
                                            { ldata.colorscheme && ldata.colorscheme === 'orangeopacity' && ( <span className="agg-legend-square" style={ { 'background': "linear-gradient(to right, rgba(255, 128, 0, 100%) 0%, rgba(255, 128, 0, 0%) 100%)"}  } ></span> ) }
                                            { ldata.colorscheme && ldata.colorscheme === 'cyanopacity' && ( <span className="agg-legend-square" style={ { 'background': "linear-gradient(to right, rgba(0, 140, 140, 100%) 0%, rgba(0, 140, 140, 0%) 100%)"}  } ></span> ) }
                                            { ldata.colorscheme && ldata.colorscheme === 'magentaopacity' && ( <span className="agg-legend-square" style={ { 'background': "linear-gradient(to right, rgba(200, 0, 200, 100%) 0%, rgba(200, 0, 200, 0%) 100%)"}  } ></span> ) }
                                            { ldata.colorscheme && ldata.colorscheme === 'red' && ( <span className="agg-legend-square" style={ { 'background': "linear-gradient(to right, rgba(255, 0, 0, 100%) 0%, rgba(255, 255, 255, 100%) 100%)" }  } ></span> ) }
                                            { ldata.colorscheme && ldata.colorscheme === 'green' && ( <span className="agg-legend-square" style={ { 'background': "linear-gradient(to right, rgba(0, 255, 0, 100%) 0%, rgba(255, 255, 255, 100%) 100%)"}  } ></span> ) }
                                            { ldata.colorscheme && ldata.colorscheme === 'blue' && ( <span className="agg-legend-square" style={ { 'background': "linear-gradient(to right, rgba(0, 0, 255, 100%) 0%, rgba(255, 255, 255, 100%) 100%)"}  } ></span> ) }
                                            { ldata.colorscheme && ldata.colorscheme === 'orange' && ( <span className="agg-legend-square" style={ { 'background': "linear-gradient(to right, rgba(255, 255, 0, 100%) 0%, rgba(255, 255, 255, 100%) 100%)"}  } ></span> ) }
                                            { ldata.colorscheme && ldata.colorscheme === 'cyan' && ( <span className="agg-legend-square" style={ { 'background': "linear-gradient(to right, rgba(0, 255, 255, 100%) 0%, rgba(255, 255, 255, 100%) 100%)"}  } ></span> ) }
                                            { ldata.colorscheme && ldata.colorscheme === 'magenta' && ( <span className="agg-legend-squar" style={ { 'background': "linear-gradient(to right, rgba(255, 0, 255, 100%) 0%, rgba(255, 255, 255, 100%) 100%)"}  } ></span> ) }
                                        </>

                                    ) }

                                </div>

                                <div className="layer-control-child-right">

                                    { ldata.type !== 'tile' && (

                                        <button className="btn btn-link ms-2 float-end" type="button" onClick={ function() { props.deleteLayer( idx ); }  }>
                                            <i className="bi bi-x" />
                                        </button>

                                    ) }

                                    { ldata.target && (

                                        <button className="btn btn-link warp-button" type="button" onClick={ function() { props.setMapPosition( ldata.target ); }  }>
                                            <i className="bi bi-crosshair" />
                                        </button>

                                    ) }



                                        <>

                                        { ldata.type === 'query' && (

                                            <>
                                            { ldata.searchmode === 'ppop' && (

                                                <span>{ pretty(ldata.qs.ppopdata, ['lookup_ldata_ppopdata']) }. </span>

                                            ) }

                                            { ldata.searchmode !== 'ppop' && (

                                                <>
                                                <span>{ pretty(ldata.searchmode, ['lookup_ldata_searchmode']) }. </span>

                                                { ldata.searchmode !== 'place' && (

                                                    <span>{ layerControlTerm(ldata.searchmode, ldata.display) }. </span>

                                                ) }

                                                {/*
                                                <span>{ ldata.searchmode.startsWith('mola') ? 'Finds by location' : pretty(ldata.display, [ 'lookup_ldata_display' ]) }. </span>

                                                <span>{ 'lookup_ldata_display_' + hitTerm(ldata.searchmode) }. </span>
                                                */}

                                                <span>{ pretty(ldata.qs, ['query']) }</span>

                                                {/* <span>{ ldata.description } </span> */}
                                                </>

                                            ) }
                                            </>

                                        ) }

                                        { (ldata.type === 'hit' || ldata.type === 'tile') && (

                                            <span>{ ldata.description } </span>

                                        ) }

                                        </>

                                    { ldata.geotype && (

                                        <span className={ "geotype geotype-" + ldata.geotype }>{ pretty(ldata.geotype, ['lookup_geotype']) }</span>

                                    ) }

                                    { (ldata.type === 'query' || ldata.type === 'hit') && (

                                        <div>

                                            <button type="button" className="btn btn-link layer-options-button" data-bs-toggle="modal" data-bs-target="#layerOptionsModal" onClick={ function() { props.setLayerOptions( idx ); }  }>
                                                Change display ...
                                            </button>

                                        </div>

                                    ) }

                                </div>


                            </div>

                        </span>

                    ) }

                </div>

            );

        });

    } 

    return (
        
        <>
            { layersControl }

        </>

    );

};

export default LayersControl;
