
//import { useCallback } from "react";
//import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";

//import useForm from "../../../hooks/useForm";

//import usePretty from "../../../hooks/usePretty";
//import usePaging from "../../../hooks/usePaging";

//import SearchButton from "./SearchButton";
import AddToMapButton from "./AddToMapButton";

//import useResults from "../../../hooks/useResultsNew";

//import Loading from "../../Loading";
//import Error from "../../Error";
//import Hit from "../Hit";

//import queryString from "query-string";

import useFormSimple from "../../../hooks/useFormSimple";

//import useHitTerm from "../../../hooks/useHitTerm";

import Post from "../../Post";
import options_syn from '../../../data/loclon/options_syn';

const FormPpop = (props) => {

    document.title = "Population and Area Data 1690-1801 | Locating London's Past";

    const [ values, handleChange, , ] = useFormSimple( { 'ppopdata': 'pop1690s' } );

    //const location = useLocation();
    //let qs = queryString.parse(location.search, { arrayFormat: "bracket" });

    //const [ values, handleChange, handleSubmit, handleReset ] =
    //useForm(false, false);
    
    //const [ hitTerm, hitsTerm, keyTerm, layerControlTerm ] = useHitTerm();

    const handleSubmit = (event) => {

        event.preventDefault();

        //console.log('ppop handleSubmit');

        //console.log(values['ppopdata']);

        //props.addQueryLayer( 'ppop', values['ppopdata'] );
        props.addQueryLayer( 'ppop', { 'ppopdata': values['ppopdata'] } );

    }


    return (

        <>

            <div>

                <div className="setinfo">
                    <Post url={ `${process.env.REACT_APP_WORDPRESS_URL}/posts?slug=synopsis-ppop` } options={ options_syn } />
                    {/* <span>Population figures for each of the parishes included on John Rocque’s 1746 map of London, drawn from various sources between 1690 and 1801. </span> */}
                    <Link target="_blank" to="/about/population">More information ...</Link>
                </div>

                <form onSubmit={ handleSubmit }> 

                    <div className="row mb-1">
                        <div className="col-12 text-end">
                            <div className="input-group">
                                <select className='form-select' onChange={ handleChange } value={ values['ppopdata'] || '' } name="ppopdata" id="form_ppopdata">
                                    <option value="pop1690s">1690s Bills of Mortality</option><option value="den1690s">1690s Bills of Mortality (density)</option><option value="pop1695">1695 Marriage Duty</option><option value="den1695">1695 Marriage Duty (density)</option><option value="pop1740s">1740s Bills of Mortality</option><option value="den1740s">1740s Bills of Mortality (density)</option><option value="pop1801">1801 Census</option><option value="den1801">1801 Census (density)</option><option value="area">Area</option>
                                </select>
                                <button type="button" className="btn btn-outline-input-group btn-light bg-white border-start-0 text-muted" data-bs-toggle="modal" data-bs-target="#helpModal" data-bs-helpslug="help-populationandarea" onClick={ props.handleChangeHelpslug }><i className="bi bi-question-circle" /></button>
                                {/*<button type="submit" className="btn btn-outline-input-group btn-light bg-primary text-white search-feature-inline-submit"><i className="bi bi-search" /></button>*/}
                                <AddToMapButton/>
                            </div>
                            <button data-bs-helpslug="help-addtomap" type="button" className="btn border-0 p-0 m-0 align-top help-link" data-bs-toggle="modal" data-bs-target="#helpModal" onClick={ props.handleChangeHelpslug }>What's this?</button>
                        </div>
                    </div>

                </form>

            </div>

        </>

    );

};

export default FormPpop;
