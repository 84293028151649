
//import { Link, useLocation } from "react-router-dom";

//import parse from 'html-react-parser';

//import queryString from "query-string";

//import useForm from "../../hooks/useFormSimple";

import DisplayOptions from "./forms/DisplayOptions";

const LayerOptions = (props) => {
    
    //const pretty = props.pretty;

    return (
        
        <div>

            <h1>

                Change display
                <button data-bs-helpslug="help-adddata-changedisplay" type="button" className="btn border-0 text-muted ps-2 pe-0 pt-0 pb-0 align-top" data-bs-toggle="modal" data-bs-target="#helpModal" onClick={ props.handleChangeHelpslug }><i className="bi bi-question-circle" /></button>

            </h1>        

            <div className="layer-options-inner">

                { (props.layerData.searchmode !== 'molacp' && props.layerData.searchmode !== 'molag' && props.layerData.searchmode !== 'ppop' && props.layerData.searchmode !== 'place' && props.layerData.type === 'query') && (

                    <div className="mb-3">
                        <label htmlFor="field_layerdisplay" className="form-label">What to display</label>
                        <div className="input-group">

                            <select className={ 'form-select' } name="layerdisplay" id="field_layerdisplay" value={ props.layerOptionsValues['layerdisplay'] || '' } onChange={ props.layerOptionsHandleChange }>
                                <DisplayOptions searchmode={ props.layerData.searchmode } />
                            </select>

                            <button type="button" className="btn btn-outline-input-group btn-light bg-white border-start-0 text-muted" data-bs-toggle="modal" data-bs-target="#helpModal" data-bs-helpslug="help-changedisplay-mappableplaces" onClick={ props.handleChangeHelpslug }>
                                <i className="bi bi-question-circle" />
                            </button>

                        </div>

                    </div>

                )}

                { (props.layerData.searchmode !== 'molacp' && props.layerData.searchmode !== 'molag') && (

                    <div className="mb-3">
                        <label htmlFor="field_polymarker" className="form-label">How to display the data</label>
                        <div className="input-group">

                            <select className={ 'form-select' } name="polymarker" id="field_polymarker" value={ props.layerOptionsValues['polymarker'] || '' } onChange={ props.layerOptionsHandleChange }>

                                <option value="polygons">Display one polygon{ props.layerData.type === 'query' ? ' per place' : '' }.</option>

                                { (props.layerOptionsValues['layerdisplay'] !== 'heat-blocks-3dp') && (

                                    <option value="markers">Display one marker{ props.layerData.type === 'query' ? ' per place (size is proportional to value)' : '' }.</option>

                                ) }

                                {/* <option value="circles">Display one circle{ props.layerData.type === 'query' ? ' per place' : '' }.</option> */}

                                {/*
                                { props.layerData.type === 'query' && (

                                    <option value="multicircles">Display one circle per hit (proportionally).</option>

                                )}
                                */}

                            </select>

                            <button type="button" className="btn btn-outline-input-group btn-light bg-white border-start-0 text-muted" data-bs-toggle="modal" data-bs-target="#helpModal" data-bs-helpslug="help-changedisplay-howtodisplay" onClick={ props.handleChangeHelpslug }>
                                <i className="bi bi-question-circle" />
                            </button>

                        </div>

                    </div>

                )}

                { (props.layerData.searchmode !== 'molacp' && props.layerData.searchmode !== 'molag') && (props.layerOptionsValues['polymarker'] === 'polygons') && (

                    <div className="mb-3">
                        <label htmlFor="field_stroke" className="form-label">Border</label>
                        <div className="input-group">

                            <select className={ 'form-select' } name="stroke" id="field_stroke" value={ props.layerOptionsValues['stroke'] || '' } onChange={ props.layerOptionsHandleChange }>
                                <option value="stroke">Polygons have borders.</option>
                                <option value="nostroke">Polygons do not have borders.</option>
                            </select>

                            <button type="button" className="btn btn-outline-input-group btn-light bg-white border-start-0 text-muted" data-bs-toggle="modal" data-bs-target="#helpModal" data-bs-helpslug="help-changedisplay-border" onClick={ props.handleChangeHelpslug }>
                                <i className="bi bi-question-circle" />
                            </button>

                        </div>

                    </div>

                ) }

                <div className="mb-3">
                    <label htmlFor="field_colorscheme" className="form-label">Colour scheme</label>
                    <div className="input-group">

                        <select className={ 'form-select' } name="colorscheme" id="field_colorscheme" value={ props.layerOptionsValues['colorscheme'] || '' } onChange={ props.layerOptionsHandleChange }>
                            <option value="redopacity">Red</option>
                            <option value="greenopacity">Green</option>
                            <option value="blueopacity">Blue</option>
                            <option value="orangeopacity">Orange</option>
                            <option value="cyanopacity">Cyan</option>
                            <option value="magentaopacity">Magenta</option>

                            { (props.layerData.searchmode !== 'place' && props.layerData.type === 'query') && (

                                <option value="heat">Heatmap (red = high value ..  orange .. green .. blue = low value)</option>

                            )}

                            <option value="key">Multiple colours</option>

                            {/*
                            <option value="red">Red solid shades (dark to light)</option>
                            <option value="green">Green solid shades (dark to light)</option>
                            <option value="blue">Blue solid shades (dark to light)</option>
                            <option value="orange">Yellow solid shades (dark to light)</option>
                            <option value="cyan">Cyan solid shades (dark to light)</option>
                            <option value="magenta">Magenta solid shades (dark to light)</option>
                            */}
                        </select>

                        <button type="button" className="btn btn-outline-input-group btn-light bg-white border-start-0 text-muted" data-bs-toggle="modal" data-bs-target="#helpModal" data-bs-helpslug="help-changedisplay-colour" onClick={ props.handleChangeHelpslug }>
                            <i className="bi bi-question-circle" />
                        </button>

                    </div>

                </div>

                <button type="button" className="btn btn-secondary float-start mb-3" data-bs-dismiss="modal">Cancel</button>
                <button type="button" className="btn btn-primary float-end text-white mb-3" data-bs-dismiss="modal" onClick={ function() { props.setLayerOpts( props.idx, props.layerOptionsValues ); }  }>Confirm</button>

            </div>

        </div>

    );

};

export default LayerOptions;
